import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import * as moment from 'moment';

@Injectable()
export class SummaryService {
	constructor(protected http: HttpClient) {}

	getProspectSummary(id?: number): Observable<{ [key: string]: { count: number, percent: number } }> {
		if (id) {
			return this.http.get<{ [key: string]: { count: number, percent: number } }>(`${environment.apiUrl}/summary/prospects/${id}`);
		} else {
			return this.http.get<{ [key: string]: { count: number, percent: number } }>(`${environment.apiUrl}/summary/prospects`);
		}
	}

	getHasWantsByCategory(id?: number): Observable<{ category: string, subCategory: string, has: number, wants: number }[]> {
		if (id) {
			return this.http.get<{ category: string, subCategory: string, has: number, wants: number }[]>(`${environment.apiUrl}/summary/haswantsbycategory/${id}`);
		} else {
			return this.http.get<{ category: string, subCategory: string, has: number, wants: number }[]>(`${environment.apiUrl}/summary/haswantsbycategory`);
		}
	}

	public getPropertyStatusSummary(id?: number): Observable<{ status: string, count: number }[]> {
		if (id) {
			return this.http.get<{ status: string, count: number }[]>(`${environment.apiUrl}/summary/property/status/${id}`);
		} else {
			return this.http.get<{ status: string, count: number }[]>(`${environment.apiUrl}/summary/property/status`);
		}
	}

	public getExpiredProperties(id?: number): Observable<{ propertyName: string, status: string, expirationDate: Date }[]> {
		if (id) {
			return this.http.get<{ propertyName: string, status: string, expirationDate: Date }[]>(`${environment.apiUrl}/summary/property/expired/${id}`);
		} else {
			return this.http.get<{ propertyName: string, status: string, expirationDate: Date }[]>(`${environment.apiUrl}/summary/property/expired`);
		}
	}

	public getPropertyCategorySummary(id?: number): Observable<{ propertyCount: number, totalPrice: number, totalCommission: number, category: string, status: string }[]> {
		if (id) {
			return this.http.get<{ propertyCount: number, totalPrice: number, totalCommission: number, category: string, status: string }[]>(`${environment.apiUrl}/summary/property/category/${id}`);
		} else {
			return this.http.get<{ propertyCount: number, totalPrice: number, totalCommission: number, category: string, status: string }[]>(`${environment.apiUrl}/summary/property/category`);
		}
	}

	public getProgress(startDate: Date, endDate: Date, userId?: number) {
		let params = new HttpParams()
			.append('startDate', moment(startDate).toISOString())
			.append('endDate', moment(endDate).toISOString());

		if (userId) {
			params = params.append('userId', `${userId}`);
		}

		return this.http.get<any[]>(`${environment.apiUrl}/summary/progress`, {params});
	}

	public getCommunicationSummary(startDate: Date, endDate: Date, userId?: number) {
		let params = new HttpParams()
			.append('startDate', moment(startDate).toISOString())
			.append('endDate', moment(endDate).toISOString());

		if (userId) {
			params = params.append('userId', `${userId}`);
		}

		return this.http.get<any[]>(`${environment.apiUrl}/summary/communicationsummary`, {params});
	}
}

import { Component, Input, Output, ViewChild, OnDestroy, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import {
	PropertyModel,
	ProspectModel,
	PropertyMatchModel,
	PaginationDto,
	MatchInterestInterface,
	ProspectHasModel,
	ProspectWantsModel,
	ProspectMatchModel, ValueInterface,
	UserModel, UserListModel, ProspectListModel, IdInterface,
} from '@dvp/is2-shared';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import 'rxjs/operators/map';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatchmakerService } from '../../service/matchmaker.service';
import { PaginationDatasource } from '../../datasource/pagination.datasource';
import { Breakpoints } from '@angular/cdk/layout';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../service/user.service';
import { ShownComponent } from '../shown/shown.component';
import { PropertyService } from '../../module/property/service/property.service';
import { ProspectService } from '../../module/prospect/service/prospect.service';
import { ProspectPaginationListComponent } from '../prospect-pagination-list/prospect-pagination-list.component';
import { PropertyPaginationListComponent } from '../property-pagination-list/property-pagination-list.component';
import { ValuesService } from '../../service/values.service';
import { ProspectSelectionEventInterface } from '../../interface/prospect-selection-event.interface';
import { PropertySelectionEventInterface } from '../../interface/property-selection-event.interface';
import { FormBuilder, FormControl } from '@angular/forms';
import { UsersService } from '../../module/admin/service/users.service';
import { StorageService } from '../../service/storage.service';
import { EmailService } from '../../service/email.service';
import { PromptDialogComponent } from '../prompt-dialog/prompt-dialog.component';

@Component({
	selector: 'is2-matchmaker',
	templateUrl: './matchmaker.component.pug',
	styleUrls: ['./matchmaker.component.scss'],
})

export class MatchMakerComponent implements OnDestroy, AfterViewInit, OnInit {

	@Output() protected addShownEvent: EventEmitter<{match: PropertyMatchModel | ProspectMatchModel, result: PropertyModel | ProspectModel, event: MouseEvent}> = new EventEmitter();
	@Output() protected prospectSelect: EventEmitter<ProspectSelectionEventInterface> = new EventEmitter();
	@Output() protected propertySelect: EventEmitter<PropertySelectionEventInterface> = new EventEmitter();

	@ViewChild('prospectPaginationList', {static: false}) protected prospectPaginationListComponent: ProspectPaginationListComponent;
	@ViewChild('propertyPaginationList', {static: false}) protected propertyPaginationListComponent: PropertyPaginationListComponent;

	@Input() public shownComponent: ShownComponent;
	@Input() public property: PropertyModel;
	@Input() public has: ProspectHasModel;
	@Input() public want: ProspectWantsModel;
	@Input() public user: UserModel;

	protected subject = new Subject<any>();
	protected mediaSubscription: Subscription;

	public isMobile: boolean = false;
	public loading: boolean;
	public match: boolean = true;
	public agents: UserListModel[];
	public cities: ValueInterface[];
	public sectionTitle: string;
	public dataSource: PaginationDatasource<PropertyMatchModel | ProspectMatchModel>;
	public phoneLinks: {phone: string, label: string}[] = [];
	public matchFormGroup = this.fb.group({
		toggleMatches: new FormControl(),
	});
	public routedToOnly: boolean;
	public bulkEditMode: boolean = false;

	constructor(
		protected matchmakerService: MatchmakerService,
		protected router: Router,
		protected userService: UserService,
		protected toastrService: ToastrService,
		protected propertyService: PropertyService,
		protected prospectService: ProspectService,
		breakpointObserver: BreakpointObserver,
		protected valueService: ValuesService,
		protected usersService: UsersService,
		protected fb: FormBuilder,
		protected emailService: EmailService,
		protected promptDialog: MatDialog
	) {
		valueService.getValues().subscribe((values) => {
			this.cities = values.city;
		});

		usersService.getAllUsers().subscribe((users: UserListModel[]) => {
			this.agents = users;
		});

		this.mediaSubscription = breakpointObserver
			.observe(Breakpoints.Handset)
			.subscribe(result => {
				this.isMobile = result.matches;
			});
	}

	public ngOnInit(): void {
		this.loading = true;
	}

	public ngAfterViewInit(): void {
		this.doSearch();
		this.getColumns();
	}

	public ngOnDestroy(): void {
		if (this.mediaSubscription) {
			this.mediaSubscription.unsubscribe();
		}
	}

	public getColumns(): void {
		let component: PropertyPaginationListComponent | ProspectPaginationListComponent;

		if (this.want) {
			component = this.propertyPaginationListComponent;
		} else {
			component = this.prospectPaginationListComponent;
		}

		if (this.want || this.property) {
			component.getColumns().push({
				columnDef: 'shownBtn',
				title: 'Add Shown',
				align: 'center',
				pipe: 'actions',
				buttons: [{
					fontSet: 'fas',
					fontIcon: 'fa-plus',
					color: 'primary',
					tooltip: 'Add Shown',
					callback: (row: PropertyMatchModel | ProspectMatchModel, event: MouseEvent): void => {
						this.addShown(row, event);
					},
				}],
			});
		}

		if (this.has || this.property) {
			component.getColumns().unshift({
				columnDef: 'phone',
				title: 'Call Prospect',
				pipe: 'actions',
				buttons: [{
					matMenu: true,
					fontSet: 'far',
					fontIcon: 'fa-phone',
					color: 'primary',
					tooltip: 'Call Prospect',
					callback: (row: ProspectMatchModel, event: MouseEvent): void => {
						this.phoneButtonClicked(event, row);
					},
					condition: (row: ProspectMatchModel): boolean => {
						return this.hasPhoneNumber(row);
					},
				},
					{
						fontSet: 'far',
						fontIcon: 'fa-phone-slash',
						color: 'primary',
						tooltip: 'No numbers listed',
						callback: (row: ProspectMatchModel, event: MouseEvent): void => {
							this.phoneButtonClicked(event, row);
						},
						condition: (row: ProspectMatchModel): boolean => {
							return !this.hasPhoneNumber(row);
						},
					}],
			});
		}

		component.getColumns().push({
			columnDef: 'interestLevel',
			title: 'Interest',
			pipe: 'actions',
			buttons: [{
				fontSet: 'fas',
				fontIcon: 'fa-heart',
				color: 'primary',
				tooltip: 'Click to change interest',
				callback: (row: PropertyMatchModel | ProspectMatchModel, event: MouseEvent): void => {
					return this.saveInterestLevel(row, event);
				},
				condition: (row: PropertyMatchModel | ProspectMatchModel): boolean => {
					return row.interestLevel;
				},
			},
				{
					fontSet: 'far',
					fontIcon: 'fa-heart',
					color: 'primary',
					tooltip: 'Click to change interest',
					callback: (row: PropertyMatchModel | ProspectMatchModel, event: MouseEvent): void => {
						return this.saveInterestLevel(row, event);
					},
					condition: (row: PropertyMatchModel | ProspectMatchModel): boolean => {
						return !row.interestLevel;
					},
				}],
		});

	}

	public getMatches = (pageIndex: number, pageSize: number): Observable<PaginationDto<ProspectMatchModel | PropertyMatchModel>> => {
		let method: string;
		let object: ProspectHasModel | ProspectWantsModel | PropertyModel;

		if (this.want) {
			method = 'findWantPropertyMatches';
			object = this.want;
		} else if (this.has) {
			method = 'findHasProspectMatches';
			object = this.has;
		} else {
			method = 'findProspectMatchesForProperty';
			object = this.property;
		}

		const routedToOnly: boolean = this.routedToOnly ? this.routedToOnly : this.matchFormGroup.controls['toggleMatches'].value;

		return this.matchmakerService[method](object, pageIndex, pageSize, routedToOnly ? this.user.userId : null)
			.finally(() => this.loading = false);
	};

	public dataTransformation = (match: IdInterface): void => {
		const destination: string = this.want ? 'property' : 'prospect';
		(match as any).rowHref = `/${destination}/detail/${match.id}`;
	};

	public toggleMatches(): void {
		this.loading = true;
		this.prospectPaginationListComponent.getProspects();
	}

	public loadingEvent(event: boolean): void {
		this.loading = event;
	}

	public matchSelected(event: ProspectSelectionEventInterface | PropertySelectionEventInterface): void {
		if (event instanceof PropertyMatchModel) {
			this.propertySelect.emit(event as PropertySelectionEventInterface);
		} else {
			this.prospectSelect.emit(event as ProspectSelectionEventInterface);
		}
	}

	public saveInterestLevel(match: PropertyMatchModel | ProspectMatchModel, event: MouseEvent): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let interestLevel: MatchInterestInterface;
		match.interestLevel = !match.interestLevel;

		if (this.want) {
			interestLevel = {
				matchInterestId: match.matchInterestId,
				prospectId: (match as PropertyMatchModel).prospectId,
				propertyId: match.id,
				interestLevel: match.interestLevel,
				savedBy: this.userService.getUser().userId,
				dtStamp: new Date(),
			};
		}

		if (this.has || this.property) {
			interestLevel = {
				prospectId: match.id,
				matchInterestId: match.matchInterestId,
				hasId: (match as ProspectMatchModel).hasId,
				propertyId: (match as ProspectMatchModel).propertyId,
				interestLevel: match.interestLevel,
				savedBy: this.userService.getUser().userId,
				dtStamp: new Date(),
			};
		}

		this.matchmakerService.saveInterestLevel(interestLevel).subscribe((result) => {
			this.toastrService.success(`Prospect Interest Level Saved`);
			match.matchInterestId = result;
			this.doSearch();
			this.loading = true;
		});
	}

	public async addShown(match: PropertyMatchModel | ProspectMatchModel, event: MouseEvent): Promise<void> {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		if ((match as PropertyMatchModel).prospectId) {
			await this.propertyService.getDetail(match.id).subscribe((result) => {
				this.addShownEvent.emit({match: match as PropertyMatchModel, result: result as PropertyModel, event: event});
			});
		} else {
			await this.prospectService.getProspect(match.id).subscribe((result) => {
				this.addShownEvent.emit({match: match as ProspectMatchModel, result: result as ProspectModel, event: event});
			});
		}
	}

	public phoneButtonClicked(event: MouseEvent, match: ProspectMatchModel): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		if (!this.hasPhoneNumber(match)) {
			this.toastrService.error(`Prospect ${match.id} does not have numbers listed`);
		}

		this.phoneLinks = [
			{phone: match.workPhone, label: 'Work Phone'},
			{phone: match.homePhone, label: 'Home Phone'},
			{phone: match.cellPhone, label: 'Cell Phone'},
			{phone: match.directPhone, label: 'Direct Phone'},
		].filter(x => x.phone != null && x.phone !== '');
	}

	public hasPhoneNumber(match: ProspectMatchModel): boolean {
		return !!match.cellPhone || !!match.directPhone || !!match.workPhone || !!match.homePhone;
	}

	public toggleBulkEditMode(event?: MouseEvent): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.routedToOnly = !this.routedToOnly;
		this.bulkEditMode = !this.bulkEditMode;
		this.toggleMatches();
		this.prospectPaginationListComponent.toggleBulkEditMode();
	}

	public shareListing(event: MouseEvent): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		let method: string;
		let prospects: IdInterface[] = this.prospectPaginationListComponent.bulkEditExceptionList;

		if ( this.prospectPaginationListComponent.selectAllMode && prospects.length > 0) {
			method = 'selectAllBulkProspectEmails';
			prospects = this.prospectPaginationListComponent.bulkEditExceptionList;
		}

		if ((this.prospectPaginationListComponent.bulkEditMode && prospects.length === 0) || !this.prospectPaginationListComponent.selectAllMode) {
			method = 'bulkSelectProspectEmails';
			prospects = this.prospectPaginationListComponent.bulkEditSelection.selected;
		}

		const prospectIds: number[] = prospects.map(prospect => prospect.id);

		this.emailService[method](prospectIds, this.property).subscribe(async (prospect: {emails: ProspectListModel[], noEmails: ProspectListModel[]}) => {
			if (prospect.noEmails.length > 0) {
				let info: boolean;

				try {
					info = await this.openPromptDialog(prospect.noEmails);
				} catch (e) {
					return;
				}

				if (info) {
					this.goToPropertyShare(prospect.emails);
				}

			} else {
				this.goToPropertyShare(prospect.emails);
			}
		}, (error) => {
			this.toastrService.error(error);
		});

	}

	public goToPropertyShare(prospects: ProspectListModel[]): void {
		StorageService.set('emails', prospects);
		// noinspection JSIgnoredPromiseFromCall
		this.router.navigateByUrl(`/property/share/${this.property.id}`);
	}

	public openPromptDialog(prospects: ProspectListModel[]) {
		return new Promise<boolean>((resolve: any, reject: any): void => {
			this.promptDialog.open(PromptDialogComponent, {
				data: {
					title: 'Prospects Without Emails',
					allowCancel: true,
					instructions: 'The following prospects will not receive this property listing:',
					list: prospects,
				},
			}).afterClosed().subscribe(result => {
				if (result === false) {
					reject(false);
				} else {
					resolve(true);
				}
			});
		});
	}

	public cancel(event?: MouseEvent): void {
		this.toggleBulkEditMode(event);
	}

	protected doSearch(): void {
		if (this.want) {
			this.propertyPaginationListComponent.doSearch();
		} else {
			this.prospectPaginationListComponent.doSearch();
		}
	}

}


import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'crop-image-dialog',
	templateUrl: './crop-image-dialog.component.pug',
})

export class CropImageDialogComponent implements OnInit {

	public imageChangedEvent: any = '';

	public image;
	public croppedFile;

	public loading = false;

	constructor(public dialogRef: MatDialogRef<CropImageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
	            protected http: HttpClient, protected toastr: ToastrService) {

		this.image = data.image;

	}

	public ngOnInit() {

		this.loading = true;

		this.http.get(this.image.original, {
			responseType: 'blob',
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
			}
		})
			.subscribe((blob: Blob) => {
				this.imageChangedEvent = {
					target: {
						files: [blob]
					}
				};
			}, (error) => {
				this.loadImageFailed();
			});
	}

	public imageLoaded() {
		this.loading = false;
	}

	public loadImageFailed() {
		this.loading = false;
		this.toastr.error('Unable to get image for cropping!');
		this.onCloseCancel();
	}

	public imageCroppedFile($event) {
		this.croppedFile = $event;
	}

	onCloseConfirm() {
		this.dialogRef.close(this.croppedFile);
	}

	onCloseCancel() {
		this.dialogRef.close(null);
	}

}

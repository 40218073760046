import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValueInterface } from '@dvp/is2-shared';
import { PrintColumnSelectionDialogInterface } from '../../interface/print-column-selection-dialog.interface';
import { SearchService } from '../../module/property/service/search.service';
import { ProspectSearchService } from '../../module/prospect/service/prospect-search.service';

@Component({
	templateUrl: './print-column-selection-dialog.component.pug',
	styleUrls: ['./print-column-selection-dialog.component.scss'],
})

export class PrintColumnSelectionDialogComponent implements OnInit {

	public columns: {
		title: string,
		sections:
			{
				title: string,
				options: ValueInterface[]
			}[]
	}[];
	public data: PrintColumnSelectionDialogInterface;
	public categoryGroup: FormGroup;
	public sortGroup: FormGroup;

	constructor(
		protected dialogRef: MatDialogRef<PrintColumnSelectionDialogComponent>,
		protected fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) data,
		protected searchService: SearchService,
		protected prospectSearchService: ProspectSearchService,
	) {
		this.columns = require('../../../assets/print-selection-columns.json');
		this.data = data;
	}

	public ngOnInit(): void {
		this.sortGroup = this.fb.group({
			sortBy: this.data.includeHas ? 'has.name' : null,
		});
		this.categoryGroup = this.fb.group({
			groupBy: 'has.hasCategory',
		});
		this.setDefaultColumnsSelected();
	}

	public getReportType(entity: string): boolean {
		return this.data.reportType === entity;
	}

	public getColumnsSelected(): void {
		this.data.columnsSelected = [];
		this.columns.forEach((column) => {
			column.sections.forEach((section) => {
				const selected = section.options.filter(x => x.selected === true);
				if (selected.length > 0) {
					this.data.columnsSelected = this.data.columnsSelected.concat(selected);
				}
			});
		});
	}

	public setDefaultColumnsSelected(): void {
		this.resetColumnsSelected();

		let ids: string[];
		if (this.data.reportType === 'Property') {
			ids = [
				'details.agent1Id',
				'details.statusId',
				'location.mapAddress',
				'location.mapCity',
				'location.mapZip',
			];
		}

		if (this.data.reportType === 'Prospect' && !this.data.includeHas) {
			ids = [
				'details.name',
				'details.company',
				'details.routedTo',
				'details.address1',
				'details.nextContactDate',
			];
		}

		if (this.data.reportType === 'Prospect' && this.data.includeHas) {
			ids = [
				'details.name',
				'has.name',
				'has.hasCategory',
				'has.hasStreet',
				'has.hasCounty',
				'has.hasCity',
				'has.hasRoad',
				'has.hasSize',
				'details.company',
				'details.email',
				'details.routedTo',
			];
		}

		this.columns.forEach((column) => {
			if (column.title === this.data.reportType) {
				ids.forEach((id) => {
					column.sections.forEach((section) => {
						section.options.forEach((option) => {
							if (option.id === id) {
								option.selected = true;
							}
						});
					});
				});
			}
		});
		this.getColumnsSelected();
	}

	public resetColumnsSelected(): void {
		this.columns.forEach((column) => {
			column.sections.forEach((section) => {
				section.options.forEach((option) => {
					option.selected = false;
				});
			});
		});
	}

	public includeSection(section: string): boolean {
		return section !== 'Has' || this.data.includeHas;
	}

	public print(): void {
		const tab: Window = window.open('/loading');
		this.dialogRef.close(this.data);

		const sort: string = this.sortGroup.get('sortBy').value || '';

		if (this.data.reportType === 'Property') {
			this.data.currentSearch.columnsSelected = this.data.columnsSelected.map(x => x.id);
			this.data.currentSearch.sortColumnsBy = sort;

			this.searchService.getPropertySearchResultsPdf(this.data.currentSearch)
				.subscribe((data) => {
					this.initializePrint(tab, data);
				});
		}

		if (this.data.reportType === 'Prospect') {
			this.data.currentSearch.forEach((search) => {
				search.columnsSelected = this.data.columnsSelected.map(x => x.id);
			});
			this.data.currentSearch.forEach((search) => {
				search.sortColumnsBy = sort;
			});

			if (!this.data.includeHas) {
				this.prospectSearchService.getBulkSearchPdf(this.data.currentSearch)
					.subscribe(data => {
						this.initializePrint(tab, data);
					});
			} else {
				this.data.currentSearch.forEach((search) => {
					search.groupColumnsBy = this.categoryGroup.get('groupBy').value || '';
				});

				this.prospectSearchService.getSearchResultsHasPdf(this.data.currentSearch)
					.subscribe((data) => {
						this.initializePrint(tab, data);
					});
			}
		}
	}

	public initializePrint(tab: Window, data: any): void {
		const file = new Blob([data], {type: 'application/pdf'});
		tab.location.replace(URL.createObjectURL(file));
		setTimeout(() => tab.print(), 1500);
	}
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'phoneNumber'})
export class PhoneNumberFormatPipe implements PipeTransform {
	transform(phoneNumber: string): string {
		if (!phoneNumber) {
			return null;
		}

		const length = phoneNumber.length;

		if (length < 10) {
			return phoneNumber;
		}

		if (length > 10) {
			return this.format(phoneNumber.slice(length - 10));
		}

		return this.format(phoneNumber);
	}

	private format(phoneNumber: string) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
	}
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {
	PaginationDto,
	TaskGroupTemplateModel,
	TaskTemplateModel,
	TaskOccurrenceModel,
	TaskGroupProspectModel,
	TaskGroupPropertyModel,
	TaskGroupModel,
	ValueInterface,
	TaskOccurrenceDto,
} from '@dvp/is2-shared';
import { Observable } from 'rxjs';

@Injectable()
export class TaskService {

	constructor(protected http: HttpClient) {
	}

	public getLabels(): Observable<ValueInterface[]> {
		return this.http.get<ValueInterface[]>(`${environment.apiUrl}/task/labels`);
	}

	public getAllTaskGroupTemplates(): Observable<TaskGroupTemplateModel[]> {
		return this.http.get<TaskGroupTemplateModel[]>(`${environment.apiUrl}/task/group/templates`);
	}

	public getTaskGroupTemplateById(id: number): Observable<TaskGroupTemplateModel> {
		return this.http.get<TaskGroupTemplateModel>(`${environment.apiUrl}/task/group/template/${id}`);
	}

	public getTasksByUserId(userId: number, currentPage: number, pageSize: number, filterBy?: number[]): Observable<PaginationDto<TaskOccurrenceDto>> {
		let params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		if (filterBy) {
			if (!Array.isArray(filterBy)) {
				filterBy = [filterBy];
			}
			params = params.append('filterBy', filterBy.join(','));
		}

		return this.http.get<PaginationDto<TaskOccurrenceDto>>(`${environment.apiUrl}/task/user/occurrences/${userId}`, {params});
	}

	public getTaskGroupsByPropertyId(propertyId: number, showCompleted: boolean, showArchived: boolean): Observable<TaskGroupPropertyModel[]> {
		const params = new HttpParams()
			.append('showCompleted', `${showCompleted}`)
			.append('showArchived', `${showArchived}`);

		return this.http.get<TaskGroupPropertyModel[]>(`${environment.apiUrl}/task/group/property/${propertyId}`, {params});
	}

	public getTaskGroupsByProspectId(prospectId: number, showCompleted: boolean, showArchived: boolean): Observable<TaskGroupProspectModel[]> {
		const params = new HttpParams()
			.append('showCompleted', `${showCompleted}`)
			.append('showArchived', `${showArchived}`);

		return this.http.get<TaskGroupProspectModel[]>(`${environment.apiUrl}/task/group/prospect/${prospectId}`, {params});
	}

	public getTasksByProspectId(prospectId: number, showCompleted: boolean, showArchived: boolean): Observable<TaskOccurrenceDto[]> {
		const params = new HttpParams()
			.append('showCompleted', `${showCompleted}`)
			.append('showArchived', `${showArchived}`);

		return this.http.get<TaskOccurrenceDto[]>(`${environment.apiUrl}/task/prospect/${prospectId}`, {params});
	}

	public getTasksByPropertyId(propertyId: number, showCompleted: boolean, showArchived: boolean): Observable<TaskOccurrenceDto[]> {
		const params = new HttpParams()
			.append('showCompleted', `${showCompleted}`)
			.append('showArchived', `${showArchived}`);

		return this.http.get<TaskOccurrenceDto[]>(`${environment.apiUrl}/task/property/${propertyId}`, {params});
	}

	public createLabel(label: ValueInterface): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/task/create/label`, label);
	}

	public createTaskGroupTemplate(template: TaskGroupTemplateModel): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/task/group/template`, template);
	}

	public createTaskTemplate(template: TaskTemplateModel, groupTemplateId: number): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/task/template/add/${groupTemplateId}`, template);
	}

	public createTask(task: TaskOccurrenceDto): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/task`, task);
	}

	public createTaskProperty(task: TaskOccurrenceDto): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/task/property`, task);
	}

	public createTaskProspect(task: TaskOccurrenceDto): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/task/prospect`, task);
	}

	public createTaskGroupProperty(propertyId: number, group: TaskGroupPropertyModel): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/task/group/property/${propertyId}`, group);
	}

	public createTaskGroupProspect(prospectId: number, group: TaskGroupProspectModel): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/task/group/prospect/${prospectId}`, group);
	}

	public createTaskGroupByTemplateId(info: {templateId: number, propertyId: number, prospectId: number}): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/task/group/create`, info);
	}

	public updateTaskAndOccurrences(task: TaskOccurrenceDto, updateRRule: boolean): Observable<boolean> {
		const params = new HttpParams()
			.append('updateRRule', `${updateRRule}`);

		return this.http.put<boolean>(`${environment.apiUrl}/task/all/${task.occurrence.taskId}`, task, {params});
	}

	public updateArchiveStatus(task: TaskOccurrenceDto): Observable<void> {
		return this.http.put<void>(`${environment.apiUrl}/task/update-archive-status`, task);
	}

	public updateOccurrence(task: TaskOccurrenceDto): Observable<boolean> {
		return this.http.put<boolean>(`${environment.apiUrl}/task/occurrence/${task.occurrence.id}`, task);
	}

	public updateTaskTemplate(taskTemplate: TaskTemplateModel): Observable<TaskTemplateModel> {
		return this.http.put<TaskTemplateModel>(`${environment.apiUrl}/task/template/${taskTemplate.id}`, taskTemplate);
	}

	public updateTaskStatus(taskOccurrence: TaskOccurrenceModel): Observable<number> {
		return this.http.put<number>(`${environment.apiUrl}/task/status/${taskOccurrence.id}`, taskOccurrence);
	}

	public updateTaskGroupTemplate(taskGroupTemplate: TaskGroupTemplateModel): Observable<TaskGroupTemplateModel> {
		return this.http.put<TaskGroupTemplateModel>(`${environment.apiUrl}/task/group/template/${taskGroupTemplate.id}`, taskGroupTemplate);
	}

	public updateTaskGroup(taskGroup: TaskGroupModel): Observable<number> {
		return this.http.put<number>(`${environment.apiUrl}/task/group/${taskGroup.id}`, taskGroup);
	}

	public updateLabel(label: ValueInterface): Observable<boolean> {
		return this.http.put<boolean>(`${environment.apiUrl}/task/label/${label.id}`, label);
	}

	public deleteLabel(id: number): Observable<void> {
		return this.http.delete<void>(`${environment.apiUrl}/task/label/${id}`);
	}

	public deleteTaskTemplate(id: number): Observable<number> {
		return this.http.delete<number>(`${environment.apiUrl}/task/template/${id}`);
	}

	public deleteTaskGroupTemplate(id: number): Observable<number> {
		return this.http.delete<number>((`${environment.apiUrl}/task/group/template/${id}`));
	}

	public deleteTaskAndOccurrences(id: number): Observable<number> {
		return this.http.delete<number>(`${environment.apiUrl}/task/all/${id}`);
	}

	public deleteOccurrence(id: number): Observable<number> {
		return this.http.delete<number>(`${environment.apiUrl}/task/occurrence/${id}`);
	}

	public deleteTaskGroup(id: number): Observable<number> {
		return this.http.delete<number>(`${environment.apiUrl}/task/group/${id}`);
	}

}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { timer } from 'rxjs/observable/timer';
import { debounce } from 'rxjs/operators';
import { UsersService } from '../../../admin/service/users.service';
import { SummaryService } from '../../service/summary.service';
import { UserListModel, UserModel } from '@dvp/is2-shared';
import { MatSelect, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs/Subscription';
import { DataSource } from '@angular/cdk/collections';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { TableColumnInterface } from '../../../../component/table/interface/table-column.interface';
import { UserService } from '../../../../service/user.service';

@Component({
	templateUrl: './property-summary.page.pug',
	styleUrls: ['./property-summary.page.scss'],
})
export class PropertySummaryPage implements OnInit, OnDestroy {

	public selectedUser = 0;

	public users: UserListModel[];
	public loading = false;

	public expiredPropertiesDatasource: DataSource<{propertyName: string, status: string, expirationDate: Date}>;
	public expiredPropertiesColumns: TableColumnInterface[] = [{
		columnDef: 'propertyName',
		title: 'Property Name',
	}, {
		columnDef: 'status',
		title: 'Status',
	}, {
		columnDef: 'expirationDate',
		title: 'Expiration Date',
		pipe: 'date',
	}];

	public expiredPropertiesCount: number;
	public categorySummaryDatasource: DataSource<{propertyCount: number, totalPrice: number, totalCommission: number, category: string, status: string}>;

	public categorySummaryColumns: TableColumnInterface[] = [{
		columnDef: 'category',
		title: 'Category',
	}, {
		columnDef: 'propertyCount',
		align: 'right',
		title: 'Count',
		pipe: 'number',
	}, {
		columnDef: 'totalCommission',
		align: 'right',
		title: 'Commission',
		pipe: 'currency',
	}, {
		columnDef: 'totalPrice',
		align: 'right',
		title: 'Price',
		pipe: 'currency',
	}];

	public totalProperties: number;
	public totalPropertyValue: number;
	public totalCommissionPotential: number;
	public isGtSm = false;
	public user: UserModel;
	public propertySummaryPage: boolean = true;

	@ViewChild('selectedUserControl', {static: true})
	private selectedUserControl: MatSelect;
	private summarySubscription: Subscription;

	constructor(
		private usersService: UsersService,
		private summaryService: SummaryService,
		private mediaObserver: MediaObserver,
		private userService: UserService,
	) {
		this.mediaObserver.media$.subscribe((change: MediaChange) => {
			this.isGtSm = change.mqAlias !== 'xs' && change.mqAlias !== 'sm';
		});
	}

	public ngOnInit() {
		this.loading = true;
		this.summarySubscription = forkJoin(this.usersService.getAllUsers(),
			this.summaryService.getExpiredProperties(),
			this.summaryService.getPropertyCategorySummary())
			.finally(() => {
				this.loading = false;
			})
			.subscribe(([users, expiredProperties, categorySummary]) => {
				this.users = users.filter(user => user.active);
				this.setData(expiredProperties, categorySummary);
			});

		this.selectedUserControl.selectionChange
			.pipe(debounce(() => timer(500)))
			.subscribe(this.getData.bind(this));

		this.user = this.userService.getUser();
	}

	public ngOnDestroy(): void {
		if (this.summarySubscription) {
			this.summarySubscription.unsubscribe();
			this.summarySubscription = null;
		}
	}

	public getData() {
		if (this.summarySubscription) {
			this.summarySubscription.unsubscribe();
			this.summarySubscription = null;
		}
		this.loading = true;

		this.summarySubscription = forkJoin(
			this.summaryService.getExpiredProperties(this.selectedUser),
			this.summaryService.getPropertyCategorySummary(this.selectedUser))
			.finally(() => {
				this.loading = false;
			})
			.subscribe(([expiredProperties, categorySummary]) => {
				this.setData(expiredProperties, categorySummary);
			});
	}

	public printSummary(): void {
		window.print();
	}

	private setData(expiredProperties, categorySummary) {
		this.expiredPropertiesCount = expiredProperties.length;
		this.totalProperties = categorySummary.reduce((sum, category) => sum + category.propertyCount, 0);
		this.totalPropertyValue = categorySummary.reduce((sum, category) => sum + category.totalPrice, 0);
		this.totalCommissionPotential = categorySummary.reduce((sum, category) => sum + category.totalCommission, 0);

		this.expiredPropertiesDatasource = new MatTableDataSource<{
			propertyName: string,
			status: string,
			expirationDate: Date
		}>(expiredProperties);
		this.categorySummaryDatasource = new MatTableDataSource<{
			propertyCount: number,
			totalPrice: number,
			totalCommission: number,
			category: string,
			status: string
		}>(categorySummary);
	}
}

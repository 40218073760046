import { HttpParams } from '@angular/common/http';

export abstract class Service {

	protected buildHttpParams(search: any): HttpParams {
		let params: HttpParams = new HttpParams();
		Object.keys(search).forEach(key => {
			if (Array.isArray(search[key])) {
				search[key].forEach(value => {
					params = params.append(key, value);
				});
			} else {
				params = params.append(key, search[key]);
			}
		});

		return params;
	}

}

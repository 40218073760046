import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, PRIMARY_OUTLET, Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { NavModel } from '../model/nav.model';
import { NavGroupModel } from '../model/nav-group.model';
import { NgProgress } from 'ngx-progressbar';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class NavigationService {

	public mainNavigationUpdates: Subject<NavModel[]> = new Subject();

	protected subject: Subject<NavModel> = new Subject();
	protected userNavigation: NavModel[] = [];
	protected mainNavigation: NavModel[] = [];
	protected activeSubNavigationKey: string = null;
	protected subNavigationDictionary: NavGroupModel = {};
	protected back: boolean;
	protected activePath: string[] = [];

	constructor(protected router: Router, protected ngProgress: NgProgress) {
		this.router.events.pairwise().subscribe((events) => {

			if (events[0] instanceof NavigationEnd && events[1] instanceof NavigationStart) {
				this.back = ((events[1] as NavigationStart).navigationTrigger === 'popstate');
			}

			if (events[1] instanceof NavigationStart) {
				ngProgress.start();
			}
			if (events[1] instanceof NavigationEnd) {
				const event: NavigationEnd = events[1] as NavigationEnd;

				const tree: UrlTree = this.router.parseUrl(event.urlAfterRedirects || event.url);
				const group: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
				this.activePath = (group && group.segments || []).map(segment => segment.path);

				this.notifyRouteChange((event.urlAfterRedirects ? event.urlAfterRedirects : event.url) || '');
				ngProgress.done();
			}
			if (events[1] instanceof NavigationCancel) {
				ngProgress.done();
			}
		});

		this.notifyRouteChange(this.router.routerState.snapshot.url);
	}

	/**
	 * Adds the passed nav to the NavModel container
	 *
	 * @param {NavModel[]} container
	 * @param {NavModel} nav
	 */
	protected static addNavModel(container: NavModel[], nav: NavModel) {
		container.push(nav);
		container.sort((a: NavModel, b: NavModel) => {
			return (a.ordinal || 0) - (b.ordinal || 0);
		});
	}

	protected notifyRouteChange(url: string) {
		for (const key in this.subNavigationDictionary) {
			if (this.subNavigationDictionary.hasOwnProperty(key)) {
				if (url.substr(0, key.length) === key) {
					this.activeSubNavigationKey = key;
					return;
				}
			}
		}
		this.activeSubNavigationKey = null;
	}

	public getUserNavigation(): NavModel[] {
		return this.userNavigation;
	}

	public getUserIconNavigation(): NavModel[] {
		return this.userNavigation.filter(nav => !!nav.iconClass);
	}

	public addMainNavigation(nav: NavModel) {
		if (!nav.hasOwnProperty('isActive')) {
			nav.isActive = (): boolean => {
				if (nav.hasOwnProperty('pathToMatch')) {
					return JSON.stringify(this.activePath.slice(0, nav.pathToMatch.length)) === JSON.stringify(nav.pathToMatch);
				} else if (nav.hasOwnProperty('path')) {
					return JSON.stringify(this.activePath.slice(0, nav.path.length)) === JSON.stringify(nav.path);
				}
				return false;
			};
		}

		if (!nav.href && nav.path) {
			nav.href = '/' + nav.path.join('/');
		}

		NavigationService.addNavModel(this.mainNavigation, nav);
		this.mainNavigationUpdated();
	}

	public addUserNavigation(nav: NavModel) {
		NavigationService.addNavModel(this.userNavigation, nav);
	}

	public addSubNavigation(url: string, nav: NavModel) {
		if (!this.subNavigationDictionary[url]) {
			this.subNavigationDictionary[url] = [];
		}

		if (!nav.isActive) {
			nav.isActive = (): boolean => nav.path && JSON.stringify(nav.path) === JSON.stringify(this.activePath);
		}
		if (!nav.href && nav.path) {
			nav.href = '/' + nav.path.join('/');
		}

		NavigationService.addNavModel(this.subNavigationDictionary[url], nav);
	}

	public getMainNavigation(): NavModel[] {
		return this.mainNavigation;
	}

	public getActiveSubNavigation(): NavModel[] {
		return this.hasActiveSubNavigation() ? this.subNavigationDictionary[this.activeSubNavigationKey] : [];
	}

	public hasActiveSubNavigation(): boolean {
		return !!this.activeSubNavigationKey;
	}

	public backClicked(): boolean {
		return !!this.back;
	}

	public navigate(nav: NavModel) {
		this.subject.next(nav);
		if (nav.action) {
			nav.action();
		}
	}

	public getObservable() {
		return this.subject;
	}

	public mainNavigationUpdated(): void {
		this.mainNavigationUpdates.next(this.mainNavigation);
	}

}

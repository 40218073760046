import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterService } from '../../service/router.service';

@Component({
	selector: 'is2-call-pop',
	templateUrl: './call-pop.component.pug',
	styleUrls: ['./call-pop.component.scss'],
})

export class CallPopComponent {

	@Input() callPopData: any;
	@Input() callEnded: boolean;

	@Output()
	public action: EventEmitter<string> = new EventEmitter();

	@Output()
	public toggleCallPopEvent: EventEmitter<void> = new EventEmitter();

	constructor(protected router: RouterService) {}

	public close() {
		this.action.emit('close');
	}

	public goToProspect(): void {
		window.open(`/prospect/detail/${this.callPopData.id}`);
	}

	public createProspect(): void {
		this.router.navigate([`/prospect/create`], { queryParams: this.callPopData, queryParamsHandling: 'merge' });
	}

	public toggleCallPop() {
		this.toggleCallPopEvent.emit();
		if (this.callEnded) {
			this.close();
		}
	}

}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs/Subscription';
import { ToastrService } from 'ngx-toastr';
import { ProspectListModel, PropertyListModel, MailingModel, ValueInterface, ProspectSearchDto, ValuesModel } from '@dvp/is2-shared';
import { ProspectSearchService } from '../../service/prospect-search.service';
import { NavigationService } from '../../../../service/navigation.service';
import { StorageService } from '../../../../service/storage.service';
import { PropertySearchDialogComponent } from '../../../../component/property-search-dialog/property-search-dialog.component';
import { YesNoDialogComponent } from '../../../../component/yes-no-dialog/yes-no-dialog.component';
import { MailingDialogComponent } from '../../../../component/mailing-dialog/mailing-dialog.component';
import { ProspectSelectionDialogComponent } from '../../component/prospect-selection-dialog/prospect-selection-dialog.component';
import { ProspectRestrictionDialogComponent } from '../../component/prospect-restriction-dialog/prospect-restriction-dialog.component';
import { PromptDialogComponent } from '../../../../component/prompt-dialog/prompt-dialog.component';
import { ProspectSelectionEventInterface } from '../../../../interface/prospect-selection-event.interface';
import { ProspectSearchComponent } from '../../../../component/prospect-search/prospect-search.component';
import { StoredSearchInterface } from '../../../../interface/stored-search.interface';
import { ValuesService } from '../../../../service/values.service';
import { saveAs } from 'file-saver';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UserService } from '../../../../service/user.service';
import * as moment from 'moment';
import * as isEqual from 'lodash.isequal';
import { PrintColumnSelectionDialogComponent } from '../../../../component/print-column-selection-dialog/print-column-selection-dialog.component';

@Component({
	templateUrl: './prospect-search.page.pug',
	styleUrls: ['./prospect-search.page.scss'],
})
export class ProspectSearchPage implements OnInit, OnDestroy {

	public dataSource: MatTableDataSource<ProspectListModel>;
	public resultList: ProspectListModel[];
	public bulkProspects: ProspectListModel[];
	public searchList: ProspectSearchDto[] = [];
	public loading = false;
	public isMobile: boolean = false;
	public initialSearch: StoredSearchInterface<ProspectSearchDto, ProspectListModel>;
	public currentSearch: ProspectSearchDto;
	public bulkEditMode: boolean = false;
	public hasAdminRights: boolean = false;
	public itemsSelected: boolean = false;

	protected mediaSubscription: Subscription;
	protected navigationSubscription: Subscription;
	protected pdfSubscription: Subscription;
	protected mailingTypes: ValueInterface[];
	protected values: ValuesModel;

	@ViewChild('prospectSearchComponent', {static: true}) protected prospectSearchComponent: ProspectSearchComponent;

	constructor(
		protected prospectSearchService: ProspectSearchService,
		protected valuesService: ValuesService,
		protected navigationService: NavigationService,
		protected route: ActivatedRoute,
		protected yesNoDialog: MatDialog,
		protected promptDialog: MatDialog,
		protected propertySearchDialog: MatDialog,
		protected mergeLetterDialog: MatDialog,
		protected toastrService: ToastrService,
		protected prospectSelectionDialog: MatDialog,
		protected prospectRestrictionDialog: MatDialog,
		protected printColumnSelectionDialog: MatDialog,
		protected userService: UserService,
		breakpointObserver: BreakpointObserver,
	) {
		this.navigationSubscription = this.navigationService.getObservable().subscribe(() => {
			this.clearSearchResults();
		});
		this.mediaSubscription = breakpointObserver
			.observe(Breakpoints.Handset)
			.subscribe(result => {
				this.isMobile = result.matches;
			});
	}

	public ngOnInit(): void {
		this.hasAdminRights = (this.userService.hasRole('Admin') || this.userService.hasRole('Secretary'));
		if (this.navigationService.backClicked() || !!this.route.snapshot.queryParams.restore) {
			this.restorePreviousSearch();
		}
		this.getValues();
	}

	public ngOnDestroy(): void {
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
			this.navigationSubscription = null;
		}

		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}
	}

	public searched(storedSearch: StoredSearchInterface<ProspectSearchDto, ProspectListModel>): void {
		this.currentSearch = storedSearch.dto;
		storedSearch.results.data = this.resultList = this.mapDataSource(storedSearch.results.data);
		StorageService.set('prospectSearch', storedSearch);
	}

	public selected(selection: ProspectSelectionEventInterface): void {
		if (!StorageService.has('prospectSearch')) {
			return;
		}

		const prospectSearch: StoredSearchInterface<ProspectSearchDto, ProspectListModel> = StorageService.get('prospectSearch');
		prospectSearch.previouslySelectedId = selection.prospect.id;
		StorageService.set('prospectSearch', prospectSearch);
	}

	public async printSearchResults() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
		}

		await this.addToSearchList();

		this.loading = true;
		const tab: Window = window.open('/loading');
		this.pdfSubscription = this.prospectSearchService.getSearchResultsPdf(this.searchList)
			.finally(() => {
				this.loading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}

	public async printHasSearchResults() {
		await this.addToSearchList();

		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
		}

		this.printColumnSelectionDialog.open(PrintColumnSelectionDialogComponent, {
			data: {
				reportType: 'Prospect',
				showGroupBy: true,
				includeHas: true,
				currentSearch: this.searchList,
			},
		}).afterClosed().subscribe(() => {
			if (this.searchList.length === 1) {
				this.searchList = [];
			}
		});
	}

	public async printBulkSearchPdf() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
		}

		await this.printSelection();
	}

	public async printSelection(): Promise<boolean> {
		await this.addToSearchList();

		return new Promise<boolean>((resolve: any): void => {
			this.printColumnSelectionDialog
				.open(PrintColumnSelectionDialogComponent, {
					width: '800px',
					data: {
						reportType: 'Prospect',
						currentSearch: this.searchList,
					},
				})
				.afterClosed().subscribe(() => {
					resolve();
					if (this.searchList.length === 1) {
						this.searchList = [];
				}
			});
		});
	}

	public async csvClick(): Promise<void> {
		await this.addToSearchList();

		if (this.checkSearchListForRestrictionDialog()) {
			try {
				this.searchList = await this.askAboutRestrictions();
			} catch (e) {
				return; // user cancelled
			}
		}

		const bulkProspects = await this.getBulkSearchResults();

		let excludedProspectIds: number[];
		try {
			excludedProspectIds = await this.showProspectExclusionDialog(bulkProspects);
		} catch (e) {
			return; // user cancelled
		}

		const shouldCreateMailing: string = await this.askAboutMailing();
		if (shouldCreateMailing === 'Cancel') {
			return; // user cancelled
		}
		let mailing: MailingModel;
		if (shouldCreateMailing === 'Yes') {
			try {
				mailing = await this.createMailingRecord(bulkProspects.filter(prospect => !excludedProspectIds.includes(prospect.id)));
			} catch (e) {
				return; // user cancelled
			}
		}

		let filename: string;
		try {
			filename = await this.promptForCsvFilename();
		} catch (e) {
			return; // user cancelled
		}

		this.exportToCsv(this.searchList, excludedProspectIds, mailing, filename);
	}

	public checkSearchListForRestrictionDialog() {
		const restrictionMap: {canSendEmail: boolean, canSendLetter: boolean}[] = this.searchList.map(dto => {
			if (dto.details) {
				return {
					canSendEmail: dto.details.canSendEmail,
					canSendLetter: dto.details.canSendLetter,
				};
			}
		});

		const checkAllValuesUndefined = (arr: Object[], prop: string) => arr.every(value => typeof value[prop] === 'undefined');

		const checkAllValuesEqual = arr => arr.every(val => isEqual(arr[0], val));

		if (checkAllValuesUndefined(restrictionMap, 'canSendEmail') && checkAllValuesUndefined(restrictionMap, 'canSendLetter')) {
			return true;
		} else if (checkAllValuesEqual(restrictionMap)) {
			return false;
		}

		return true;
	}

	public async addToSearchList(): Promise<void> {
		if (this.searchList.find(i => isEqual(this.currentSearch, i))) {
			return;
		}

		this.searchList.push(this.currentSearch);
	}

	public resetSearchList() {
		this.searchList = [];
	}

	public dataTransformation = (prospect: ProspectListModel): void => {
		(prospect as any).rowHref = `/prospect/detail/${prospect.id}`;
	};

	public toggleBulkEditMode(event?: MouseEvent): void {
		if (event) {
			this.prospectSearchComponent.toggleBulkEditMode(event, this.currentSearch);
		} else {
			this.bulkEditMode = !this.bulkEditMode;
		}
	}

	public itemsSelectedEvent(event: boolean): void {
		this.itemsSelected = !!event;
	}

	public editSelectedRows(event): void {
		this.prospectSearchComponent.editSelectedRows(event);
	}

	protected mapDataSource(data: ProspectListModel[]): ProspectListModel[] {
		return data.map(prospect => {
			this.dataTransformation(prospect);
			return prospect;
		});
	}

	protected getValues(): void {
		this.valuesService.getValues()
			.finally(() => {})
			.subscribe(values => {
				this.values = values;
				this.mailingTypes = values.mailingType;
			});
	}

	protected clearSearchResults(): void {
		StorageService.remove('prospectSearch');
		this.resultList = undefined;
		this.prospectSearchComponent.clearSearchResults();
	}

	protected askAboutMailing(): Promise<string> {
		return new Promise<string>((resolve: any): void => {
			const dialog = this.yesNoDialog.open(YesNoDialogComponent, {
				disableClose: true,
				data: {
					title: 'Create Mailing Record?',
					question: 'Do you want to create a mailing record?',
					allowCancel: true,
				},
			});
			dialog.afterClosed().subscribe(result => resolve(result));
		});
	}

	protected createMailingRecord(prospects: ProspectListModel[]): Promise<MailingModel> {
		return new Promise<MailingModel>(async (resolve: any, reject: any): Promise<void> => {
			try {
				const properties: PropertyListModel[] = await this.showPropertySearchForm();
				const mailing: MailingModel = await this.showMailingForm(properties, prospects);
				resolve(mailing);
			} catch (e) {
				reject();
			}
		});
	}

	protected showPropertySearchForm(): Promise<PropertyListModel[]> {
		return new Promise<PropertyListModel[]>((resolve: any, reject: any): void => {
			const searchPropertiesDialog = this.propertySearchDialog.open(PropertySearchDialogComponent, {
				width: '1000px',
				data: {
					multiple: true,
				},
			});
			searchPropertiesDialog.afterClosed().subscribe((properties: PropertyListModel[]) => {
				if (!properties || properties.length === 0) {
					reject();
					return;
				}
				resolve(properties);
			});
		});
	}

	protected async showMailingForm(properties: PropertyListModel[], prospects: ProspectListModel[]): Promise<MailingModel> {
		return new Promise<MailingModel>(async (resolve: any, reject: any): Promise<void> => {
			const search = new ProspectSearchDto();
			const params: string = search.getSearchParams(this.searchList, this.values, this.userService.getUser());
			const agentDiff: {agent1: number, agent2: number} = this.getAgentComparisons(properties);

			const createMailingDialog = this.mergeLetterDialog.open(MailingDialogComponent, {
				width: '500px',
				data: {
					mailing: new MailingModel({
						properties,
						prospects,
						dateSent: moment().format('YYYY-MM-DD'),
						source: 'REIS',
						output: 'Email',
						type: 'Marketing',
						parameters: params,
						agent1: agentDiff.agent1 === 0 ? properties[0].agent1Id : null,
						agent2: agentDiff.agent2 === 0 ? properties[0].agent2Id : null,
					}),
					action: 'Add',
					mailingTypes: this.mailingTypes,
					canEdit: true,
				},
			});
			createMailingDialog.afterClosed().subscribe((result: string | MailingModel) => {
				if (!(result instanceof MailingModel)) {
					reject();
					return;
				}
				delete result.prospects;
				if (result.dateSent && (result.dateSent as any).format) {
					result.dateSent = (result.dateSent as any).format('YYYY-MM-DD');
				}
				resolve(result);
			});
		});
	}

	protected getAgentComparisons(properties: PropertyListModel[]): {agent1: number, agent2: number} {
		let agent1Diff: number = 0;
		let agent2Diff: number = 0;

		properties.map((property: PropertyListModel) => {
			properties.map((property2: PropertyListModel) => {
				if (property.agent1Id !== property2.agent1Id) {
					agent1Diff++;
				}

				if (property.agent2Id !== property2.agent2Id) {
					agent2Diff++;
				}
			});
		});

		return {
			agent1: agent1Diff,
			agent2: agent2Diff,
		};
	}

	protected promptForCsvFilename(): Promise<string> {
		return new Promise<string>((resolve: any, reject: any): void => {
			this.promptDialog
				.open(PromptDialogComponent, {
					width: '400px',
					disableClose: true,
					data: {
						title: 'CSV Filename',
						allowCancel: true,
						placeholder: 'Filename',
						value: 'prospects.csv',
					},
				})
				.afterClosed()
				.subscribe(result => {
					if (result === false) {
						reject();
					} else {
						resolve(result);
					}
				});
		});
	}

	protected getBulkSearchResults() {
		return new Promise<any>((resolve: any, reject: any): void => {
			this.loading = true;
			this.prospectSearchService.getBulkSearchResults(this.searchList)
				.finally(() => {
					this.loading = false;
					return resolve(this.bulkProspects);
				})
				.subscribe((prospects: ProspectListModel[]) => {
					this.bulkProspects = prospects;
				}, () => {
					this.loading = false;
					return reject();
				});
		});
	}

	protected showProspectExclusionDialog(prospects) {
		return new Promise<any>((resolve: any, reject: any): void => {
			const selectProspectsToExcludeDialog = this.prospectSelectionDialog.open(ProspectSelectionDialogComponent, {
				width: '1000px',
				data: {
					prospects,
					title: 'Select Prospects to Exclude',
					selectedFontIcon: 'fa-ban',
					selectedFontIconColor: 'red',
				},
			});

			selectProspectsToExcludeDialog.afterClosed().subscribe((result: ProspectListModel[]) => {
				if (!result) {
					reject();
					return;
				}

				return resolve(result.map(prospect => prospect.id));
			});
		});
	}

	protected exportToCsv(searchDtos: ProspectSearchDto[], excludedProspectIds: number[], mailing: MailingModel, filename: string) {
		this.loading = true;
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
		}
		this.pdfSubscription = this.prospectSearchService.getSearchResultsCsv(searchDtos, excludedProspectIds, mailing)
			.finally(() => {
				this.loading = false;
				this.resetSearchList();
				this.clearSearchResults();
			})
			.subscribe((data) => {
				if (mailing) {
					this.toastrService.success('Mailing record successfully saved.');
				}

				if (!filename.match(/\.csv$/)) {
					filename += '.csv';
				}

				const file = new Blob([data], {type: 'text/csv'});
				saveAs(file, filename);

				if (this.searchList.length === 1) {
					this.searchList = [];
				}
			});
	}

	protected restorePreviousSearch(): void {
		this.initialSearch = StorageService.has('prospectSearch') && StorageService.get('prospectSearch');
	}

	protected askAboutRestrictions(): Promise<ProspectSearchDto[]> {
		return new Promise((resolve, reject) => {
			this.prospectRestrictionDialog
				.open(ProspectRestrictionDialogComponent, {
					width: '500px',
				})
				.afterClosed()
				.subscribe(restrictions => {
					if (!restrictions) {
						reject();
						return;
					}

					return resolve(this.updateSearchRestrictions(restrictions));
				});
		});
	}

	protected updateSearchRestrictions(restrictions: {canSendEmailDropdown: string, canSendLetterDropdown: string}) {
		const getRestrictionValue = (dropdownValue: string, canSend: boolean) => {
			switch (dropdownValue) {
				case 'dontChange':
					return canSend;
				case 'clear':
					return null;
				case 'yes':
					return true;
				case 'no':
					return false;
				default:
					throw new Error('Value not recognized.');
			}
		};

		return this.searchList.map(dto => {
			if (dto.details && restrictions) {
				dto.details.canSendEmail = getRestrictionValue(restrictions.canSendEmailDropdown, dto.details.canSendEmail);
				dto.details.canSendLetter = getRestrictionValue(restrictions.canSendLetterDropdown, dto.details.canSendLetter);
			}
			return dto;
		});
	}

}

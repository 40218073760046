import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'action-button',
	templateUrl: './action-button.component.pug',
	styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent {

	@Input() public icon: string;
	@Input() public disabled = false;
	@Input() public tooltip: string;

	@Output() public action: EventEmitter<any> = new EventEmitter<any>();
}

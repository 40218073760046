import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportService } from '../../service/report.service';
import 'rxjs/add/operator/finally';
import * as moment from 'moment';
import { BarChartData, UserModel, ValueInterface } from '@dvp/is2-shared';
import { FormBuilder } from '@angular/forms';
import { UserService } from '../../../../service/user.service';
import { ChartModalComponent } from '../chart-modal/chart-modal.component';

@Component({
	selector: 'is2-call-count-by-team-by-date',
	templateUrl: './call-count-by-user-by-date.component.pug',
	styleUrls: ['./call-count-by-user-by-date.component.scss'],
})
export class CallCountByUserByDateComponent implements OnInit {

	@ViewChild('fullscreen', {static: true})
	private fullscreen: ChartModalComponent;

	private startDate = moment().startOf('week').toISOString();
	private endDate = moment().endOf('week').toISOString();

	public loading = false;
	public options: any = null;
	public user: UserModel;
	public noCalls: boolean = false;
	public dropdown: ValueInterface[] = [
		{id: 'week', name: 'This Week', selected: true},
		{id: 'month', name: 'This Month'},
	];

	constructor(private reportService: ReportService, private fb: FormBuilder, private userService: UserService) {
	}

	public ngOnInit(): void {
		this.user = this.userService.getUser();
		this.loadChart();
	}

	private loadChart(): void {
		this.reportService.getCallCountByUserByDate(this.startDate, this.endDate, this.user.userId).finally(() => {
			this.loading = false;
		})
			.subscribe((chartData: BarChartData) => {

					if (Object.keys(chartData).length === 0 && chartData.constructor === Object) {
						this.noCalls = true;
					}

					const seriesData = [];

					for (const i in chartData) {
						if (!chartData.hasOwnProperty(i)) {
							continue;
						}
						seriesData.push({
							name: moment(chartData[i].title).format('MM/DD/YYYY'),
							y: chartData[i].value,
						});
					}

					this.options = {
						chart: {
							type: 'column',
						},

						title: false,

						xAxis: {
							type: 'category',
						},

						yAxis: {
							title: {
								text: 'Total Calls',
							},
						},

						credits: {
							enabled: false,
						},

						legend: {
							enabled: false,
						},

						series: [{
							name: 'Call Count',
							colorByPoint: true,
							data: seriesData,
						}],

						responsive: {
							rules: [{
								condition: {
									maxWidth: 500,
								},
								chartOptions: {
									legend: {
										align: 'center',
										verticalAlign: 'bottom',
										layout: 'horizontal',
										x: 0,
										y: 0,
									},
								},
							}],
						},
					};
				},
				(error: any) => {
				},
			);
	}

	public dropdownChangeEvent(timeframe: string): void {
		if (timeframe === 'month') {
			this.startDate = moment().startOf('month').toISOString();
			this.endDate = moment().endOf('month').toISOString();
		}

		if (timeframe === 'week') {
			this.startDate = moment().startOf('week').toISOString();
			this.endDate = moment().endOf('week').toISOString();
		}

		this.loadChart();
	}

	public openFullscreen() {
		this.fullscreen.show();
	}

}

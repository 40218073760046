import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TaskGroupTemplateModel, ValueInterface } from '@dvp/is2-shared';
import { TaskService } from '../../../../service/task.service';
import { LabelDialogComponent } from '../../component/label-dialog/label-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from '../../../../component/deleteDialog/delete-dialog.component';


@Component({
	templateUrl: './task-template.page.pug',
	styleUrls: ['./task-template.page.scss'],
})

export class TaskTemplatePage implements OnInit {

	public loading: boolean = false;
	public taskGroups: TaskGroupTemplateModel[];
	public labels: ValueInterface[];

	constructor(
		protected router: Router,
		protected taskService: TaskService,
		protected labelDialog: MatDialog,
		protected toastrService: ToastrService,
		protected deleteDialog: MatDialog,
	) {}

	public navigate(id: number): void {
		this.router.navigate([`/admin/task/template/${id}`]);
	}

	public ngOnInit(): void {
		this.loading = true;
		this.taskService.getAllTaskGroupTemplates().subscribe((results: TaskGroupTemplateModel[]) => {
			this.taskGroups = results;
			this.loading = false;
		});
		this.getLabels();
	}

	public getLabels(): void {
		this.taskService.getLabels().subscribe((results: ValueInterface[]) => {
			this.labels = results;
		});
	}

	public showLabelDialog(label?: ValueInterface) {
		this.labelDialog.open(LabelDialogComponent, {
			width: '400px',
			data: {
				label: label || {id: null, name: null},
			},
		}).afterClosed().subscribe((result) => {
			if (result === 'Cancel') {
				return;
			}

			if (result === 'Delete') {
				this.deleteDialog.open(DeleteDialogComponent, {
					width: '300px',
					data: {
						text: 'this task label',
						type: 'Task Label',
					},
				}).afterClosed().subscribe((data) => {
					if (data === 'Delete') {
						this.taskService.deleteLabel((label.id as number)).subscribe(() => {
							this.toastrService.success('Task Label successfully deleted');
						});
						this.getLabels();
					}
				});
			} else {
				if (!result.id) {
					this.taskService.createLabel(result).subscribe((data) => {
						if (data) {
							this.toastrService.success('Task Label successfully created');
							this.getLabels();
						}
					}, (error) => {
						this.toastrService.error('Something went wrong while creating the label: ', error.error.message);
					});
				} else {
					this.taskService.updateLabel(result).subscribe((data) => {
						if (data) {
							this.toastrService.success('Task Label successfully updated');
							this.getLabels();
						}
					});
				}

			}
		});
	}

}

import { Component } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../service/user.service';
import { DialService } from '../../service/dial.service';
import { TableFunctionalityComponent } from '../table-functionality/table-functionality.component';

@Component({
	selector: 'is2-mobile-table',
	templateUrl: './mobile-table.component.pug',
	styleUrls: ['./mobile-table.component.scss'],
})

export class MobileTableComponent extends TableFunctionalityComponent {

	constructor(
		protected mediaObserver: MediaObserver,
		protected toastrService: ToastrService,
		protected userService: UserService,
		protected dialService: DialService,
	) {
		super(mediaObserver, toastrService, userService, dialService);
	}

}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ValuesService} from '../../../../service/values.service';
import {LocationCodeModel} from '@dvp/is2-shared/dist/model/location-code.model';
import {CodesService} from '../../service/codes.service';

@Component({
	templateUrl: './codes.page.pug',
	styleUrls: ['./codes.page.scss'],
})
export class CodesPage {

	constructor(private codesService: CodesService, private router: Router) {

	}

}

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValuesService } from '../../../../service/values.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import {
	PropertyMatchModel,
	PropertyModel,
	ProspectListModel,
	ProspectDetailsModel,
	ProspectModel,
	ProspectRemarkModel,
	UserModel,
	ValuesModel,
	UserListModel,
} from '@dvp/is2-shared';
import { ScrollSpyIndexService, ScrollSpyService } from 'ngx-scrollspy';
import { HttpErrorResponse } from '@angular/common/http';
import { ProspectService } from '../../service/prospect.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { UserService } from '../../../../service/user.service';
import { CanComponentDeactivate } from '../../../../guard/can-deactivate.guard';
import { UnsavedChangesDialogComponent } from '../../../../component/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { Moment } from 'moment';
import * as moment from 'moment';
import { RouterService } from '../../../../service/router.service';
import { ProspectDuplicateDialogComponent } from '../../component/prospect-duplicate-dialog/prospect-duplicate-dialog.component';
import { ProspectDuplicateDialogDataInterface } from '../../interface/prospect-duplicate-dialog-data.interface';
import { ProspectRemarkService } from '../../service/prospect-remark.service';
import { DeleteExplanationDialogComponent } from '../../component/delete-explanation-dialog/delete-explanation-dialog.component';
import { DeleteExplanationDialogDataInterface } from '../../interface/delete-explanation-dialog-data.interface';
import { ShownComponent } from '../../../../component/shown/shown.component';
import { ProspectDefaultsInterface } from '../../component/prospect-create/prospect-create.component';
import { ProspectRemarksComponent } from '../../component/prospect-page/prospect-remarks/prospect-remarks.component';
import { UsersService } from '../../../admin/service/users.service';

@Component({
	selector: 'prospect-page',
	templateUrl: './prospect.page.pug',
	styleUrls: ['./prospect.page.scss'],
})
export class ProspectPage implements OnInit, AfterViewInit, CanComponentDeactivate {

	protected _prospect: ProspectModel;
	@Input('prospect')
	set prospect(prospect: ProspectModel) {
		this._prospect = prospect;

		if (this.prospectFormGroup) {
			this.initializeProspect(prospect);
		}
	}

	get prospect() {
		return this._prospect;
	}

	@Input() public showSidenav: boolean = true;

	@Input() public prospectDefaults: ProspectDefaultsInterface;

	@Output() protected save: EventEmitter<number> = new EventEmitter();
	@Output() protected newProspect: EventEmitter<ProspectModel> = new EventEmitter();
	@Output() protected prospectSelected: EventEmitter<ProspectListModel> = new EventEmitter();
	@Output() protected prospectDeleted: EventEmitter<number> = new EventEmitter<number>();

	protected shownComponent: ShownComponent;
	@ViewChild(ShownComponent, {static: false}) set shownCmp(component: ShownComponent) {
		if (component !== undefined) {
			this.shownComponent = component;
		}
	}

	protected prospectRemarksComponent: ProspectRemarksComponent;
	@ViewChild(ProspectRemarksComponent, {static: false}) set remarksComponent(component: ProspectRemarksComponent) {
		if (component !== undefined) {
			this.prospectRemarksComponent = component;
		}
	}

	public loading = false;
	public pdfLoading = false;
	public prospectFormGroup: FormGroup;
	public values: ValuesModel;
	public createMode = false;
	public hasAdminRights = false;
	public hasRoutedToRights = false;
	public hasSalesRights = false;
	public isDeleted = false;
	public backToLink: {title: string, url: string};
	public needToCheckDuplicates: boolean = false;
	public nameOrCoChanged: boolean = false;
	public currentSection: string = 'details';
	public user: UserModel;
	public allUsers: UserListModel[];

	constructor(
		protected valuesService: ValuesService,
		protected route: ActivatedRoute,
		protected fb: FormBuilder,
		protected router: Router,
		protected scrollSpyService: ScrollSpyService,
		protected scrollSpyIndex: ScrollSpyIndexService,
		protected prospectService: ProspectService,
		protected toastrService: ToastrService,
		protected unsavedChangesDialog: MatDialog,
		protected userService: UserService,
		protected usersService: UsersService,
		protected changeDetectionRef: ChangeDetectorRef,
		protected routerService: RouterService,
		protected duplicateDialog: MatDialog,
		protected deleteExplainDialog: MatDialog,
		protected remarkService: ProspectRemarkService,
	) {}

	public async ngOnInit(): Promise<void> {
		this.loading = true;

		this.backToLink = this.getBackToLink(this.routerService.getPreviousUrl());
		const valuesPromise: Promise<void> = this.getValues();
		this.allUsers = await this.usersService.getAllUsers().toPromise();

		await this.getData();

		if (!this.prospect) {
			this.createMode = true;
		}

		this.setPermissions();
		this.createFormGroup();

		if (this.createMode) {
			await valuesPromise;
			await this.showDuplicateDialog();
		}

		this.loading = false;
	}

	public ngAfterViewInit(): void {
		this.scrollSpyService.getObservable('content2').subscribe((e: any) => {
			const sectionId: string = this.getScrollSpySection(e);

			if (sectionId && sectionId !== this.currentSection) {
				this.currentSection = sectionId;
				this.updateUrlFragment(sectionId);
			}
		});

		this.route.fragment.subscribe(fragment => {
			if (!fragment) {
				return;
			}

			setTimeout(() => {
				this.goToSection(fragment);
			}, 1000);
		});
	}

	public async getData(): Promise<void> {
		if (!this.prospect) {
			await this.loadProspect();
		}

		if (this.prospect) {
			this.isDeleted = this.prospect.details.status.toUpperCase() === 'DELETED';
		}
	}

	public resetCreate(): void {
		this.prospectFormGroup.reset();
	}

	public initializeDetailsFormGroup() {
		this.user = this.userService.getUser();

		if (this.createMode && this.userService.hasRole('Salesman')) {
			this.prospect.details.inputtedByAgentId = this.user.userId;
			this.prospect.details.inputtedByAgentName = `${this.user.firstName} ${this.user.lastName}`;
			this.prospect.details.routeToAgentId = this.user.userId;
		}

		if (this.prospectDefaults) {
			this.prospect.details.workPhone = this.prospectDefaults.workPhone;
		}

		return this.fb.group({
			status: [this.prospect.details.status],
			code: [this.prospect.details.code],
			title: [this.prospect.details.title],
			salutation: [this.prospect.details.salutation, Validators.maxLength(50)],
			firstName: [this.prospect.details.firstName, Validators.maxLength(64)],
			lastName: [this.prospect.details.lastName, Validators.maxLength(64)],
			middleInitial: [this.prospect.details.middleInitial, Validators.maxLength(1)],
			spouse: [this.prospect.details.spouse, Validators.maxLength(30)],
			assistant: [this.prospect.details.assistant, Validators.maxLength(30)],
			company: [this.prospect.details.company, Validators.maxLength(64)],
			inputtedByAgentId: [this.prospect.details.inputtedByAgentId],
			inputtedByAgentName: [this.prospect.details.inputtedByAgentName],
			routeToAgentId: [this.prospect.details.routeToAgentId],
			operator: [this.prospect.details.operator],
			callSources: [this.prospect.details.callSources],
			prospectTypes: this.fb.array(this.prospect.details.prospectTypes.map(type => [type])),
			address1: [this.prospect.details.address1, Validators.maxLength(50)],
			address2: [this.prospect.details.address2, Validators.maxLength(50)],
			city: [this.prospect.details.city, Validators.maxLength(50)],
			state: [this.prospect.details.state || 'MI Michigan'],
			zipcode: [this.prospect.details.zipcode, Validators.maxLength(5)],
			email: [this.prospect.details.email, [this.emailValidator, Validators.maxLength(50)]],
			website: [this.prospect.details.website, Validators.maxLength(50)],
			workPhone: [this.prospect.details.workPhone],
			extension: [this.prospect.details.extension, Validators.maxLength(5)],
			homePhone: [this.prospect.details.homePhone],
			cellPhone: [this.prospect.details.cellPhone],
			faxNumber: [this.prospect.details.faxNumber],
			pager: [this.prospect.details.pager],
			jobTitle: [this.prospect.details.jobTitle, Validators.maxLength(200)],
			canSendLetter: [this.prospect.details.canSendLetter],
			canSendEmail: [this.prospect.details.canSendEmail],
			lastModified: [this.prospect.details.lastModified ? new Date(this.prospect.details.lastModified) : null],
			lastCorrespondence: [this.prospect.details.lastCorrespondence ? new Date(this.prospect.details.lastCorrespondence) : null],
			lastCalled: [this.prospect.details.lastCalled ? new Date(this.prospect.details.lastCalled) : null],
			nextContactDate: [this.prospect.details.nextContactDate ? new Date(this.prospect.details.nextContactDate) : null],
		});
	}

	public initializeMiscFormGroup() {
		return this.fb.group({
			building: [this.prospect.misc.building, Validators.maxLength(30)],
			personalMail: [this.prospect.misc.personalMail, Validators.maxLength(200)],
			newsletter: [this.prospect.misc.newsletter, Validators.maxLength(30)],
			sicCode: [this.prospect.misc.sicCode, Validators.maxLength(20)],
		});
	}

	public initializeProspect(prospect?: ProspectModel) {
		if (!prospect) {
			prospect = this.prospect;
		}

		const {details} = prospect;

		details.state = this.prospect.details.state || 'MI Michigan';
		details.lastModified = this.prospect.details && this.prospect.details.lastModified ? new Date(this.prospect.details.lastModified) as any : null;
		details.lastCalled = this.prospect.details && this.prospect.details.lastCalled ? new Date(this.prospect.details.lastCalled) as any : null;
		details.nextContactDate = this.prospect.details && this.prospect.details.nextContactDate ? new Date(this.prospect.details.nextContactDate) as any : null;

		if (this.prospect.details && this.prospect.details.lastCorrespondence) {
			details.lastCorrespondence = new Date(this.prospect.details.lastCorrespondence) as any;
		} else {
			details.lastCorrespondence = null;
		}

		this.prospectFormGroup.patchValue({
			details,
			misc: this.prospect.misc,
		});

		this.prospectFormGroup.markAsPristine();

		this.changeDetectionRef.detectChanges();
	}

	public createFormGroup() {
		if (!this.prospect) {
			this._prospect = new ProspectModel({
				details: new ProspectDetailsModel({
					lastCorrespondence: new Date(),
					lastCalled: new Date(),
					canSendLetter: true,
					canSendEmail: true,
				}),
			});
			const element = document.querySelector('#details');
			if (element) {
				element.scrollIntoView(true);
			}
		}

		this.prospectFormGroup = this.fb.group({
			details: this.initializeDetailsFormGroup(),
			misc: this.initializeMiscFormGroup(),
		});
	}

	public revert() {
		const dialog = this.unsavedChangesDialog.open(UnsavedChangesDialogComponent, {
			width: '400px',
			data: {
				hideSave: true,
			},
		});

		dialog.afterClosed().subscribe(choice => {
			if (choice === 'Discard') {
				return this.initializeProspect();
			}
		});

	}

	public saveButtonClicked(): Promise<boolean> {
		return this.saveChanges();
	}

	public async addMatchMakerShown(event: {match: PropertyMatchModel, result: PropertyModel, event}): Promise<void> {
		await this.shownComponent.addMatchMakerShown(event.match, event.result, event.event);
	}

	public async confirmDelete(): Promise<void> {
		if (!this.hasRoutedToRights) {
			return;
		}

		let result: DeleteExplanationDialogDataInterface;
		try {
			result = await this.promptForExplanation();
		} catch (e) {
			return;
		}

		try {
			await this.deleteProspect(this.prospect.id);
		} catch (error) {
			this.toastrService.error(error.message);
			return;
		}

		try {
			await Promise.all(result.remarks.map(remark => this.createDeletedRemark(remark)));
		} catch (error) {
			this.toastrService.error(error.message);
		}

		this.toastrService.success('Prospect #' + this.prospect.id + ' successfully deleted.');

		if (this.router.url === '/prospect/past-due-contacts' || this.router.url === '/prospect/next-contacts') {
			this.prospectDeleted.emit(this.prospect.id);
			return;
		} else {
			// noinspection JSIgnoredPromiseFromCall
			this.router.navigateByUrl('/prospect/search');
		}
	}

	public setPermissions(): void {
		this.hasAdminRights = (this.userService.hasRole('Admin') || this.userService.hasRole('Secretary'));
		this.hasRoutedToRights = (this.hasAdminRights || this.prospect && (this.userService.getUser().userId === this.prospect.details.routeToAgentId));
		this.hasSalesRights = (this.hasRoutedToRights || this.userService.hasRole('Salesman'));
	}

	public saveAsPdf() {
		this.pdfLoading = true;
		const tab: Window = window.open('/loading');
		this.prospectService.getPdf(this.prospect.id)
			.finally(() => {
				this.pdfLoading = false;
			})
			.subscribe(async (data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}

	public setNextContactDate(event: {nextContactDate: Moment}): void {
		this.prospectService.setNextContactDate(this.prospect.id, event.nextContactDate).subscribe(
			() => {
				this.prospect.details.nextContactDate = event.nextContactDate.format('YYYY-MM-DD');
				const details: FormGroup = this.prospectFormGroup.controls.details as FormGroup;
				details.controls.nextContactDate.setValue(event.nextContactDate.toDate());
				this.save.emit(this.prospect.id);
			},
		);
	}

	public setLastCalledDate(lastCalledDate: Moment): void {
		this.prospectService.setLastCalledDate(this.prospect.id, lastCalledDate).subscribe(
			() => {
				this.prospect.details.lastCalled = lastCalledDate.format('YYYY-MM-DD');
				const details: FormGroup = this.prospectFormGroup.controls.details as FormGroup;
				details.controls.lastCalled.setValue(lastCalledDate.format('YYYY-MM-DD'));
				this.save.emit(this.prospect.id);
			},
		);
	}

	public getUnsavedChangesTooltip(defaultMessage?: string): string {
		if (this.prospectFormGroup.dirty) {
			return 'Please save your changes';
		}

		return defaultMessage;
	}

	public goToSection(fragment: string): void {
		const element: HTMLElement = document.getElementById(fragment);
		if (element) {
			element.scrollIntoView({behavior: 'smooth'});
		}
	}

	protected updateUrlFragment(fragment: string): void {
		if (history.replaceState) {
			history.replaceState(null, null, window.location.pathname + '#' + fragment);
		} else {
			window.location.hash = fragment;
		}
	}

	protected getScrollSpySection(e: any): string {
		const items: any[] = this.scrollSpyIndex.getIndex('sections');

		if (!items || !items.length) {
			return;
		}

		// if scroll position is at the bottom of the page
		if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
			return items[items.length - 1].id;
		} else {
			for (let i = items.length - 1; i >= 0; i--) {
				if ((e.target.scrollTop - items[i].offsetTop >= -115)) {
					return items[i].id;
				}
			}
		}
	}

	protected loadProspect(): Promise<void> {
		return new Promise<void>((resolve: Function): void => {
			this.route.data.subscribe(response => {
				this._prospect = response.prospect;
				resolve();
			});
		});
	}

	protected getValues(): Promise<void> {
		this.loading = true;
		return new Promise<void>(resolve => {
			this.valuesService.getValues()
				.finally(() => {
					this.loading = false;
				})
				.subscribe(values => {
					this.values = values;
					resolve();
				});
		});
	}

	protected promptForExplanation(): Promise<DeleteExplanationDialogDataInterface> {
		return new Promise<DeleteExplanationDialogDataInterface>((resolve: any, reject: any): void => {
			this.deleteExplainDialog
				.open(DeleteExplanationDialogComponent, {
					minWidth: '1000px',
					data: <DeleteExplanationDialogDataInterface>{
						text: (this.prospect.details.firstName + ' ' + this.prospect.details.lastName),
						prospect: this.prospect,
						type: 'Prospect',
						user: this.user.firstName + ' ' + this.user.lastName,
						agents: this.allUsers,
					},
				})
				.afterClosed()
				.subscribe(result => {
					if (!result || result === 'Cancel') {
						reject();
					}
					resolve(result);
				});
		});
	}

	protected async saveChanges(): Promise<boolean> {
		if (this.prospectFormGroup.invalid) {
			this.touchAll(this.prospectFormGroup);
			const errors = this.getErroredFields(this.prospectFormGroup);
			this.toastrService.error('Please resolve errors in ' + errors.join(', ') + ' and try again.');
			return false;
		}

		this.loading = true;
		const updatedProspect = new ProspectModel(this.prospectFormGroup.getRawValue());
		updatedProspect.details.operator = this.user.userId;
		updatedProspect.details.lastModified = moment().format('YYYY-MM-DD');

		if (moment.isMoment(updatedProspect.details.lastCalled)) {
			this.prospect.details.lastCalled = updatedProspect.details.lastCalled.format('YYYY-MM-DD');
		}

		if (moment.isMoment(updatedProspect.details.nextContactDate)) {
			this.prospect.details.nextContactDate = updatedProspect.details.nextContactDate.format('YYYY-MM-DD');
		}

		return new Promise<boolean>(async (resolve: any) => {
			if (this.nameOrCoChanged) {
				await this.showDuplicateDialog();
			}

			if (!this.prospect.id) {
				this.prospectService.create(updatedProspect)
					.subscribe(
						result => {
							this.toastrService.success('Prospect #' + result.code + ' successfully created.');
							this.prospectFormGroup.markAsPristine();
							updatedProspect.id = result.id;
							updatedProspect.details.code = result.code;
							this.newProspect.emit(updatedProspect);
							this.loading = false;
							resolve(true);
						},
						(error: HttpErrorResponse) => {
							this.loading = false;
							this.toastrService.error(error.error.message);
							resolve(false);
						},
					);
			} else {
				updatedProspect.id = this.prospect.id;
				if (!this.nameOrCoChanged) {
					this.prospectService.save(updatedProspect)
						.finally(() => {
							this.loading = false;
						})
						.subscribe(
							() => {
								this.toastrService.success('Prospect #' + this.prospect.details.code + ' successfully saved.');
								if (this.prospectFormGroup.controls.details['controls'].routeToAgentId.dirty) {
									this.prospectRemarksComponent.ngOnInit();
								}
								this.prospectFormGroup.markAsPristine();
								this.save.emit(this.prospect.id);
								resolve(true);
							},
							(error: HttpErrorResponse) => {
								this.toastrService.error(error.error.message);
								resolve(false);
							},
						);
				}
			}
		});
	}

	protected deleteProspect(prospectId: number): Promise<void> {
		return new Promise<void>((resolve: any, reject: any): void => {
			this.prospectService.del(prospectId)
				.subscribe(
					() => {
						resolve();
					},
					(error: HttpErrorResponse) => {
						reject(error);
					},
				);
		});
	}

	protected createDeletedRemark(remark: ProspectRemarkModel): Promise<void> {
		return new Promise<void>((resolve: any, reject: any): void => {
			this.remarkService.createRemark(remark)
				.subscribe(
					() => {
						resolve();
					},
					(error: HttpErrorResponse) => {
						reject(error);
					},
				);
		});
	}

	protected touchAll(formGroup: FormGroup | FormArray, func = 'markAsTouched', opts = {onlySelf: false}): void {
		Object.keys(formGroup.controls).forEach(key => {
			if (formGroup.controls[key] instanceof FormGroup || formGroup.controls[key] instanceof FormArray) {
				this.touchAll(formGroup.controls[key], func, opts);
			} else {
				formGroup.controls[key][func](opts);
			}
		});
	}

	protected getErroredFields(formGroup: FormGroup | FormArray): any {
		const fields = [];
		Object.keys(formGroup.controls).forEach(key => {
			if (formGroup.controls[key].invalid) {
				fields.push(key.charAt(0).toUpperCase() + key.substring(1));
			}
		});
		return fields;
	}

	protected emailValidator(control: AbstractControl): ValidationErrors {
		// the default email validator also makes email a required field, but for us it is not required
		if (!control.value) {
			return null;
		}

		return Validators.email(control);
	}

	protected getBackToLink(previousUrl: string): {title: string, url: string} {
		const previousUrlRevised: string = previousUrl.indexOf('?') > 0 ? previousUrl.substring(0, previousUrl.indexOf('?')) : previousUrl;
		const segments = previousUrlRevised.split('/');
		const title = segments[1];
		const backToLink = {title: 'search', url: '/prospect/search'};

		if (!title) {
			return backToLink;
		}

		backToLink.title = segments[2] === 'search' ? 'Search' : title;
		backToLink.url = previousUrlRevised;

		return backToLink;
	}

	protected showDuplicateDialog(): Promise<boolean> {
		return new Promise<boolean>((resolve: any) => {
			this.nameOrCoChanged = true;
			this.needToCheckDuplicates = true;
			const details: FormGroup = <FormGroup>this.prospectFormGroup.controls.details;
			this.duplicateDialog
				.open(ProspectDuplicateDialogComponent, {
					width: '1000px',
					data: <ProspectDuplicateDialogDataInterface>{
						firstName: details.controls.firstName.value,
						lastName: details.controls.lastName.value,
						company: details.controls.company.value,
						agents: this.allUsers,
						prospectId: this.prospect.id,
					},
				})
				.afterClosed()
				.subscribe(result => {
					if (!result || result === 'Cancel') {
						this.loading = false;
						if (this.prospect.id) {
							this.toastrService.info('You must verify the prospect does not already exist before editing your current prospect.');
						} else {
							this.toastrService.info('You must verify the prospect does not already exist before creating a new prospect.');
						}
						resolve(true);
						return; // form remains disabled
					}

					if (result.status === 'Duplicate') {
						this.prospectSelected.emit(result.prospect);
						resolve(true);
						return;
					}

					resolve(false);

					this.needToCheckDuplicates = false;
					this.nameOrCoChanged = false;

					for (const field of ['firstName', 'lastName', 'company']) {
						details.controls[field].setValue(result[field]);
						details.controls[field].markAsDirty();
					}
				});
		});
	}

	@HostListener('window:beforeunload', ['$event'])
	public onBeforeUnload($event: BeforeUnloadEvent): void {
		if (this.prospectFormGroup.dirty && !this.needToCheckDuplicates) {
			$event.returnValue = true;
		}
	}

	public canDeactivate(): Promise<boolean> | boolean {
		if (!this.prospectFormGroup.dirty || this.needToCheckDuplicates) {
			return true;
		}

		return new Promise<boolean>((resolve: any) => {
			const dialog = this.unsavedChangesDialog.open(UnsavedChangesDialogComponent, {width: '400px'});

			return dialog.afterClosed().subscribe(async choice => {
				if (choice === 'Cancel') {
					resolve(false);
				} else if (choice === 'Save') {
					resolve(await this.saveChanges());
				} else {
					resolve(true);
				}
			});
		});
	}

}

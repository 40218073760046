import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
	selector: 'email-confirm-dialog',
	templateUrl: './email-send-confirm-dialog.component.pug',
	styleUrls: ['./email-send-confirm-dialog.component.scss']
})

export class EmailSendConfirmDialogComponent {

	constructor(public dialogRef: MatDialogRef<EmailSendConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

}

import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { LatLng, LatLngBounds, MapsAPILoader } from '@agm/core';
import { GoogleMap } from '@agm/core/services/google-maps-types';
import { NavigationService } from '../../../../../service/navigation.service';

export interface Marker {
	id: number;
	name: string;
	latitude: number;
	longitude: number;
}

@Component({
	selector: 'property-map',
	templateUrl: './property-map.component.pug',
	styleUrls: ['./property-map.component.scss'],
})
export class PropertyMapComponent implements OnInit {

	public static MAX_DEFAULT_ZOOM = 17;

	public styles = require('./styles.json');
	public icon;
	public markers: Marker[];

	@Output()
	public markerClick: EventEmitter<number> = new EventEmitter<number>();

	@Output()
	protected mapMove: EventEmitter<void> = new EventEmitter<void>();

	@Input() dashboard: boolean = false;

	protected _groupMarkers: boolean;
	@Input() set groupMarkers(groupMarkers: boolean) {
		this._groupMarkers = groupMarkers;
		this.ngOnInit();
	}

	get groupMarkers(): boolean {
		return this._groupMarkers;
	}

	protected map: GoogleMap;
	protected mapLock = true;
	protected ready = false;

	constructor(protected navigationService: NavigationService, protected mapsAPILoader: MapsAPILoader) {}

	public ngOnInit(): void {
		this.mapsAPILoader.load().then(async () => {
			this.icon = {
				url: this.groupMarkers ? 'assets/TD-icon.png' : 'assets/no-logo-icon.png',
				scaledSize: this.groupMarkers ? {height: 42, width: 28} : {height: 28, width: 18},
				labelOrigin: new window['google'].maps.Point(14, 50),
			};
		});
	}

	public isReady(): boolean {
		return this.ready;
	}

	public setCenter(center: LatLng): void {
		if (this.map) {
			this.mapLock = true;
			this.map.setCenter(center);
		}
	}

	public getCenter(): LatLng {
		return this.map ? this.map.getCenter() : null;
	}

	public setZoom(zoom: number): void {
		if (this.map) {
			this.mapLock = true;
			this.map.setZoom(zoom);
		}
	}

	public getZoom(): number {
		return this.map ? this.map.getZoom() : null;
	}

	public getBounds(): LatLngBounds {
		return this.map ? this.map.getBounds() : null;
	}

	public setMarkers(markers: Marker[], fitMarkers?: boolean): void {
		if (fitMarkers) {
			this.fitMarkers(markers);
		}
		setTimeout(() => this.markers = markers);
	}

	public getMarkers(): Marker[] {
		return this.markers;
	}

	public async loadAPIWrapper(map) {
		this.map = map;
		await this.setDefaultLocation();
		this.ready = true;
	}

	public onIdle() {
		if (!this.mapLock) {
			this.mapMove.emit();
		} else {
			this.mapLock = false;
		}
	}

	protected fitMarkers(markers: Marker[]): void {
		this.mapLock = true;
		const bounds = new window['google'].maps.LatLngBounds();
		markers.forEach((marker: Marker) => {
			if (marker.latitude === null || marker.longitude === null
				|| typeof marker.latitude !== 'number' || typeof marker.longitude !== 'number'
				|| marker.latitude < -90 || marker.latitude > 90 || marker.longitude < -180 || marker.longitude > 180) {
				marker.latitude = 42.428145;
				marker.longitude = -82.677612;
			}
			bounds.extend(new window['google'].maps.LatLng(marker.latitude, marker.longitude));
		});
		this.map.fitBounds(bounds);

		if (this.getZoom() > PropertyMapComponent.MAX_DEFAULT_ZOOM) {
			this.setZoom(PropertyMapComponent.MAX_DEFAULT_ZOOM);
		}
	}

	protected async setDefaultLocation(): Promise<void> {
		this.mapLock = true;
		const defaultLocation = new window['google'].maps.LatLng(42.4693317, -83.4144029);
		this.map.setZoom(14);
		this.map.panTo(defaultLocation);
	}

}

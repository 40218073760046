import { Injectable } from '@angular/core';
import { StorageService } from '../../../service/storage.service';

@Injectable()
export class TokenService {

	protected accessToken: string = null;
	protected refreshToken: string = null;

	constructor() {
		this.accessToken = StorageService.get('accessToken');
		this.refreshToken = StorageService.get('refreshToken');
	}

	public hasTokens() {
		return !!this.accessToken && !!this.refreshToken;
	}

	public getRefreshToken() {
		return this.refreshToken;
	}

	public getAccessToken() {
		return this.accessToken;
	}

	public setAccessToken(token: string) {
		StorageService.set('accessToken', token);
		this.accessToken = token;
	}

	public setRefreshToken(token: string) {
		StorageService.set('refreshToken', token);
		this.refreshToken = token;
	}

	public clearAccessToken(): void {
		this.accessToken = null;
		StorageService.remove('accessToken');
	}

	public clearRefreshToken(): void {
		this.refreshToken = null;
		StorageService.remove('refreshToken');
	}

	public clear(): void {
		this.clearAccessToken();
		this.clearRefreshToken();
	}

}

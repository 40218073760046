import {Component, OnInit, ViewChild} from '@angular/core';
import {CallSummaryByTeamModel, PaginationDto} from '@dvp/is2-shared';
import {ReportService} from '../../service/report.service';
import {FormControl} from '@angular/forms';
import {PaginationDatasource} from '../../../../datasource/pagination.datasource';
import {Observable} from 'rxjs/Observable';
import {RouterService} from '../../../../service/router.service';
import { TableComponent } from '../../../../component/table/table.component';

@Component({
	templateUrl: './call-summary.page.pug',
	styleUrls: ['./call-summary.page.scss'],
})
export class CallSummaryPage implements OnInit {

	@ViewChild(TableComponent, {static: true})
	private table: TableComponent;

	public range: FormControl = new FormControl('week');
	public dataSource: PaginationDatasource<CallSummaryByTeamModel>;

	public columns = [{
		title: 'Team',
		columnDef: 'teamName',
		sortable: true,
		fontWeight: 'bold'
	}, {
		title: 'Calls',
		columnDef: 'calls',
		sortable: true
	}, {
		title: 'Productive Calls',
		columnDef: 'productiveCalls',
		sortable: true
	}, {
		title: 'Total Duration',
		columnDef: 'totalDuration',
		sortable: true,
		pipe: 'duration'
	}, {
		title: 'Average Duration',
		columnDef: 'avgDuration',
		sortable: true,
		pipe: 'duration'
	}];

	constructor(private reportService: ReportService, private routerService: RouterService) {
	}

	private getData(): Observable<PaginationDto<CallSummaryByTeamModel>> {
		return this.reportService.getCallSummaryForAllTeams(this.range.value,
			this.table.paginator.pageIndex,
			this.table.paginator.pageSize,
			this.table.sort ? this.table.sort.active : undefined,
			this.table.sort ? this.table.sort.direction : undefined);
	}

	public onRowClicked(event): void {
		const { row } = event;
		this.routerService.navigate([`/dashboard/calls/team/${row.teamId}`]);
	}

	public ngOnInit(): void {
		this.dataSource = new PaginationDatasource(this.getData.bind(this), this.table.paginator, this.table.sort, [this.range.valueChanges]);
	}

	public print(): void {
		window.print();
	}
}

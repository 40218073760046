import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';

/**
 * Used to let the application know it is shutting down
 */
@Injectable()
export class ShutdownService {

	protected observer: Subscriber<any> = null;

	/**
	 * Get the shutdown observable.  Will notify when the application is being shutdown.
	 *
	 * @returns {Observable<any>}
	 */
	public onShutdown(): Observable<any> {
		// Return observable finally
		return new Observable<any>(observer => {
			this.observer = observer;
		});
	}

	/**
	 * Tell the application it is shutting down
	 */
	public shutdown() {
		if (this.observer) {
			this.observer.next();
		}
	}
}

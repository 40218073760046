import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { UserGuard } from '../../guard/user.guard';
import { NavigationService } from '../../service/navigation.service';
import { RouterService } from '../../service/router.service';
import { PreferencesPage } from './page/preferences/preferences.page';
import { UserComponent } from './user.component';
import { CanDeactivateGuard } from '../../guard/can-deactivate.guard';

@NgModule({
	declarations: [
		UserComponent,
		PreferencesPage,
	],
	imports: [
		SharedModule.forRoot(),
		RouterModule.forChild([
			{
				path: 'user',
				component: UserComponent,
				canActivate: [UserGuard],
				children: [
					{
						path: 'preferences',
						component: PreferencesPage,
						canDeactivate: [CanDeactivateGuard],
					},
				],
			},
		]),
	],
	providers: [
		UserGuard,
	],
})
export class UserModule {

	constructor(navigationService: NavigationService, routerService: RouterService) {
		navigationService.addUserNavigation({
			label: 'Preferences',
			action: routerService.navigate.bind(routerService, ['/user/preferences']),
			ordinal: 2,
			iconClass: 'fa-sliders-h',
		});
	}

}

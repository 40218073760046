import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
	selector: 'delete-dialog',
	templateUrl: './delete-dialog.component.pug',
})

export class DeleteDialogComponent {

	constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

}

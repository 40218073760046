import {Pipe, PipeTransform} from '@angular/core';
import {UserListModel} from '@dvp/is2-shared';

@Pipe({
	name: 'userFilter'
})

export class UserFilterPipe implements PipeTransform {
	transform(users: UserListModel[], searchText: string, showInactive: boolean): UserListModel[] {
		if (!users) {
			return [];
		}

		let result: UserListModel[] = users.slice(); // copy the users array by value

		if (!showInactive) {
			result = this.getActiveUsers(result);
		}

		if (!searchText) {
			return result;
		}

		searchText = searchText.toLowerCase();

		return result.filter((user: UserListModel) => {
			const {firstName, lastName, initials} = user;

			return (this.hasMatch(firstName, searchText) || this.hasMatch(lastName, searchText) || this.hasMatch(initials, searchText));
		});
	}

	private hasMatch(value: string, searchText) {
		return value ? value.toLowerCase().includes(searchText) : false;
	}

	private getActiveUsers(users: UserListModel[]) {
		return users.filter((user: UserListModel) => user.active);
	}
}

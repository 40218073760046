import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MailingModel, PaginationDto, PropertyModel, ProspectModel, ValueInterface } from '@dvp/is2-shared';
import { MatDialog, MatPaginator } from '@angular/material';
import { MailingDialogComponent } from '../mailing-dialog/mailing-dialog.component';
import { MailingService } from '../../service/mailing.service';
import { PaginationDatasource } from '../../datasource/pagination.datasource';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
	selector: 'is2-mailings',
	templateUrl: './mailings.component.pug',
	styleUrls: ['./mailings.component.scss'],
})
export class MailingsComponent implements OnInit {

	@ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;

	@Input() public allowEdit: boolean;
	@Input() public property: PropertyModel;
	@Input() protected mailingTypes: ValueInterface[];

	@Input('prospect')
	set prospect(prospect) {
		this._prospect = prospect;
		this.ngOnInit();
	}

	get prospect() {
		return this._prospect;
	}

	protected _prospect: ProspectModel;

	public loading: boolean;
	public isMobile: boolean = false;
	public dataSource: PaginationDatasource<MailingModel>;
	public showArchived: boolean = false;
	public archiveFormGroup = this.fb.group({
		showArchives: new FormControl(),
	});

	protected subject = new Subject<any>();
	protected mediaSubscription: Subscription;

	public displayedColumns: string[] = [
		'source',
		'propertiesAsString',
		'output',
		'dateSent',
		'count',
		'type',
		'comments',
		'truncatedParameters',
	];


	constructor(
		protected mailingService: MailingService,
		protected dialog: MatDialog,
		protected toastrService: ToastrService,
		protected fb: FormBuilder,
		breakpointObserver: BreakpointObserver,
	) {
		this.mediaSubscription = breakpointObserver
			.observe(Breakpoints.Handset)
			.subscribe(result => {
				this.isMobile = result.matches;
			});
	}

	public ngOnInit(): void {
		this.loading = true;
		this.dataSource = new PaginationDatasource<MailingModel>(this.getMailings.bind(this), this.paginator, null, [this.subject.asObservable()]);
		this.dataSource.subject.asObservable()
			.subscribe(data => this.dataSource.pager.data = data.map((i: MailingModel) => {
				const maxLines: number = 2;
				if (i.properties) {
					if (i.properties.length > maxLines) {
						i.properties = i.properties.slice(0, maxLines - 1);
						(<any>i.properties).push({name: '...'});
					}
					i.propertiesAsString = i.properties.map(prop => prop.name).join('\n');
				}

				if (i.parameters) {
					(i as any).truncatedParameters = i.parameters.trim();

					if ((i as any).truncatedParameters.length > 80) {
						(i as any).truncatedParameters = (i as any).truncatedParameters.toString().substring(0, 80).concat('...');
					}
				}

				return i;
			}));
	}

	public toggleArchives(): void {
		this.showArchived = this.archiveFormGroup.get('showArchives').value;
		this.ngOnInit();
	}

	public showDialog(event: MouseEvent, mailing?: MailingModel): void {
		event.preventDefault();
		event.stopPropagation();

		let dialogMailing: MailingModel;
		if (mailing) {
			dialogMailing = new MailingModel(Object.assign({}, mailing, {
				dateSent: moment.utc(mailing.dateSent).format('YYYY-MM-DD'),
			}));
		} else {
			dialogMailing = new MailingModel({
				count: null,
				dateSent: moment().format('YYYY-MM-DD'),
				source: 'REIS',
				output: 'Email',
				type: 'Marketing',
			});

			if (this.property) {
				dialogMailing.properties = [<any>{id: this.property.id}];
				dialogMailing.agent1 = this.property.details.agentIds[0];
				dialogMailing.agent2 = this.property.details.agentIds[1];
			} else if (this.prospect) {
				dialogMailing.prospects = [<any>{id: this.prospect.id}];
			}
		}

		const dialog = this.dialog.open(MailingDialogComponent, {
			width: '500px',
			data: {
				mailing: dialogMailing,
				action: mailing ? 'Edit' : 'Add',
				canEdit: this.allowEdit,
				mailingTypes: this.mailingTypes,
			},
		});
		dialog.afterClosed().subscribe((result: string | MailingModel) => {
			let observable: Observable<any>;
			if (result === 'Delete') {
				observable = this.mailingService.delete(mailing.id);
			}

			if (result === 'Toggle') {
				mailing.isArchived = !mailing.isArchived;
				observable = this.mailingService.updateArchiveStatus(mailing);
			}

			if (result instanceof MailingModel) {
				result.prospects = <any>result.prospects.map(prospect => ({id: prospect.id}));
				result.properties = <any>result.properties.map(property => ({id: property.id}));

				if (mailing) {
					observable = this.mailingService.update(result);
				} else {
					observable = this.mailingService.create(result);
				}
			}

			if (observable) {
				this.loading = true;
				observable
					.finally(() => {
						this.loading = false;
					})
					.subscribe(
						() => {
							this.subject.next();
							this.toastrService.success('Mailing record successfully ' + (result === 'Delete' ? 'deleted' : 'saved') + '.');
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						},
					);
			}
		});
	}

	protected getMailings(): Observable<PaginationDto<MailingModel>> {
		let method: string;
		let id: number;

		if (this.property) {
			method = 'findByPropertyId';
			id = this.property.id;
		} else if (this.prospect) {
			method = 'findByProspectId';
			id = this.prospect.id;
		} else {
			throw new Error('Need either a property or prospect record');
		}

		return this.mailingService[method](id, this.paginator.pageIndex, this.paginator.pageSize, this.showArchived)
			.finally(() => this.loading = false);
	}

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ProspectListModel } from '@dvp/is2-shared/dist/model/prospect.list-model';
import { environment } from '../../environments/environment';
import { CustomMessageInterface } from '../interface/custom-message.interface';
import { PropertyModel, ProspectDetailsModel } from '@dvp/is2-shared';
import { CostarEmailDialogDataInterface } from '../module/property/component/property-edit/costar-email-dialog/costar-email-dialog.component';

@Injectable()
export class EmailService {

	constructor(protected http: HttpClient) {}

	public shareListing(id: number, prospects: ProspectListModel[], fromUserId: number, customImage: string, customMessage: string, description: string): Observable<any> {
		return this.http.post(`${environment.apiUrl}/property/${id}/share`, {
			prospects,
			fromUserId,
			customImage,
			customMessage,
			description,
		});
	}

	public previewListingEmail(id: number, customImage: string, customMessage: string, description: string): Observable<{html: string}> {
		return this.http.post<{html: string}>(`${environment.apiUrl}/property/${id}/emailpreview`, {
			customImage,
			customMessage,
			description,
		});
	}

	public shareMultipleListings({
		prospectDetails,
		fromUserId,
		mainMessage,
		customMessages,
		messageSignature,
	}: {
		prospectDetails: ProspectDetailsModel,
		fromUserId: number,
		mainMessage: string,
		customMessages: CustomMessageInterface[],
		messageSignature: string
	}): Observable<any> {
		return this.http.post(`${environment.apiUrl}/property/multilisting-share`, {
			prospectDetails,
			fromUserId,
			mainMessage,
			customMessages,
			messageSignature,
		});
	}

	public getMultiListingEmailPreview({
		mainMessage,
		customMessages,
		messageSignature,
	}: {
		mainMessage: string,
		customMessages: CustomMessageInterface[],
		messageSignature: string
	}): Observable<{html: string}> {
		return this.http.post<{html: string}>(`${environment.apiUrl}/property/multilisting-emailpreview`, {
			mainMessage,
			customMessages,
			messageSignature,
		});
	}

	public sendEmailToCostar({
		propertyName,
		url,
		userEmail,
		userText,
	}: CostarEmailDialogDataInterface): Observable<any> {
		return this.http.post(`${environment.apiUrl}/property/email-costar`, {
			propertyName,
			url,
			userEmail,
			userText,
		});
	}

	public selectAllBulkProspectEmails(prospectIds: number[], property: PropertyModel): Observable<{emails: ProspectListModel[], noEmails: ProspectListModel[]}> {
		return this.http.post<{
			emails: ProspectListModel[],
			noEmails: ProspectListModel[]
		}>(`${environment.apiUrl}/prospect/emails/select-all/${prospectIds.join(',')}`, {property});
	}

	public bulkSelectProspectEmails(prospectIds: number[], property: PropertyModel): Observable<{emails: ProspectListModel[], noEmails: ProspectListModel[]}> {
		return this.http.post<{
			emails: ProspectListModel[],
			noEmails: ProspectListModel[]
		}>(`${environment.apiUrl}/prospect/emails/bulk-select/${prospectIds.join(',')}`, {property});
	}
}

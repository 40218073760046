import { Component } from '@angular/core';
import { TableFunctionalityComponent } from '../table-functionality/table-functionality.component';
import { MediaObserver } from '@angular/flex-layout';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../service/user.service';
import { DialService } from '../../service/dial.service';

@Component({
	selector: 'is2-virtual-scroll-table',
	templateUrl: 'virtual-scroll-table.component.pug',
	styleUrls: ['./virtual-scroll-table.component.scss']
})

export class VirtualScrollTableComponent extends TableFunctionalityComponent {

	constructor(
		protected mediaObserver: MediaObserver,
		protected toastrService: ToastrService,
		protected userService: UserService,
		protected dialService: DialService,
	) {
		super(mediaObserver, toastrService, userService, dialService);
	}

}

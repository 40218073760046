import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
	selector: 'image-carousel',
	templateUrl: './image-carousel.component.pug',
	styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent implements AfterViewInit {

	public carouselConfig: NguCarouselConfig = {
		grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
		slide: 3,
		speed: 250,
		point: {
			visible: true
		},
		load: 3,
		velocity: 0,
		touch: true,
		loop: true,
		interval: {timing: 4000, initialDelay: 1000},
		easing: 'cubic-bezier(0, 0, 0.2, 1)'
	};

	protected _carouselTileItems = [];

	@Input('carouselTileItems')
	set carouselTileItems(carouselTileItems) {
		this._carouselTileItems = carouselTileItems;
	}
	get carouselTileItems() {
		return this._carouselTileItems;
	}

	@Output() public onImageEvent: EventEmitter<any> = new EventEmitter();

	constructor(protected cdr: ChangeDetectorRef) {}

	public ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	public imageEvent(image) {
		this.onImageEvent.emit(image);
	}

}

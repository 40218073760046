import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/defaultIfEmpty';
import 'rxjs/add/operator/do';

/**
 * Used to tell the application about any actions that need to be performed before it is shown to the user
 */
@Injectable()
export class StartupService {

	protected _starting = true;
	protected observers: Observable<any>[] = [];

	public isStarting(): boolean {
		return this._starting;
	}

	/**
	 * The returned observable will trigger once all the startup actions have been completed.
	 *
	 * @returns {Observable<any>}
	 */
	public onStartup(): Observable<any> {
		// Return observable finally
		return Observable.forkJoin(
			this.observers,
		).defaultIfEmpty(null)
			.do(() => this._starting = false);

	}

	/**
	 * Add a startup action.  The application will not be shown to the user until this passed observable is completed.
	 *
	 * @param {Observable<string>} observer
	 */
	public add(observer: Observable<string>) {
		this.observers.push(observer);
	}
}

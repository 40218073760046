import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'image-selector',
	templateUrl: './image-selector.component.pug',
	styleUrls: ['./image-selector.component.scss'],
})
export class ImageSelectorComponent {

	public selectedImage;
	public loading: boolean = false;

	protected _carouselTileItems = [];
	@Input('carouselTileItems')
	set carouselTileItems(carouselTileItems) {
		if (!this.selectedImage) {
			this.selectedImage = carouselTileItems.find(i => i.featured);
		}
		this._carouselTileItems = carouselTileItems;
	}
	get carouselTileItems() {
		return this._carouselTileItems;
	}

	@Output() public onImageSelect: EventEmitter<any> = new EventEmitter();

	constructor() {}

	public setImage(image) {
		this.selectedImage = image;
		this.onImageSelect.emit(image);
	}

	public recieveNewPhoto(photo) {
		this.setImage(photo);
	}

	public isLoading(loading) {
		this.loading = loading;
	}

	public removeImage() {
		this.selectedImage = null;
		this.onImageSelect.emit();
	}
}

import { Component, OnInit } from '@angular/core';
import { TeamModel } from '@dvp/is2-shared';
import {Router} from '@angular/router';
import { TeamsService } from '../../service/teams.service';


@Component({
	templateUrl: './teams.page.pug',
	styleUrls: ['./teams.page.scss'],
})

export class TeamsPage implements OnInit {

	public loading: boolean = false;
	public teams: TeamModel[];

	constructor(
		protected router: Router,
		protected teamService: TeamsService,
		) {

	}

	public ngOnInit(): void {
		this.loading = true;
		this.teamService.getAllTeams().subscribe((results: TeamModel[]) => {
			this.teams = results;
			this.loading = false;
		});
	}

	public navigate(id: number): void {
		this.router.navigate([`/admin/teams/detail/${id}`]);
	}

}

import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../../service/user.service';
import { Observable } from 'rxjs/Observable';
import { LoginResponseModel } from '../model/login-response.model';
import { TokenService } from './token.service';
import { ShutdownService } from '../../../service/shutdown.service';
import { TokenResponseModel } from '../model/token-response.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthService {

	protected _tokenRefreshTimeout = null;

	constructor(protected http: HttpClient,
	            protected userService: UserService,
							protected tokenService: TokenService,
							protected shutdownService: ShutdownService) {
	}

	public hasAuth(): boolean {
		return this.tokenService.hasTokens();
	}

	public login(username: string, password: string): Observable<boolean> {
		return this.http.post(environment.apiUrl + '/account/login', {
				username: username,
				password: password
			})
			.map((response: LoginResponseModel) => {
				this.tokenService.setAccessToken(response.accessToken);
				this.tokenService.setRefreshToken(response.refreshToken);
				this.userService.setAtDesk(response.ip);
				this.nextTokenRefresh();
				const user = response.user;

				return this.userService.setUser(user);
			});
	}

	public logout(redirectToCurrentUrl: boolean = false): void {
		if (this._tokenRefreshTimeout) {
			clearTimeout(this._tokenRefreshTimeout);
		}

		// Clean out the active user
		this.userService.clear();
		// Clean out the active tokens
		this.tokenService.clear();

		this.shutdownService.shutdown();

		// Reload to make sure we get to a completely clean state, include the redirect url if necessary
		let fullRedirectUrl = '';
		if (redirectToCurrentUrl) {
			// see when the user gets the no auth dialog login window, but then refreshes, the redirect state is lost
			fullRedirectUrl = '?redirect=' + encodeURIComponent(window.location.pathname);
		}

		window.location.href = environment.routing.loginUrl + fullRedirectUrl;
	}

	/**
	 * Refresh the token now
	 *
	 * @returns {Observable<void>}
	 */
	public doTokenRefresh(): Observable<TokenResponseModel> {
		return this.http.get(environment.apiUrl + '/token/refresh/' + encodeURIComponent(this.tokenService.getRefreshToken()))
			.map((response: TokenResponseModel) => {
				this.tokenService.setAccessToken(response.accessToken);
				this.userService.setAtDesk(response.ip);
				this.nextTokenRefresh();
				return response;
			});
	}

	/**
	 * Refresh the token on an interval
	 */
	public nextTokenRefresh() {
		this._tokenRefreshTimeout = setTimeout(() => {
			this.doTokenRefresh().subscribe( // Capture success / failure status as we don't need to do anything with them
				() => {
				},
				() => {
				}
			);
		}, environment.token.refresh);
	}

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../../../property/service/search.service';
import { PaginationDto, PropertyListModel, PropertySearchDto, UserModel } from '@dvp/is2-shared';
import { UserService } from '../../../../service/user.service';
import { PropertyMapComponent } from '../../../property/component/search-map/property-map/property-map.component';
import { Router } from '@angular/router';

@Component({
	selector: './is2-property-summary-map',
	templateUrl: './property-summary-map.component.pug',
	styleUrls: ['./property-summary-map.component.scss'],
})

export class PropertySummaryMapComponent implements OnInit {

	@ViewChild('map', {static: false}) protected map: PropertyMapComponent;

	protected searchDto: PropertySearchDto = new PropertySearchDto();
	protected populateMapTimeout: NodeJS.Timer;
	protected user: UserModel;

	public dashboard: boolean = true;

	constructor(
		protected searchService: SearchService,
		protected userServie: UserService,
		protected router: Router,
	) {
		this.user = this.userServie.getUser();
	}

	public ngOnInit(): void {
		this.searchDto.agent1Id = [this.user.userId];
		this.searchService.search(this.searchDto, 0, 500).subscribe((result: PaginationDto<PropertyListModel>) => {
			this.populateMap(result);
		});
	}

	protected populateMap(paginationDto: PaginationDto<PropertyListModel>, fitMarkers: boolean = true) {
		if (this.map === undefined) {
			return;
		}

		if (paginationDto.data && paginationDto.data.length > 0) {
			const markers = paginationDto.data.map(({id, name, latitude, longitude, mapAddress, size, price}) => {

				if (size) {
					(size as any) = size.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
				}

				if (price) {
					(price as any) = price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
				}

				return {id, name, latitude, longitude, mapAddress, size, price};
			});
			this.map.setMarkers(markers, fitMarkers);
		} else if (!this.map.isReady()) {
			this.populateMapTimeout = setTimeout(() => {
				this.populateMap(paginationDto, fitMarkers);
			}, 100);
		}

	}

}

import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {

	constructor(protected sanitizer: DomSanitizer) {}

	public transform(value) {
		return this.sanitizer.bypassSecurityTrustHtml(value);
	}

}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProspectListModel, UserListModel } from '@dvp/is2-shared';
import { MatTableDataSource, MatSort, MatCheckboxChange } from '@angular/material';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { IconService } from '../../service/icon.service';
import { ProspectSelectionEventInterface } from '../../interface/prospect-selection-event.interface';
import { TableComponent } from '../table/table.component';

@Component({
	selector: 'prospect-list',
	templateUrl: './prospect-list.component.pug',
	styleUrls: ['./prospect-list.component.scss'],
})
export class ProspectListComponent implements OnInit {

	protected _prospects: ProspectListModel[];
	@Input() set prospects(prospects: ProspectListModel[]) {
		this._prospects = prospects;
		if (prospects && this.agents) {
			this.setDataSource();
		} else {
			this.dataSource = undefined;
		}
	}

	get prospects(): ProspectListModel[] {
		return this._prospects;
	}

	protected _agents: UserListModel[];
	@Input() set agents(agents: UserListModel[]) {
		this._agents = agents;
		if (this.prospects && !this.dataSource) {
			this.setDataSource();
		}
	}

	get agents(): UserListModel[] {
		return this._agents;
	}

	@Input() public bulkEditMode: boolean;
	@Input() public selectAllExclusions: boolean;
	@Input() public phoneLinks: {phone: string, label: string}[];
	@Input() public rowHeight: number;
	@Input() public maxHeight: number;
	@Input() protected multiple: boolean;
	@Input() protected match: boolean;
	@Input() protected previouslySelectedId: number;
	@Input() protected dataTransformation: (prospect: ProspectListModel) => void;

	@Output() protected prospectSelect: EventEmitter<ProspectSelectionEventInterface> = new EventEmitter();
	@Output() protected sortChange: EventEmitter<MatSort> = new EventEmitter();
	@Output() public masterToggleEvent: EventEmitter<void> = new EventEmitter();
	@Output() public toggleRowEvent: EventEmitter<{event: MatCheckboxChange, item: ProspectListModel}> = new EventEmitter<{event: MatCheckboxChange, item: ProspectListModel}>();

	@ViewChild(TableComponent, {static: false}) public table: TableComponent;

	public dataSource: MatTableDataSource<ProspectListModel>;
	public columns: TableColumnInterface[] = [
		{columnDef: 'code', title: 'Number', sortable: true},
		{columnDef: 'name', title: 'Name', sortable: true},
		{columnDef: 'company', title: 'Company', sortable: true},
		{columnDef: 'routedTo', title: 'Routed To', sortable: true},
		{columnDef: 'statusText', title: 'Status', allowHtml: true},
	];

	protected selectedProspects: ProspectListModel[] = [];

	constructor(protected iconService: IconService) {}

	public async ngOnInit(): Promise<void> {
		if (this.multiple) {
			this.columns.unshift({columnDef: 'selected', title: '', allowHtml: true});
		}
	}

	public isSelected(prospectId: number): boolean {
		return !!this.selectedProspects.find(prospect => prospect.id === prospectId);
	}

	public isPreviouslySelected(prospect: ProspectListModel): boolean {
		if (this.match) {
			return false;
		}
		return prospect.id === this.previouslySelectedId;
	}

	public toggleProspect(prospect: ProspectListModel): void {
		const index: number = this.selectedProspects.findIndex(p => p.id === prospect.id);
		const selected: boolean = (index === -1);

		if (!selected) {
			this.selectedProspects.splice(index, 1);
		} else {
			this.selectedProspects.push(prospect);
		}

		if (this.multiple) {
			(prospect as any).selected = selected ? this.iconService.getCheckmarkIcon() : '';
		}

		this.prospectSelect.emit({prospect, selected});
	}

	public getColumns(): TableColumnInterface[] {
		return this.columns;
	}

	public getSortData(sort: MatSort): void {
		this.dataSource.sort = sort;
		this.sortChange.emit(sort);
	}

	public masterToggleEventEmitted(): void {
		this.masterToggleEvent.emit();
	}

	public toggleRow(event: {event: MatCheckboxChange, item: ProspectListModel}): void {
		this.toggleRowEvent.emit(event);
	}

	protected setDataSource(): void {
		const checkmarkHtml: string = this.iconService.getCheckmarkIcon();
		this.prospects.forEach(prospect => {
			const routedTo: UserListModel = this.agents.find(agent => agent.userId === prospect.routedToId);
			(prospect as any).selected = this.isSelected(prospect.id) ? checkmarkHtml : '';
			(prospect as any).name = [prospect.firstName, prospect.lastName].join(' ');
			(prospect as any).statusText = this.iconService.getProspectStatusIcon(prospect.status);

			if (routedTo) {
				if (!routedTo.active) {
					(prospect as any).routedTo = `${routedTo.firstName} ${routedTo.lastName} (INACTIVE)`;
				} else {
					(prospect as any).routedTo = `${routedTo.firstName} ${routedTo.lastName}`;
				}
			}

			if (this.dataTransformation) {
				this.dataTransformation(prospect);
			}
		});

		this.dataSource = new MatTableDataSource<ProspectListModel>(this.prospects);
	}

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyModel, ValueInterface, ValuesModel } from '@dvp/is2-shared';
import { PropertyService } from '../../../service/property.service';
import { ValuesService } from '../../../../../service/values.service';
import { DialService } from '../../../../../service/dial.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { HighlightsComponent } from '../../property-edit/highlights/highlights.component';
import { Router } from '@angular/router';
import { UserService } from '../../../../../service/user.service';

@Component({
	selector: 'property-preview',
	templateUrl: './property-preview.component.pug',
	styleUrls: ['./property-preview.component.scss'],
})

export class PropertyPreviewComponent implements OnInit {

	public loading = false;

	@Input()
	public property: PropertyModel;

	public values: ValuesModel;

	@Output()
	public closePreview: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private propertyService: PropertyService,
		private valuesService: ValuesService,
		private dialService: DialService,
		private toastrService: ToastrService,
		private router: Router,
		public userService: UserService,
	) {}

	public ngOnInit(): void {
		this.valuesService.getValues().subscribe((values: ValuesModel) => {
			this.values = values;
		});
		this.setSellerPhone();
	}

	public getSizeUnit(id: number): string {
		if (!id) {
			return;
		}
		if (this.values) {
			return this.values.sizeUnit.find((sizeUnit: ValueInterface) => sizeUnit.id === id).name;
		}
	}

	public getAgent(id: number): string {
		if (!id) {
			return;
		}
		if (this.values) {
			const found = this.values.activeAgent.find((agent: ValueInterface) => agent.id === id);
			return found ? found.name : '';
		}
	}

	public fullBrochureURL(): string {
		return HighlightsComponent.BROCHURE_URL + '/' + this.property.marketing.buildoutId;
	}

	public fullListingURL(): string {
		return HighlightsComponent.LISTING_URL + '/' + this.property.marketing.buildoutId;
	}

	public displayBrochureURL(): string {
		return HighlightsComponent.BROCHURE_URL.substring(11) + '/' + this.property.marketing.buildoutId;
	}

	public displayListingURL(): string {
		return HighlightsComponent.LISTING_URL.substring(11) + '/' + this.property.marketing.buildoutId;
	}

	public clickToDial(phoneNumber: string): void {
		if (this.property.seller.sellerPhone) {
			this.dialService.dial(phoneNumber)
				.subscribe(id => {
						this.toastrService.success('Dial completed.');
					},
					(error: HttpErrorResponse) => {
						this.toastrService.error(error.error.message);
					},
				);
		}
	}

	public getPdf(): void {
		this.loading = true;
		const tab: Window = window.open('/loading');
		this.propertyService.getSummaryPdf(this.property.id)
			.finally(() => {
				this.loading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}

	public setSellerPhone(): void {
		if (this.property.seller.owner.details.workPhone) {
			this.property.seller.sellerPhone = this.property.seller.owner.details.workPhone;
		} else if (this.property.seller.owner.details.cellPhone) {
			this.property.seller.sellerPhone = this.property.seller.owner.details.cellPhone;
		} else if (this.property.seller.owner.details.homePhone) {
			this.property.seller.sellerPhone = this.property.seller.owner.details.homePhone;
		}
	}

	public formatPhoneNumber(): string {
		if (this.property.seller.sellerPhone) {
			const phone = this.property.seller.sellerPhone;
			return '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + '-' + phone.substr(6, 4);
		} else {
			return 'N/A';
		}
	}

	public clickToEmail(): void {
		if (this.property.seller.owner.details.email) {
			const email = this.property.seller.owner.details.email;
			const win = window.open('mailto:' + email);
			if (win && win.open && !win.closed) {
				win.close();
			}
		}
	}

	public openPhoto(photo) {
		window.open(photo.original, '_blank');
	}

	public shareListing(id: number) {
		this.router.navigate(['/property/share/', id]);
	}

	public copyToClipboard(url) {
		const copyUrl = document.createElement('textarea');
		copyUrl.value = url;
		document.body.appendChild(copyUrl);
		copyUrl.focus();
		copyUrl.select();

		try {
			document.execCommand('copy');
			this.toastrService.success(`URL copied to clipboard`);
		} catch (e) {
			this.toastrService.error(`URL failed to copy to clipboard`);
		}
		document.body.removeChild(copyUrl);
	}
}

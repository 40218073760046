import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {CallSummaryModel} from '@dvp/is2-shared';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class CallReportService {

	constructor(protected http: HttpClient) {

	}

	public callSummary(): Observable<CallSummaryModel> {
		return this.http.get<CallSummaryModel>(`${environment.apiUrl}/report/call-summary`);
	}

}

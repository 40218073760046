import {Component, Input} from '@angular/core';

@Component({
	selector: 'dvp-expansion-container',
	templateUrl: './expansion-container.component.pug',
	styleUrls: ['./expansion-container.component.scss'],
})
export class ExpansionContainerComponent {

	public containerTitle = '';

	constructor() {}

	@Input()
	set title(title) {
		this.containerTitle = title;
	}

	@Input()
	public icon: string;

	@Input()
	public sectionTitle: string;

	@Input()
	public collapsible: boolean;

}

import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';
import { ProspectListModel } from '@dvp/is2-shared';
import fz from 'fuzzaldrin-plus';
import { Subject } from 'rxjs';


@Component({
	templateUrl: './prospect-selection-dialog.component.pug',
	styleUrls: ['./prospect-selection-dialog.component.scss'],
})
export class ProspectSelectionDialogComponent implements OnInit, OnDestroy {

	public title: string = 'Prospect Selection';
	public selectedFontIcon: string = 'fa-check';
	public selectedFontIconColor: string = 'black';
	public displayedColumns = ['selected', 'name', 'company', 'status'];
	public dataSource: MatTableDataSource<ProspectListModel>;
	public prospects: ProspectListModel[];
	public selectedProspects: ProspectListModel[] = [];
	protected subject: Subject<string> = new Subject();
	protected subscription;

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

	constructor(public dialogRef: MatDialogRef<ProspectSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
		if (data) {
			this.prospects = data.prospects ? data.prospects : [];
			this.title = data.title ? data.title : this.title;
			this.selectedFontIcon = data.selectedFontIcon ? data.selectedFontIcon : this.selectedFontIcon;
			this.selectedFontIconColor = data.selectedFontIconColor ? data.selectedFontIconColor : this.selectedFontIconColor;
		}

		this.dataSource = new MatTableDataSource(this.prospects);
	}

	public ngOnInit() {
		this.dataSource.paginator = this.paginator;
		this.subscription = this.subject.debounceTime(500).subscribe(query => {
			this.keywordSearch(query);
		});
	}

	public ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	public selectProspect(prospect) {
		if (this.prospects) {
			if (this.isSelected(prospect.id)) {
				this.selectedProspects = this.selectedProspects.filter(x => x.id !== prospect.id);
			} else {
				this.selectedProspects.push(prospect);
			}
		} else {
			this.dialogRef.close(prospect);
		}
	}

	public isSelected(id: number): boolean {
		return !!this.selectedProspects.find(x => id === x.id);
	}

	public onKeyUp(query: string) {
		this.subject.next(query);
	}

	public keywordSearch(query) {
		this.paginator.firstPage();

		if (!query) {
			return this.dataSource.data = this.prospects;
		}

		const preparedQuery = fz.prepareQuery(query);

		const scores = {};

		this.dataSource.data = this.prospects.map(prospect => {
			const scorableFields = [
				prospect.code,
				prospect.firstName,
				prospect.lastName,
				prospect.company,
				prospect.cellPhone,
				prospect.homePhone,
				prospect.faxNumber,
				prospect.workPhone
			].map(toScore => fz.score(toScore, query, { preparedQuery }));

			scores[prospect.id] = Math.max(...scorableFields);

			return prospect;
		})
			.filter(prospect => scores[prospect.id] > 1)
			.sort((a, b) => scores[b.id] - scores[a.id]);
	}

	public close(): void {
		this.dialogRef.close(this.selectedProspects);
	}

}

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'property-attributes',
	templateUrl: './attributes.component.pug',
	styleUrls: ['./attributes.component.scss']
})

export class AttributesComponent {

	public attributeValues = [
		{
			name: 'User',
			value: 'isUser'
		},
		{
			name: 'Developer',
			value: 'isDeveloper'
		},
		{
			name: 'Divisible',
			value: 'isDivisible'
		},
		{
			name: 'Exp Access',
			value: 'isExpAccess'
		},
		{
			name: 'Sewer Access',
			value: 'isSewerAccess'
		},
		{
			name: 'Bank',
			value: 'isBank'
		},
		{
			name: 'Restaurant',
			value: 'isRestaurant'
		},
		{
			name: 'Hotel',
			value: 'isHotel'
		},
		{
			name: 'Big Box Site',
			value: 'isBigBoxSite'
		},
		{
			name: 'Gas Station',
			value: 'isGasStation'
		},
		{
			name: 'Car Wash',
			value: 'isCarWash'
		},
		{
			name: 'Multi Tenant',
			value: 'isMultiTenant'
		},
		{
			name: 'Senior Living',
			value: 'isSeniorLiving'
		},
		{
			name: 'Corner',
			value: 'isCorner'
		},
		{
			name: 'Drive Thru',
			value: 'isDriveThru'
		},
		{
			name: 'Traffic Less Than 20k',
			value: 'isTrafficLessThan20k'
		},
		{
			name: 'Traffic 20k To 50k',
			value: 'isTraffic20kTo50k'
		},
		{
			name: 'Traffic Greater Than 50k',
			value: 'isTrafficGreaterThan50k'
		},
		{
			name: 'Retail Outlot',
			value: 'isRetailOutlot'
		},
		{
			name: 'Liquor License',
			value: 'isLiquorLicense'
		},
		{
			name: 'Downtown',
			value: 'isDowntown'
		},
		{
			name: 'Dedicated Parking',
			value: 'isDedicatedParking'
		},
		{
			name: 'Renovation Needed',
			value: 'isRenovationNeeded'
		},
		{
			name: 'High Visibility',
			value: 'isHighVisibility'
		},
	];

	@Input() public attributesFormGroup: FormGroup;
	@Input() public hasAdminRights = false;
}

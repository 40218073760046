import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatSelectChange, MatSidenav, MatTooltip } from '@angular/material';
import { FullScreenModalComponent } from '../../../../component/full-screen-modal/full-screen-modal.component';
import { BoxContentTransclusionDirective } from '../../directive/box-content-transclusion.directive';
import { ValueInterface, TaskOccurrenceModel, TaskOccurrenceAssigneeModel, TaskOccurrenceDto } from '@dvp/is2-shared';
import { TaskService } from '../../../../service/task.service';
import { TaskDialogComponent } from '../../../../component/task-dialog/task-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'dashboard-box',
	templateUrl: './box.component.pug',
	styleUrls: ['./box.component.scss'],
})
export class BoxComponent {

	@Input() public title: string;
	@Input() public expandable = true;
	@Input() public hasMenu = true;
	@Input() public loading = false;
	@Input() public dropdown: ValueInterface[];
	@Input() public task: boolean = false;
	@Input() public dropdownMultiple = false;
	@Input() public print: boolean = false;

	@Output() public dropdownChange: EventEmitter<number | string> = new EventEmitter();
	@Output() public dropdownChangeEvent: EventEmitter<MatSelectChange> = new EventEmitter();
	@Output() public refreshData: EventEmitter<void> = new EventEmitter<void>();
	@Output() public dropdownClosed: EventEmitter<void> = new EventEmitter<void>();
	@Output() public printEvent: EventEmitter<void> = new EventEmitter<void>();

	@ContentChild(BoxContentTransclusionDirective, {read: TemplateRef, static: true})
	public content;

	@ViewChild('sidenav', {static: false})
	protected sidenav: MatSidenav;
	@ViewChild('fullscreen', {static: false})
	protected fullscreen: FullScreenModalComponent;
	@ViewChild(MatTooltip, {static: false})
	protected tooltip: MatTooltip;

	public fullscreenShown: boolean = false;

	constructor(
		protected taskDialog: MatDialog,
		protected taskService: TaskService,
		protected toastrService: ToastrService,
	) {}

	public expand(): void {
		this.fullscreenShown = true;
		this.fullscreen.show();
	}

	public toggleMenu(): void {
		this.sidenav.toggle();
	}

	public closeMenu(): void {
		this.sidenav.close();
	}

	public dropdownChanged(event: MatSelectChange): void {
		if (this.dropdownMultiple) {
			this.dropdownChangeEvent.emit(event);
		} else {
			this.dropdownChange.emit(event.value);
		}

	}

	public selectOpenChange(open: boolean) {
		if (!open) {
			this.dropdownClosed.emit();
		}
	}

	public getSelectedDropdownValue(): string | number | boolean | null {
		const selectedOption = this.dropdown.find(option => !!option.selected);
		if (!selectedOption) {
			return null;
		}
		return selectedOption.id;
	}

	public addTask(): void {
		this.taskDialog.open(TaskDialogComponent, {
			width: '1000px',
			data: {
				task: new TaskOccurrenceDto({
					occurrence: new TaskOccurrenceModel({
						assignee: new TaskOccurrenceAssigneeModel({
							userId: null,
						})
					})
				}),
				action: 'User Add',
			},
		}).afterClosed().subscribe((result: {task: TaskOccurrenceDto, action: string}) => {
			if (!result) {
				return;
			}
			if (result.action === 'User Add') {
				this.taskService.createTask(result.task).subscribe((data) => {
					if (data) {
						this.toastrService.success(`Task successfully added to your task list.`);
						this.refreshData.emit();
					}
				}, (error) => {
					this.toastrService.error(error);
				});
			}
		});
	}

	public printData(): void {
		this.tooltip.hide();
		setTimeout(() => this.printEvent.emit());
	}

}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';


@Component({
	selector: 'prospect-misc',
	templateUrl: './prospect-misc.component.pug',
})
export class ProspectMiscComponent {

	@Input() public miscFormGroup: FormGroup;
	@Input() public isDeleted = false;
	@Input() public needToCheckDuplicates: boolean = false;

	@Output() public valueChanged: EventEmitter<any> = new EventEmitter<any>();

}

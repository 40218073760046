import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatPaginator } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
	PaginationDto,
	PropertyListModel,
	PropertyModel,
	ProspectListModel,
	ProspectModel,
	ShownModel,
	UserModel,
	ValueInterface,
	PropertyMatchModel, ProspectMatchModel,
} from '@dvp/is2-shared';
import { ShownService } from '../../service/shown.service';
import { PaginationDatasource } from '../../datasource/pagination.datasource';
import { ShownDialogComponent } from '../shown-dialog/shown-dialog.component';
import { ProspectSearchDialogComponent } from '../prospect-search-dialog/prospect-search-dialog.component';
import { PropertySearchDialogComponent } from '../property-search-dialog/property-search-dialog.component';
import { UserService } from '../../service/user.service';
import { ShownDialogDataInterface } from '../../interface/shown-dialog-data.interface';
import { DialService } from '../../service/dial.service';
import { DeleteDialogComponent } from '../deleteDialog/delete-dialog.component';
import * as moment from 'moment';
import { SelectAllEditComponent } from '../select-all-edit/select-all-edit.component';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
	selector: 'is2-shown',
	templateUrl: './shown.component.pug',
	styleUrls: ['./shown.component.scss'],
})
export class ShownComponent extends SelectAllEditComponent implements OnInit, OnDestroy {

	@ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;

	@Input() public allowBulkEdit: boolean;
	@Input() protected agents: ValueInterface[];

	@Input('property')
	set property(property: PropertyModel) {
		this._property = property;
		this.ngOnInit();
	}

	get property(): PropertyModel {
		return this._property;
	}

	protected _property: PropertyModel;

	@Input('prospect')
	set prospect(prospect: ProspectModel) {
		this._prospect = prospect;
		this.ngOnInit();
	}

	get prospect(): ProspectModel {
		return this._prospect;
	}

	protected _prospect: ProspectModel;

	@Output() protected nextContactDateUpdate: EventEmitter<{nextContactDate: moment.Moment, prospect: Partial<ProspectListModel>}> = new EventEmitter();

	public loading: boolean;
	public phoneLinks: {phone: string, label: string}[] = [];
	public isMobile: boolean = false;
	public shouldShowFollowups: boolean;
	public matchShown: boolean = false;
	public showArchived: boolean = false;
	public archiveFormGroup = this.fb.group({
		showArchives: new FormControl(),
	});

	protected subject = new Subject<any>();
	protected user: UserModel;
	protected mediaSubscription: Subscription;

	constructor(
		protected shownService: ShownService,
		protected dialog: MatDialog,
		protected toastrService: ToastrService,
		protected userService: UserService,
		protected dialService: DialService,
		protected fb: FormBuilder,
		breakpointObserver: BreakpointObserver,
	) {
		super();
		this.user = userService.getUser();
		this.mediaSubscription = breakpointObserver
			.observe(Breakpoints.Handset)
			.subscribe(result => {
				this.isMobile = result.matches;
			});
	}

	public ngOnInit(): void {
		this.loading = true;
		this.dataSource = new PaginationDatasource<ShownModel>(this.getShowns.bind(this), this.paginator, null, [this.subject.asObservable()]);
		this.shouldShowFollowups = this.user.preferences.shouldShowFollowups;
	}

	public initializeFollowUpState(showns: ShownModel[]): void {
		if (this.shouldShowFollowups) {
			showns.forEach((shown) => {
				if (shown.followUps.length) {
					this.toggleFollowUps(shown);
				}
			});
		}
	}

	public ngOnDestroy(): void {
		if (this.mediaSubscription) {
			this.mediaSubscription.unsubscribe();
		}
	}

	public async addMatchMakerShown(match: PropertyMatchModel | ProspectMatchModel, result: ProspectModel | PropertyModel, event: MouseEvent): Promise<void> {
		this.matchShown = true;
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		let listingAgent: ValueInterface;
		const shown: ShownModel = new ShownModel({
			agent: {},
			sender: {},
		});

		shown.date = moment.utc(new Date()).format('YYYY-MM-DD');

		try {
			if (this.prospect) {
				shown.prospect = this.prospect.toListModel();
			} else {
				if (result instanceof ProspectModel) {
					shown.prospect = await result.toListModel();
				}
			}

			if (this.property) {
				shown.property = this.property.toListModel(this.agents);
				listingAgent = this.agents.find(agent => agent.name === shown.property.agent);
			} else {
				if (result instanceof PropertyModel) {
					shown.property = await result.toListModel();
					listingAgent = this.agents.find(agent => agent.id === (match as PropertyMatchModel).agent);
				}
			}
		} catch (e) {
			if (e) {
				throw e;
			}
			return; // user cancelled
		}

		shown.agent = {userId: <number>listingAgent.id};
		this.showDialog(shown);
	}

	public async add(event?: MouseEvent, basis?: ShownModel): Promise<void> {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		const shown: ShownModel = new ShownModel({
			agent: {},
			sender: {},
		});

		if (basis) {
			shown.date = moment.utc(basis.date).format('YYYY-MM-DD');
			shown.comments = basis.comments;
			shown.sender = basis.sender;
		}

		try {
			if (this.prospect) {
				shown.prospect = this.prospect.toListModel();
			} else {
				shown.prospect = await this.searchForProspect();
			}

			if (this.property) {
				shown.property = this.property.toListModel(this.agents);
			} else {
				shown.property = await this.searchForProperty();
			}
		} catch (e) {
			if (e) {
				throw e;
			}
			return; // user cancelled
		}

		const listingAgent: ValueInterface = this.agents.find(agent => agent.name === shown.property.agent);

		if (!listingAgent) {
			this.toastrService.error(`Unable to create due to listing agent being inactive.`);
			return;
		}
		shown.agent = {userId: <number>listingAgent.id};

		this.showDialog(shown);
	}

	public toggleArchives(): void {
		this.showArchived = this.archiveFormGroup.get('showArchives').value;
		this.ngOnInit();
	}

	public edit(event: MouseEvent, row: ShownModel): void {
		event.preventDefault();
		event.stopPropagation();

		const shown: ShownModel = new ShownModel(Object.assign({}, row, {
			date: moment.utc(row.date).format('YYYY-MM-DD'),
		}));

		this.showDialog(shown);
	}

	public hasPhoneNumber(shown: ShownModel): boolean {
		return !!shown.prospect.workPhone || !!shown.prospect.homePhone || !!shown.prospect.cellPhone || !!shown.prospect.directPhone;
	}

	public phoneButtonClicked(event: MouseEvent, shown: ShownModel): void {
		event.preventDefault();
		event.stopPropagation();

		this.phoneLinks = [
			{phone: shown.prospect.workPhone, label: 'Work Phone'},
			{phone: shown.prospect.homePhone, label: 'Home Phone'},
			{phone: shown.prospect.cellPhone, label: 'Cell Phone'},
			{phone: shown.prospect.directPhone, label: 'Direct Phone'},
		].filter(x => x.phone != null && x.phone !== '');
	}

	public checkDial(event: MouseEvent, phoneNumber: string): boolean {
		if (!this.userService.isAtDesk()) {
			return true;
		}

		event.preventDefault();
		this.dialService.dial(phoneNumber).subscribe(
			() => {
				this.dialed(null);
			},
			(error: HttpErrorResponse) => {
				this.dialed(error.error.message);
			},
		);
	}

	public addFollowUp(event: MouseEvent, shown: ShownModel): void {
		event.preventDefault();
		event.stopPropagation();

		const followUp: ShownModel = new ShownModel({
			parentId: shown.id,
			prospect: shown.prospect,
			property: shown.property,
			date: moment().format('YYYY-MM-DD'),
			agent: shown.agent,
			sender: shown.sender,
		});
		this.showDialog(followUp);
	}

	public toggleFollowUps(shown: ShownModel, event?: MouseEvent): void {
		if (!shown.followUps.length) {
			return;
		}

		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		shown.followUps.forEach((followUp: any) => followUp.expanded = !followUp.expanded);
	}

	public deleteSelectedBulkEditRows(event: MouseEvent): void {
		event.stopPropagation();
		event.preventDefault();

		let count: number;
		let method: string;
		let data: ShownModel[];

		if (this.selectAllMode && this.bulkEditExceptionList.length > 0) {
			count = this.dataSource.pager.totalItems - this.bulkEditExceptionList.length;
			method = 'bulkDelete';
			data = this.bulkEditExceptionList as ShownModel[];
		}

		if (this.selectAllMode && this.bulkEditExceptionList.length === 0) {
			count = this.bulkEditSelection.selected.length;
			method = 'delete';
			data = this.bulkEditSelection.selected as ShownModel[];
		}

		if (!this.selectAllMode) {
			count = this.bulkEditExceptionList.length;
			method = 'delete';
			data = this.bulkEditSelection.selected as ShownModel[];
		}

		if (!count) {
			this.toggleBulkEditMode();
			return;
		}

		this.dialog
			.open(DeleteDialogComponent, {
				width: '400px',
				data: {
					text: `these ${count} showns`,
					type: 'Showns',
				},
			})
			.afterClosed()
			.subscribe((result: string) => {
				if (result !== 'Delete') {
					return;
				}
				this.shownService[method](data.map(shown => shown.id), (this.prospect ? this.prospect.id : null), (this.property ? this.property.id : null))
					.subscribe(
						() => {
							this.toastrService.success(`${count} showns have been deleted.`);
							this.toggleBulkEditMode();
							this.subject.next();
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						},
					);
			});
	}

	public getDisplayedColumns(): string[] {
		const columns: string[] = [
			'date',
			'agent',
			'sender',
			'comments',
			'numFollowUps',
		];

		if (this.prospect) {
			columns.unshift('propertyName');
		} else if (this.property) {
			columns.unshift('prospectName');

			if (!this.bulkEditMode) {
				columns.unshift('clickToDial');
			}
		}

		if (this.bulkEditMode) {
			columns.unshift('bulkEditCheckboxes');
		} else {
			columns.push('addFollowUp');
		}

		return columns;
	}

	public allowAddFollowUp(shown: ShownModel): boolean {
		return this.userService.hasRole('Admin') || this.userService.hasRole('Secretary') || this.user.userId === shown.sender.userId;
	}

	protected showDialog(shown: ShownModel): void {
		const canEditOrDelete: boolean = (this.allowBulkEdit || shown.sender.userId == null || this.user.userId === shown.sender.userId);
		this.dialog
			.open(ShownDialogComponent, {
				width: '600px',
				data: {
					shown: shown,
					action: shown.id ? 'Edit' : 'Add',
					type: !!shown.parentId ? 'Follow Up' : 'Shown',
					canEdit: canEditOrDelete,
					canDelete: shown.id && canEditOrDelete,
					agents: this.agents,
					addFromMatch: this.matchShown,
				},
			})
			.afterClosed()
			.subscribe((result: string | ShownDialogDataInterface) => {
				let observable: Observable<any>;
				if (result === 'Delete') {
					observable = this.shownService.delete(shown.id);
				}

				if (result === 'Toggle') {
					shown.isArchived = !shown.isArchived;
					observable = this.shownService.updateArchiveStatus(shown);
				}

				if (this.isShownDialogDataInterface(result)) {
					if (shown.id) {
						observable = this.shownService.update(result.shown);
					} else {
						observable = this.shownService.create(result.shown);
					}

					if (result.shouldUpdateNextContactDate) {
						this.nextContactDateUpdate.emit({nextContactDate: moment().add(1, 'weeks'), prospect: shown.prospect});
					}
				}

				if (observable) {
					observable.subscribe(
						async () => {
							this.subject.next();
							this.toastrService.success('Shown record successfully ' + (result === 'Delete' ? 'deleted' : 'saved') + '.');
							this.matchShown = false;

							if (this.isShownDialogDataInterface(result) && result.addAnother) {
								await this.add(null, result.shown);
							}
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						},
					);
				}
			});
	}

	protected getShowns(): Observable<PaginationDto<ShownModel>> {
		let method: string;
		let id: number;

		if (this.prospect) {
			method = 'findByProspectId';
			id = this.prospect.id;
		} else if (this.property) {
			method = 'findByPropertyId';
			id = this.property.id;
		} else {
			throw new Error('Need either a property or prospect record');
		}

		return this.shownService[method](id, this.paginator.pageIndex, this.paginator.pageSize, this.showArchived)
			.finally(() => this.loading = false)
			.map(dto => {
				const showns: ShownModel[] = [];
				dto.data.forEach(shown => {
					showns.push(shown, ...shown.followUps);
				});
				dto.data = showns;
				this.initializeFollowUpState(showns);

				if (this.selectAllMode) {
					const filterShowns: ShownModel[] = showns.filter(this.compareArrays(this.bulkEditExceptionList));
					const filterExcluded: ShownModel[] = this.bulkEditExceptionList.filter(this.compareArrays(showns));
					const results: ShownModel[] = filterShowns.concat(filterExcluded);

					results.forEach((result) => this.bulkEditSelection.select(result));
				}

				if (this.bulkEditMode && !this.selectAllMode) {
					const exceptions: ShownModel[] = showns.filter((remark) => this.bulkEditExceptionList.map(exception => exception.id).includes(remark.id));
					exceptions.forEach((exception) => this.bulkEditSelection.select(exception));
				}

				return dto;
			});
	}

	protected searchForProspect(): Promise<ProspectListModel> {
		return new Promise<ProspectListModel>((resolve: any, reject: any): void => {
			this.dialog
				.open(ProspectSearchDialogComponent, {
					width: '1100px',
					data: {
						multiple: false,
					},
				})
				.afterClosed()
				.subscribe((prospect: ProspectListModel): void => {
					if (!prospect) {
						reject();
						return;
					}
					resolve(prospect);
				});
		});
	}

	protected searchForProperty(): Promise<PropertyListModel> {
		return new Promise<PropertyListModel>((resolve: any, reject: any): void => {
			this.dialog
				.open(PropertySearchDialogComponent, {
					width: '1000px',
					data: {
						multiple: false,
					},
				})
				.afterClosed()
				.subscribe((property: PropertyListModel): void => {
					if (!property) {
						reject();
						return;
					}
					resolve(property);
				});
		});
	}

	protected isShownDialogDataInterface(result: any): result is ShownDialogDataInterface {
		return result.hasOwnProperty('shown') && result.shown instanceof ShownModel;
	}

	protected dialed(error: string) {
		if (!error) {
			this.toastrService.success('Dial completed.');
		} else {
			this.toastrService.error(error);
		}
	}

}

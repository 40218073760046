import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

@Component({
	selector: 'property-highlights',
	templateUrl: './highlights.component.pug',
	styleUrls: ['./highlights.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class HighlightsComponent {

	public static readonly LISTING_URL: string = 'http://www.thomasduke.com/property';
	public static readonly BROCHURE_URL: string = 'http://www.thomasduke.com/brochure';

	@Input() public buildoutId: number;
	@Input() public highlightsFormGroup: FormGroup;
	@Input() public createMode: boolean;
	@Input() public hasAdminRights = false;

	public getHighlights(): AbstractControl[] {
		const highlights: FormArray = this.highlightsFormGroup.get('highlights') as FormArray;
		return highlights.controls;
	}

	public getListingUrl(): string {
		return HighlightsComponent.LISTING_URL + '/' + this.buildoutId;
	}

	public getBrochureUrl(): string {
		return HighlightsComponent.BROCHURE_URL + '/' + this.buildoutId;
	}

	public hasError(index, error) {
		const highlights: FormArray = this.highlightsFormGroup.get('highlights') as FormArray;
		return highlights.controls[index].hasError(error);
	}
}

import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProspectDuplicateDialogDataInterface } from '../../interface/prospect-duplicate-dialog-data.interface';
import { Observable, Subject } from 'rxjs';
import { PaginationDto, ProspectListModel, ProspectSearchDto } from '@dvp/is2-shared';
import { ProspectSearchService } from '../../service/prospect-search.service';
import { ProspectPaginationListComponent } from '../../../../component/prospect-pagination-list/prospect-pagination-list.component';
import { ProspectSelectionEventInterface } from '../../../../interface/prospect-selection-event.interface';

@Component({
	templateUrl: './prospect-duplicate-dialog.component.pug',
	styleUrls: ['./prospect-duplicate-dialog.component.scss'],
})
export class ProspectDuplicateDialogComponent implements OnInit {

	@ViewChild('prospectPaginationListComponent', {static: true}) protected prospectPaginationListComponent: ProspectPaginationListComponent;

	protected modelChanged: Subject<void> = new Subject<void>();

	constructor(
		protected dialogRef: MatDialogRef<ProspectDuplicateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ProspectDuplicateDialogDataInterface,
		protected prospectSearchService: ProspectSearchService,
	) {
		this.modelChanged
			.debounceTime(300)
			.subscribe(this.doSearch.bind(this));
	}

	public ngOnInit(): void {
		this.valuesFilled();
	}

	public changed(): void {
		this.modelChanged.next();
	}

	public valuesFilled(): void {
		if (this.data.firstName && this.data.lastName || this.data.company) {
			this.changed();
		}
	}

	public searchFunction = (pageIndex: number, pageSize: number): void | Observable<PaginationDto<ProspectListModel>> => {
		const searchDto: Partial<ProspectSearchDto> = {
			details: {},
		};

		if (this.data.firstName && this.data.lastName) {
			searchDto.details.firstName = this.data.firstName;
			searchDto.details.lastName = this.data.lastName;
		}

		if (this.data.company) {
			searchDto.details.company = this.data.company;
		}

		if (Object.keys(searchDto.details).length === 0) {
			this.prospectPaginationListComponent.clear();
			return;
		}

		return this.prospectSearchService.checkDuplicates(searchDto, this.data.prospectId, pageIndex, pageSize);
	};

	public prospectSelected(event: ProspectSelectionEventInterface): void {
		this.dialogRef.close({status: 'Duplicate', prospect: event.prospect});
	}

	protected doSearch(): void {
		this.prospectPaginationListComponent.doSearch();
	}

}

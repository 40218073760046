import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpHandler,
	HttpHeaderResponse,
	HttpInterceptor,
	HttpProgressEvent,
	HttpRequest,
	HttpResponse,
	HttpSentEvent,
	HttpUserEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TokenService } from '../service/token.service';
import { MatDialog } from '@angular/material';
import { LoginDialog } from '../dialog/login/login.dialog';
import { RouterService } from '../../../service/router.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AuthService } from '../service/auth.service';
import { TokenResponseModel } from '../model/token-response.model';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/take';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	protected offlineMessageShown: boolean = false;
	protected isRefreshingToken = false;
	protected tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		protected tokenService: TokenService,
		protected dialog: MatDialog,
		protected router: RouterService,
		protected authService: AuthService,
		protected toastrService: ToastrService,
	) {}

	public intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

		if (!navigator.onLine) {
			return this.handleOfflineError();
		}

		// don't intercept if not going to the api
		if (req.url.indexOf(environment.apiUrl) === -1) {
			return next.handle(req);
		}

		return next.handle(this.addToken(req, this.tokenService.getAccessToken())).catch((error: any) => {

			if (error instanceof HttpErrorResponse && error.url && error.url.indexOf('account/login') === -1) {
				switch (error.status) {
					case 400:
						return this.handle400Error(error);
					case 401:
						return this.handle401Error(req, next);
				}
			}
			return throwError(error);
		});
	}

	protected addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
		return req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
	}

	protected handle400Error(error) {
		if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
			// If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
			return this.logoutUser();
		}

		return throwError(error);
	}

	protected handle401Error(req: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshingToken && req instanceof HttpRequest && req.url && req.url.indexOf('token/refresh') >= 0) {
			// were we already doing a token refresh elsewhere? if so just throw what we already had
			return throwError(req);
		} else if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			// If this error was triggered by a resolve, make sure we tell navigation something happened
			this.router.next();

			// Reset here so that the following requests wait until the token
			// comes back from the refreshToken call.
			this.tokenSubject.next(null);

			return this.authService.doTokenRefresh().switchMap((response: TokenResponseModel) => {
				if (response && response.accessToken) {
					this.tokenSubject.next(response.accessToken);
					return next.handle(this.addToken(req, response.accessToken));
				}

				// If we don't get a new token, we are in trouble so logout.
				return this.logoutUser();
			})
				.catch(() => {
					// If there is an exception calling 'refreshToken', bad news so logout.
					return this.logoutUser();
				})
				.finally(() => {
					this.isRefreshingToken = false;
				});
		} else {
			return this.tokenSubject
				.filter(token => token != null)
				.take(1)
				.switchMap(token => {
					return next.handle(this.addToken(req, token));
				});
		}
	}

	protected logoutUser() {
		// If the refresh token came back as a failure, clear out all we have for the tokens
		this.tokenService.clear();
		// Prompt the user to re-login
		const dialogRef = this.dialog.open(LoginDialog, {
			width: '400px',
			disableClose: true,
			hasBackdrop: true,
			data: {
				success: () => {
					dialogRef.close();
				},
			},
		});

		return throwError('');
	}

	protected handleOfflineError() {
		if (!this.offlineMessageShown) {
			setTimeout(() => this.toastrService.warning('Internet connection lost.'));
			this.offlineMessageShown = true;

			setTimeout(() => {
				this.offlineMessageShown = false;
			}, 10000);

			return Observable.throwError('Internet connection lost.');
		}

		return Observable.onErrorResumeNext(Observable.empty());
	}

}

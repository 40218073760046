import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	templateUrl: './prospect-restriction-dialog.component.pug',
	styleUrls: ['./prospect-restriction-dialog.component.scss'],
})
export class ProspectRestrictionDialogComponent {

	public dropdownOptions: { label: string, value: string }[];
	public canSendEmailDropdown: string;
	public canSendLetterDropdown: string;

	constructor(public dialogRef: MatDialogRef<ProspectRestrictionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
		this.canSendEmailDropdown = 'dontChange';
		this.canSendLetterDropdown = 'dontChange';
		this.dropdownOptions = [
			{ label: `Don't Change`, value: 'dontChange' },
			{ label: 'Clear Filter', value: 'clear' },
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no' }
		];
	}

	public close(): void {
		this.dialogRef.close({
			canSendEmailDropdown: this.canSendEmailDropdown,
			canSendLetterDropdown: this.canSendLetterDropdown,
		});
	}

}

import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProspectPage } from '../../page/prospect/prospect.page';

@Component({
	selector: 'prospect-edit',
	templateUrl: './prospect-edit.component.pug',
	styleUrls: ['./prospect-edit.component.scss'],
})

export class ProspectEditComponent {

	@ViewChild(ProspectPage, {static: true}) protected prospectPage: ProspectPage;

	public prospect;

	constructor(
		protected router: Router,
		protected route: ActivatedRoute,
	) {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	}

	public goToDuplicateSelected(prospect: any): void {
		// noinspection JSIgnoredPromiseFromCall
		this.router.navigateByUrl('/prospect/detail/' + prospect.id);
	}

}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { LoginDialogModel } from '../../model/login-dialog.model';

@Component({
	selector: 'dvp-login-dialog',
	templateUrl: './login.dialog.pug',
	styleUrls: ['./login.dialog.scss']
})
export class LoginDialog {

	public success: () => any = () => {};

	constructor(protected toastrService: ToastrService,
							@Inject(MAT_DIALOG_DATA) protected data: LoginDialogModel) {
		this.success = data.success;

		toastrService.error('Your session has expired! Please re-login.');
	}

}

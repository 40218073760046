import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectChange } from '@angular/material';
import {
	TaskOccurrenceModel,
	UserListModel,
	TaskOccurrenceAssigneeModel,
	ValueInterface,
	TaskOccurrenceDto,
} from '@dvp/is2-shared';
import { UsersService } from '../../module/admin/service/users.service';
import { UserService } from '../../service/user.service';
import * as moment from 'moment';
import { TaskService } from '../../service/task.service';
import { RRule, RRuleSet } from 'rrule';

@Component({
	templateUrl: './task-dialog.component.pug',
	styleUrls: ['./task-dialog.component.scss'],
})

export class TaskDialogComponent implements OnInit {

	public taskFormGroup: FormGroup;
	public createMode: boolean = false;
	public userMode: boolean = false;
	public users: UserListModel[];
	public currentDate = new Date();
	public labelNames: ValueInterface[];
	public ruleSet: RRuleSet = new RRuleSet();
	public endRepeatOn: boolean = false;
	public endRepeatAfter: boolean = false;
	public neverEnd: boolean = false;
	public data: {
		propertyId: number,
		prospectId: number,
		task: TaskOccurrenceDto,
		action: string,
		hasAdminRights: boolean,
		hasEditRights: boolean,
		isAssignee: boolean,
		dashboardPage?: boolean,
	};
	public frequencies: ValueInterface[];
	public weekdays: ValueInterface[];
	public endOptions: ValueInterface[];
	public intervals: ValueInterface[];

	constructor(
		protected dialogRef: MatDialogRef<TaskDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		protected fb: FormBuilder,
		protected usersService: UsersService,
		protected userService: UserService,
		protected taskService: TaskService,
	) {
		this.data = data;
	}

	public ngOnInit(): void {
		this.usersService.getAllUsers().subscribe((users: UserListModel[]) => {
			this.users = users.filter(user => user.active);
		});
		this.taskService.getLabels().subscribe((result: ValueInterface[]) => {
			this.labelNames = result;
		});

		if (this.data.action === 'Add' || this.data.action === 'User Add') {
			this.createMode = true;
			if (this.data.action === 'User Add') {
				this.userMode = true;
			}
		}
		if (this.data.isAssignee && !this.data.hasEditRights) {
			this.userMode = true;
		}
		this.createFormGroup();
		this.setValues();
	}

	public setValues(): void {
		this.endOptions = [
			{id: 'never', name: 'Never'},
			{id: 'on', name: 'On'},
			{id: 'after', name: 'After'},
		];

		this.weekdays = [
			{id: 0, name: 'Monday'},
			{id: 1, name: 'Tuesday'},
			{id: 2, name: 'Wednesday'},
			{id: 3, name: 'Thursday'},
			{id: 4, name: 'Friday'},
		];

		this.frequencies = [
			{id: RRule.DAILY.valueOf(), name: 'Days'},
			{id: RRule.WEEKLY.valueOf(), name: 'Weeks'},
			{id: RRule.MONTHLY.valueOf(), name: 'Months'},
		];

		this.intervals = [
			{id: 1, name: '1'},
			{id: 2, name: '2'},
			{id: 3, name: '3'},
			{id: 4, name: '4'},
			{id: 5, name: '5'},
			{id: 6, name: '6'},
			{id: 7, name: '7'},
			{id: 8, name: '8'},
			{id: 9, name: '9'},
			{id: 10, name: '10'},
			{id: 11, name: '11'},
			{id: 12, name: '12'},
			{id: 13, name: '13'},
			{id: 14, name: '14'},
			{id: 15, name: '15'},
			{id: 16, name: '16'},
			{id: 17, name: '17'},
			{id: 18, name: '18'},
			{id: 19, name: '19'},
			{id: 20, name: '20'},
			{id: 21, name: '21'},
			{id: 22, name: '22'},
			{id: 23, name: '23'},
			{id: 24, name: '24'},
			{id: 25, name: '25'},
			{id: 26, name: '26'},
			{id: 27, name: '27'},
			{id: 28, name: '28'},
			{id: 29, name: '29'},
			{id: 30, name: '30'},
			{id: 31, name: '31'},
		];
	}

	public createFormGroup(): void {
		let rule: RRule;

		if (this.data.task.rRuleSet) {
			rule = RRule.fromString(this.data.task.rRuleSet);
		}

		this.taskFormGroup = this.fb.group({
			title: [{
				value: this.data.task.occurrence.title,
				disabled: !this.createMode && !this.data.hasAdminRights && !this.data.hasEditRights,
			},
				[Validators.required]],
			description: {
				value: this.data.task.occurrence.description,
				disabled: !this.createMode && !this.data.hasAdminRights && !this.data.hasEditRights && !this.data.isAssignee,
			},
			startDatetime: [{
				value: moment.utc(this.data.task.occurrence.startDatetime),
				disabled: !this.createMode && !this.data.hasAdminRights && !this.data.hasEditRights,
			},
				[Validators.required]],
			dateCompleted: [{
				value: !this.data.task.occurrence.dateCompleted ? '' : moment.utc(this.data.task.occurrence.dateCompleted),
				disabled: this.data.task.occurrence.lastStatus === 'ToDo',
			}],
			assigneeId: [{
				value: this.data.task.occurrence.assignee.userId ? this.data.task.occurrence.assignee.userId : this.userService.getUser().userId,
				disabled: this.userMode && !this.data.hasAdminRights && !this.data.hasEditRights,
			},
				[Validators.required]],
			isPrivate: {
				value: this.data.task.isPrivate,
				disabled: !this.createMode && !this.data.hasAdminRights && !this.data.hasEditRights,
			},
			lastStatus: this.data.task.occurrence.lastStatus ? this.data.task.occurrence.lastStatus : 'ToDo',
			lastUpdated: this.data.task.occurrence.lastUpdated,
			labels: [],
			repeat: this.data.task.occurrence.id ? !!this.data.task.rRuleSet : false,
			updateRRule: false,
			interval: (rule && rule.origOptions.interval) ? rule.origOptions.interval : '',
			freq: rule ? rule.origOptions.freq : '',
			count: (rule && rule.origOptions.count) ? rule.origOptions.count : '',
			byweekday: [],
			until: (rule && rule.origOptions.until) ? rule.origOptions.until : '',
			endRepeat: '',
		});

		if (this.data.task.occurrence.labels) {
			this.taskFormGroup.patchValue(Object.assign({}, {labels: this.data.task.occurrence.labels}));
		}

		if (rule && rule.options.byweekday) {
			this.taskFormGroup.patchValue(Object.assign({}, {byweekday: rule.options.byweekday}));
		}

		if (rule && rule.origOptions.until) {
			this.endRepeatOn = true;
			this.taskFormGroup.controls['endRepeat'].setValue('on');
		}

		if (rule && rule.origOptions.count) {
			this.endRepeatAfter = true;
			this.taskFormGroup.controls['endRepeat'].setValue('after');
		}

		if (!this.data.task.rRuleSet) {
			this.neverEnd = true;
			this.taskFormGroup.controls['endRepeat'].setValue('never');
		}
	}

	public async saveTask(): Promise<boolean> {
		if (this.taskFormGroup.invalid) {
			this.taskFormGroup.controls['title'].markAsTouched();
			this.taskFormGroup.controls['startDatetime'].markAsTouched();
			this.taskFormGroup.controls['assigneeId'].markAsTouched();
			return false;
		}

		const task = await this.newTaskModel();
		if (task.occurrence.title) {
			this.dialogRef.close({task: task, action: this.taskFormGroup.controls['updateRRule'].value ? `${this.data.action} Repeat` : this.data.action});
		}
	}

	public endRepeatSelected(event: MatSelectChange): void {
		if (event.value === 'never') {
			this.neverEnd = true;
			this.endRepeatAfter = false;
			this.endRepeatOn = false;
		} else if (event.value === 'on') {
			this.neverEnd = false;
			this.endRepeatAfter = false;
			this.endRepeatOn = true;
		} else {
			this.neverEnd = false;
			this.endRepeatAfter = true;
			this.endRepeatOn = false;
		}
	}

	public deleteTask(): void {
		this.dialogRef.close({task: this.data.task, action: 'Delete'});
	}

	public toggleArchiveStatus(): void {
		this.dialogRef.close({task: this.data.task, action: 'Toggle'});
	}

	protected async setRRuleSet(): Promise<void> {
		if (this.taskFormGroup.controls['repeat'].value || this.taskFormGroup.controls['updateRRule'].value) {

			this.ruleSet.rrule(new RRule({
				freq: this.getFrequency(),
				interval: this.taskFormGroup.controls['interval'].value,
				byweekday: this.taskFormGroup.controls['byweekday'].value,
				count: (this.taskFormGroup.controls['count'].value ? this.taskFormGroup.controls['count'].value : undefined),
				until: (this.taskFormGroup.controls['until'].value ? this.taskFormGroup.controls['until'].value : undefined),
			}));

		}
	}

	protected getFrequency(): number {
		if (this.taskFormGroup.controls['freq'].value === RRule.DAILY.valueOf()) {
			return RRule.DAILY;
		}

		if (this.taskFormGroup.controls['freq'].value === RRule.WEEKLY.valueOf()) {
			return RRule.WEEKLY;
		}

		if (this.taskFormGroup.controls['freq'].value === RRule.MONTHLY.valueOf()) {
			return RRule.MONTHLY;
		}
	}

	protected async newTaskModel(): Promise<TaskOccurrenceDto> {
		await this.setRRuleSet();

		return new TaskOccurrenceDto({
			taskGroupId: this.data.task.taskGroupId,
			propertyId: this.data.task.propertyId || this.data.propertyId,
			propertyName: this.data.task.propertyName,
			prospectId: this.data.task.prospectId || this.data.prospectId,
			prospectName: this.data.task.prospectName,
			isPrivate: this.taskFormGroup.get('isPrivate').value,
			rRuleSet: this.ruleSet.toString() || '',
			occurrence: new TaskOccurrenceModel({
				id: this.data.task.occurrence.id,
				taskId: this.data.task.occurrence.taskId,
				title: this.taskFormGroup.get('title').value,
				description: this.taskFormGroup.get('description').value,
				startDatetime: this.taskFormGroup.get('startDatetime').value,
				lastStatus: this.taskFormGroup.get('lastStatus').value,
				changedBy: this.data.task.occurrence.changedBy,
				lastUpdated: new Date().toISOString(),
				dateCompleted: this.taskFormGroup.get('dateCompleted').value,
				assignee: new TaskOccurrenceAssigneeModel({
					userId: this.taskFormGroup.get('assigneeId').value,
					occurrenceId: this.data.task.occurrence.id,
				}),
				labels: this.taskFormGroup.get('labels').value,
			}),
		});
	}

}

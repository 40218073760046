import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CodesService} from '../../service/codes.service';
import {CategoryModel} from '@dvp/is2-shared/dist/model/category.model';
import {Subscription} from 'rxjs/Subscription';

@Component({
	templateUrl: './categories.page.pug',
	styleUrls: ['./categories.page.scss'],
})
export class CategoriesPage implements OnInit, OnDestroy {

	public searchText: string = null;
	public loading = false;
	public categories: CategoryModel[];

	private pdfSubscription: Subscription;

	constructor(private codesService: CodesService, private router: Router) {
	}

	public navigate(id: number): void {
		this.router.navigate([`/admin/category/detail/${id}`]);
	}

	public ngOnInit(): void {
		this.loading = true;
		this.codesService.getCategories().subscribe((results: CategoryModel[]) => {
			this.categories = results;
			this.loading = false;
		});
	}

	ngOnDestroy() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}
	}

	public print() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}

		this.loading = true;
		const tab: Window = window.open('/loading');
		this.pdfSubscription = this.codesService.getCategoriesPdf()
			.finally(() => {
				this.loading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}
}

import {Component, Input} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';


@Component({
	selector: 'app-chart-modal',
	templateUrl: './chart-modal.component.pug',
	styleUrls: ['./chart-modal.component.scss'],
	animations: [
		trigger(
			'modalAnimation', [
				transition(':enter', [
					style({transform: 'translate(-50%, -50%)', top: '-50%', opacity: 0}),
					animate('300ms ease-in')
				]),
				transition(':leave', [
					animate('300ms ease-in', style({transform: 'translate(-50%, -50%)', top: '-50%', opacity: 0}))
				])
			]
		),

		trigger(
			'backdropAnimation', [
				transition(':enter', [
					style({opacity: 0}),
					animate('300ms ease-in')
				]),
				transition(':leave', [
					style({opacity: .7}),
					animate('300ms ease-in', style({ opacity: 0 }))
				])
			]
		)
	]
})
export class ChartModalComponent {

	public containerTitle = '';
	public visible = false;

	@Input()
	private chartOptions: any;

	@Input()
	set title(title) {
		this.containerTitle = title;
	}

	public show(): void {
		this.visible = true;
	}

	public close() {
		this.visible = false;
	}

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProspectModel, ProspectSearchDto, ValueInterface } from '@dvp/is2-shared';
import { Observable } from 'rxjs';
import { Moment } from 'moment';
import { environment } from '../../../../environments/environment';
import { Service } from '../../../service';
import { ProspectSearchService } from './prospect-search.service';

@Injectable()
export class ProspectService extends Service {

	public static STATUSES: ValueInterface[] = [
		{id: 'ACTIVE', name: 'ACTIVE'},
		{id: 'DELETED', name: 'DELETED'},
	];

	constructor(protected http: HttpClient) {
		super();
	}

	public getProspect(id: number | string): Observable<ProspectModel> {
		return this.http.get<ProspectModel>(`${environment.apiUrl}/prospect/${id}`)
			.map(response => new ProspectModel(response));
	}

	public save(prospect: ProspectModel): Observable<any> {
		return this.http.put(`${environment.apiUrl}/prospect/${prospect.id}`, prospect);
	}

	public create(prospect: ProspectModel): Observable<any> {
		return this.http.post(`${environment.apiUrl}/prospect/create`, prospect);
	}

	public del(id: number): Observable<any> {
		return this.http.delete(`${environment.apiUrl}/prospect/${id}`);
	}

	public getPdf(id: number): Observable<ArrayBuffer> {
		return this.http.get(`${environment.apiUrl}/prospect/${id}/pdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer',
		});
	}

	public setNextContactDate(id: number, nextContactDate: Moment): Observable<any> {
		return this.http.put(`${environment.apiUrl}/prospect/${id}/next-contact-date`, {nextContactDate: nextContactDate.format('YYYY-MM-DD')});
	}

	public setLastCalledDate(id: number, lastCalledDate: Moment): Observable<any> {
		return this.http.put(`${environment.apiUrl}/prospect/${id}/last-called-date`, {lastCalledDate: lastCalledDate.format('YYYY-MM-DD')});
	}

	public bulkUpdateProspects(columns: Partial<ProspectModel>, prospectIds: number[]): Observable<any> {
		if (!Array.isArray(prospectIds)) {
			prospectIds = [prospectIds];
		}

		return this.http.put(`${environment.apiUrl}/prospect/bulk-update/${prospectIds.join(',')}`, {columns});
	}

	public selectAllUpdateProspects(columns: Partial<ProspectModel>, prospectIds: number[], searchDto: ProspectSearchDto) {
		if (!Array.isArray(prospectIds)) {
			prospectIds = [prospectIds];
		}

		return this.http.put(`${environment.apiUrl}/prospect/select-all-update`, {
			searchDto: ProspectSearchService.prepareSearchDto(searchDto),
			columns,
			prospectsToExclude: prospectIds.join(',')
		});
	}

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelectChange } from '@angular/material';
import { ProspectWantsModel, ValuesModel } from '@dvp/is2-shared';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ValueInterface } from '@dvp/is2-shared/dist/interface/value.interface';
import { ValuesService } from '../../../../../service/values.service';

@Component({
	selector: 'want-dialog',
	templateUrl: './want-dialog.component.pug',
	styleUrls: ['./want-dialog.component.scss'],
})
export class WantDialogComponent implements OnInit {

	public completed: boolean;
	public wantFormGroup: FormGroup;
	public citiesWithCounties: (ValueInterface & {countyId: number})[];
	public cities: ValueInterface[];

	public data: {
		action: string,
		addAnother: boolean,
		canEdit: boolean,
		canDelete: boolean,
		editMode: boolean,
		noSaveAndAdd: boolean,
		values: ValuesModel,
		want: ProspectWantsModel,
	};

	constructor(
		public dialogRef: MatDialogRef<WantDialogComponent>,
		protected valuesService: ValuesService,
		@Inject(MAT_DIALOG_DATA) data,
		protected fb: FormBuilder,
	) {
		data.addAnother = false;
		this.data = data;
	}

	public async ngOnInit(): Promise<void> {
		await this.getCitiesAndCounties();
		this.cities = this.data.values.city;
		this.createFormGroup();
	}

	public createFormGroup(): void {
		this.wantFormGroup = this.fb.group({
			id: this.data.want.id,
			financialClassId: this.data.want.financialClassId,
			financialClassName: this.data.want.financialClassName,
			minPrice: this.data.want.minPrice,
			maxPrice: this.data.want.maxPrice,
			minSize: this.data.want.minSize,
			maxSize: this.data.want.maxSize,
			countyId: this.data.want.countyId,
			categoryId: this.data.want.categoryId,
			cityId: this.data.want.cityId,
			roadId: this.data.want.roadId,
		});
	}

	public getFinancialClassId(): number {
		if (this.wantFormGroup.get('financialClassId').value) {
			return this.wantFormGroup.get('financialClassId').value;
		}
	}

	public setCitiesBasedOnCounty(event: MatSelectChange): void {
		if (event.value !== undefined) {
			const cities: (ValueInterface & {countyId: number})[] = this.citiesWithCounties.filter((city: ValueInterface & {countyId: number}) => city.countyId === event.value || city.countyId === 0 || city.countyId === null);
			if (cities.length > 0) {
				this.cities = cities;

				if (cities.length === 1) {
					this.wantFormGroup.controls['cityId'].setValue(cities[0].id);
				}
			} else {
				this.wantFormGroup.controls['cityId'].setValue(null);
			}
		} else {
			this.cities = this.data.values.city;
			this.wantFormGroup.controls['cityId'].setValue(null);
		}
	}

	public resetCategory(): void {
		if (!this.wantFormGroup.get('financialClassId').value) {
			this.data.want.categoryId = null;
		}
	}

	public save(): void {
		this.data.want = new ProspectWantsModel(this.wantFormGroup.value);
		this.dialogRef.close(this.data);
	}

	public saveAdd(): void {
		this.data.want = new ProspectWantsModel(this.wantFormGroup.value);
		this.data.addAnother = true;
		this.dialogRef.close(this.data);
	}

	protected async getCitiesAndCounties(): Promise<void> {
		await new Promise<void>(resolve => {
			this.valuesService.getCitiesAndCounties()
				.subscribe(values => {
					this.citiesWithCounties = values;
					resolve();
				});
		});
	}

}

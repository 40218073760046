import { NgModule } from '@angular/core';

import { LoginPage } from './page/login/login.page';
import { ReAuthGuard } from './guard/re-auth.guard';
import { SharedModule } from '../../shared.module';
import { RouterModule } from '@angular/router';
import { AuthService } from './service/auth.service';
import { TokenInterceptor } from './interceptor/token.interceptor';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavigationService } from '../../service/navigation.service';
import { TokenService } from './service/token.service';
import { StartupService } from '../../service/startup.service';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../../service/storage.service';
import { LoginFormComponent } from './component/login-form/login-form.component';
import { LoginDialog } from './dialog/login/login.dialog';
import { UserService } from '../../service/user.service';
import { RouterService } from '../../service/router.service';

@NgModule({
	declarations: [
		LoginFormComponent,
		LoginDialog,
		LoginPage,
	],
	imports: [
		RouterModule.forRoot([
			{
				path: 'login',
				component: LoginPage,
				canActivate: [ReAuthGuard],
			},
		]),
		SharedModule.forRoot(),
	],
	providers: [
		TokenInterceptor,
		AuthService,
		TokenService,
		ReAuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
	],
	entryComponents: [
		LoginDialog,
	],
})
export class LoginModule {

	constructor(
		navigationService: NavigationService,
		userService: UserService,
		authService: AuthService,
		startupService: StartupService,
		toastrService: ToastrService,
		routerService: RouterService,
	) {
		// Perform initial validation
		if (userService.hasUser() !== authService.hasAuth()) {
			// There is an auth token or a user token but not both so reset
			startupService.add(new Observable<string>(observer => {
				// Make sure the application stays in startup mode, we are re-directing so the app should never load
				authService.logout(true);
			}));
			return;
		}

		// If we have session_expired stored then we must have been redirected on load due to an expired session...
		if (StorageService.get('session_expired') && !authService.hasAuth()) {
			// Let the user know they do not have auth because their session has expired
			toastrService.error('Your session has expired! Please re-login.');
		}
		// Make sure the expired session variable is cleared
		StorageService.remove('session_expired');

		if (authService.hasAuth()) {
			// If the user is logged in, add a startup handler to make sure the auth token is valid before the user
			// is granted access to the application
			startupService.add(new Observable<string>(observer => {
				authService.doTokenRefresh().subscribe(
					() => {
						// Let the startup service know that this startup check is complete
						observer.complete();
					},
					() => {
						// If the token refresh has failed, set the marker to let the user know on logout
						StorageService.set('session_expired', true);
						// then logout (since we are just now booting, just force the user to the main login)
						authService.logout(true);
					},
				);
			}));
		}

		navigationService.addUserNavigation({
			label: 'Logout',
			action: authService.logout.bind(authService),
			ordinal: 99,
			iconClass: 'fa-sign-out-alt',
		});
	}

}

import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInput, MatDatepickerInputEvent } from '@angular/material';
import * as moment from 'moment';

@Component({
	selector: 'is2-relative-time',
	templateUrl: './relative-time.component.pug',
	styleUrls: ['./relative-time.component.scss'],
})
export class RelativeTimeComponent {

	@Input() public placeholder: string;
	@Input() public formGroup: FormGroup;
	@Input() public relationControlName: string;
	@Input() public valueControlName: string;

	@ViewChild('datepickerInput', {static: true}) protected datepickerInput: MatDatepickerInput<moment.Moment>;

	public timeRelationOptions = [
		'Beginning of',
		'End of',
		'Last',
		'This',
		'Next',
		'Specific Date',
	];
	public relativeTimeOptions = [
		'Yesterday',
		'Today',
		'Tomorrow',
		'Last Week',
		'This Week',
		'Next Week',
		'Last Month',
		'This Month',
		'Next Month',
		'Last Year',
		'This Year',
		'Next Year',
	];
	public daysOfWeek = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];

	public getRelationControl(): FormControl {
		return this.formGroup.controls[this.relationControlName] as FormControl;
	}

	public shouldShowRelativeTimes(): boolean {
		const value = this.getRelationControl().value;
		return !value || ['Beginning of', 'End of'].indexOf(value) !== -1;
	}

	public shouldShowDaysOfWeek(): boolean {
		const value = this.getRelationControl().value;
		return ['Last', 'This', 'Next'].indexOf(value) !== -1;
	}

	public shouldShowDatepicker(): boolean {
		const value = this.getRelationControl().value;
		return value === 'Specific Date';
	}

	public shouldDisableValueControl(): boolean {
		return !this.getRelationControl().value;
	}

	public relationChanged() {
		switch (true) {
			case this.shouldDisableValueControl():
				this.formGroup.controls[this.valueControlName].setValue(undefined);
				break;
			case this.shouldShowRelativeTimes():
				this.formGroup.controls[this.valueControlName].setValue(this.relativeTimeOptions[0]);
				break;
			case this.shouldShowDaysOfWeek():
				this.formGroup.controls[this.valueControlName].setValue(this.daysOfWeek[0]);
				break;
			case this.shouldShowDatepicker():
				this.formGroup.controls[this.valueControlName].setValue(moment().format('YYYY-MM-DD'));
				break;
		}
	}

	public datePicked(event: MatDatepickerInputEvent<moment.Moment>): void {
		if (!moment.isMoment(event.value)) {
			return;
		}
		this.formGroup.controls[this.valueControlName].setValue(event.value.format('YYYY-MM-DD'));
	}

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';

@Component({
	templateUrl: './login.page.pug',
	styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {

	protected _redirectUrl: string;
	public success = () => {
		this.router.navigateByUrl(this._redirectUrl);
	};

	constructor(protected route: ActivatedRoute,
	            protected router: Router) {}

	public ngOnInit() {
		// Capture where the user was originally trying to go (if anywhere)
		this._redirectUrl = this.route.snapshot.queryParams['redirect'] || '/';
	}

}

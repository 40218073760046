import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ProspectService } from '../../service/prospect.service';
import { PropertyListModel, ProspectModel, UserModel, UserPreferencesModel } from '@dvp/is2-shared';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatStepper } from '@angular/material';
import { CustomMessageInterface } from '../../../../interface/custom-message.interface';
import { UserService } from '../../../../service/user.service';
import { EmailSendConfirmDialogComponent } from '../../../../component/email-send-confirm-dialog/email-send-confirm-dialog.component';
import { EmailService } from '../../../../service/email.service';
import { ToastrService } from 'ngx-toastr';
import { PropertySelectionEventInterface } from '../../../../interface/property-selection-event.interface';

@Component({
	templateUrl: './share-property-search.component.pug',
	styleUrls: ['./share-property-search.component.scss'],
})
export class SharePropertySearchComponent implements OnInit, AfterViewInit, OnDestroy {

	public loading: boolean = true;
	public prospect: ProspectModel;
	public messageSignature: string;
	public shouldAddSignature: boolean;
	public mainMessage: string;
	public propertyMessages: {
		property: PropertyListModel,
		customMessage: CustomMessageInterface,
	}[] = [];

	@ViewChildren('renderedPropertyMessages') public renderedPropertyMessages: QueryList<any>;

	protected subscription;
	protected user: UserModel;
	protected selectedProperties: PropertyListModel[] = [];

	constructor(
		protected prospectService: ProspectService,
		protected emailService: EmailService,
		protected route: ActivatedRoute,
		protected cdr: ChangeDetectorRef,
		protected userService: UserService,
		protected dialog: MatDialog,
		protected toastrService: ToastrService,
		protected router: Router,
	) {}

	public ngOnInit() {
		this.setUserData();

		this.route.params.subscribe(async params => {
			const id = params['id'];

			if (id) {
				this.prospect = await this.getProspect(id);
			}
		});
	}

	public ngAfterViewInit() {
		this.subscription = this.renderedPropertyMessages.changes.subscribe(queryList => {
			if (queryList && queryList.length) {
				setTimeout(() => this.loading = false, 1500);
			}
		});
	}

	public ngOnDestroy() {
		this.subscription.next();
	}

	public propertySelected(event: PropertySelectionEventInterface) {
		if (event.selected) {
			this.selectedProperties.push(event.property);
		} else {
			this.selectedProperties = this.selectedProperties.filter(p => p.id !== event.property.id);
		}

		this.propertyMessages = this.selectedProperties.map(property => {
			return {
				property,
				customMessage: {
					id: property.id,
					text: null,
					customImage: null,
				},
			};
		});
	}

	public async sendEmail() {
		if (!await this.confirmImages()) {
			return;
		}

		const customMessages = this.propertyMessages.map(propertyMessage => propertyMessage.customMessage);
		const mainMessage = this.mainMessage || '';
		const messageSignature = this.messageSignature || '';

		this.emailService.shareMultipleListings({
			prospectDetails: this.prospect.details,
			fromUserId: this.user.userId,
			mainMessage,
			customMessages,
			messageSignature,
		})
			.subscribe(
				msg => {
					this.toastrService.success(msg);
					this.router.navigate(['/prospect/detail/', this.prospect.id]);
				},
				(error) => {
					this.toastrService.error(error);
				},
			);
	}

	public goBack(stepper: MatStepper) {
		stepper.previous();
		this.loading = true;
		this.cdr.detectChanges();
	}

	public goForward(stepper: MatStepper) {
		stepper.next();
	}

	public previewEmail() {
		const customMessages = this.propertyMessages.map(propertyMessage => propertyMessage.customMessage);
		const mainMessage = this.mainMessage || '';
		const messageSignature = this.messageSignature || '';
		const tab: Window = window.open('/loading');
		this.emailService.getMultiListingEmailPreview({mainMessage, customMessages, messageSignature})
			.subscribe(({html}) => {
				const file = new Blob([html], {type: 'text/html;charset=utf-8'});
				tab.location.replace(URL.createObjectURL(file));
			});
	}

	public toggleSignature(checked: boolean): void {
		const parser = new DOMParser();
		const doc = parser.parseFromString(this.messageSignature, 'text/html');

		if (checked) {
			this.addSignature(doc);
		} else {
			this.removeSignature(doc);
		}

		this.messageSignature = doc.body.innerHTML;
	}

	protected confirmImages() {
		return new Promise(resolve => {
			const customMessages = this.propertyMessages.map(propertyMessage => propertyMessage.customMessage);
			const customImages = customMessages.map(customMessage => customMessage.customImage);

			if (!customImages.every(i => !!i)) {

				const dialog = this.dialog.open(EmailSendConfirmDialogComponent, {
					width: '600px',
				});

				dialog.afterClosed().subscribe(result => {
					if (result === 'Ok') {
						resolve(true);
					} else {
						resolve(false);
					}
				});
			} else {
				resolve(true);
			}
		});
	}

	protected addSignature(doc: Document): void {
		const spacer = doc.createElement('div');
		spacer.innerHTML = '&nbsp;';

		const signature = doc.createElement('div');
		signature.id = 'emailSignature';
		signature.innerHTML = this.user.preferences.emailSignature;

		doc.body.appendChild(spacer.cloneNode());
		doc.body.appendChild(signature);
		doc.body.appendChild(spacer.cloneNode());
	}

	protected removeSignature(doc: Document): void {
		const signature = doc.getElementById('emailSignature');
		if (signature) {
			const previousSibling = signature.previousElementSibling;
			if (previousSibling && previousSibling.innerHTML === '&nbsp;') {
				previousSibling.parentNode.removeChild(previousSibling);
			}
			const nextSibling = signature.nextElementSibling;
			if (nextSibling && nextSibling.innerHTML === '&nbsp;') {
				nextSibling.parentNode.removeChild(nextSibling);
			}
			signature.parentNode.removeChild(signature);
		}
	}

	protected setUserData() {
		this.user = this.userService.getUser();
		if (!this.user.preferences) {
			this.user.preferences = new UserPreferencesModel();
		}
		this.messageSignature = this.user.preferences.propertySharingMessage || '';
		this.shouldAddSignature = this.user.preferences.shouldAddSignature;

		this.toggleSignature(this.shouldAddSignature);
	}

	protected getProspect(id: number): Promise<ProspectModel> {
		return new Promise(resolve => {
			this.prospectService.getProspect(id).subscribe(result => {
				if (result) {
					resolve(result);
				}
			});
		});
	}

}

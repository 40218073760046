import { environment } from '../../environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs/Rx';

export class Uploader {

	static EVENT = {
		AFTER_ADDING_ALL: 'afterAddingAll',
		BUILD_ITEM_FORM: 'buildItemForm',
		AFTER_ADDING_FILE: 'afterAddingFile',
		WHEN_ADDING_FILE_FAILED: 'whenAddingFileFailed',
		BEFORE_UPLOAD_ITEM: 'beforeUploadItem',
		PROGRESS_ITEM: 'progressItem',
		PROGRESS_ALL: 'progressAll',
		SUCCESS_ITEM: 'successItem',
		ERROR_ITEM: 'errorItem',
		CANCEL_ITEM: 'cancelItem',
		COMPLETE_ITEM: 'completeItem',
		COMPLETE_ALL: 'completeAll',
	};

	private readonly uploader: FileUploader;
	private subject: Subject<any> = new Subject();

	constructor(authToken = null) {
		this.uploader = new FileUploader({
			url: `${environment.apiUrl}/photo`,
			allowedFileType: ['image'],
			authToken: authToken,
			autoUpload: true,
		});
		this.uploader.onAfterAddingAll = (items) =>
			this.nextEvent(Uploader.EVENT.AFTER_ADDING_ALL, {items});
		this.uploader.onBuildItemForm = (items, form) =>
			this.nextEvent(Uploader.EVENT.BUILD_ITEM_FORM, {items, form});
		this.uploader.onAfterAddingFile = (item) =>
			this.nextEvent(Uploader.EVENT.AFTER_ADDING_FILE, {item});
		this.uploader.onWhenAddingFileFailed = (item, filter, options) =>
			this.nextEvent(Uploader.EVENT.WHEN_ADDING_FILE_FAILED, {item, filter, options});
		this.uploader.onBeforeUploadItem = (item) =>
			this.nextEvent(Uploader.EVENT.BEFORE_UPLOAD_ITEM, {item});
		this.uploader.onProgressItem = (item, progress) =>
			this.nextEvent(Uploader.EVENT.PROGRESS_ITEM, {item, progress});
		this.uploader.onProgressAll = (progress) =>
			this.nextEvent(Uploader.EVENT.PROGRESS_ALL, {progress});
		this.uploader.onSuccessItem = (item, response, status, headers) =>
			this.nextEvent(Uploader.EVENT.SUCCESS_ITEM, {item, response, status, headers});
		this.uploader.onErrorItem = (item, response, status, headers) =>
			this.nextEvent(Uploader.EVENT.ERROR_ITEM, {item, response, status, headers});
		this.uploader.onCancelItem = (item, response, status, headers) =>
			this.nextEvent(Uploader.EVENT.CANCEL_ITEM, {item, response, status, headers});
		this.uploader.onCompleteItem = (item, response, status, headers) =>
			this.nextEvent(Uploader.EVENT.COMPLETE_ITEM, {item, response, status, headers});
		this.uploader.onCompleteAll = () =>
			this.nextEvent(Uploader.EVENT.COMPLETE_ALL);
	}

	public getUploader() {
		return this.uploader;
	}

	public subscribe(next: any) {
		return this.subject.subscribe(next);
	}

	private nextEvent(event, value = {}) {
		this.subject.next({
			event,
			value,
		});
	}

}

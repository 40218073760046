import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSelectChange, MatTableDataSource } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { HasDialogDataInterface } from '../../../interface/has-dialog-data.interface';
import { ProspectHasModel } from '@dvp/is2-shared';
import { ProspectHasService } from '../../../service/prospect-has.service';
import { TableColumnInterface } from '../../../../../component/table/interface/table-column.interface';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { IconService } from '../../../../../service/icon.service';
import { ValuesService } from '../../../../../service/values.service';
import { ValueInterface } from '@dvp/is2-shared/dist/interface/value.interface';

@Component({
	selector: 'has-dialog',
	templateUrl: './has-dialog.component.pug',
	styleUrls: ['./has-dialog.component.scss'],
})
export class HasDialogComponent implements OnInit {

	protected duplicates: ProspectHasModel[];
	public hasFormGroup: FormGroup;

	public completed: boolean;
	public currentDate = new Date();
	public selectedTab: number;
	public parcelId = new FormControl('', [Validators.maxLength(30)]);
	public duplicatesDataSource: MatTableDataSource<Partial<ProspectHasModel>>;
	public possibleDuplicatesTab: boolean = false;
	public duplicatesColumns: TableColumnInterface[] = [
		{columnDef: 'owner', title: 'Owner'},
		{columnDef: 'statusText', title: 'Status', allowHtml: true},
		{columnDef: 'parcelId', title: 'Parcel ID'},
		{columnDef: 'name', title: 'Name'},
		{columnDef: 'street', title: 'Street Address'},
		{columnDef: 'location', title: 'Location'},
		{columnDef: 'financialClass', title: 'Financial Class'},
		{columnDef: 'category', title: 'Category'},
	];
	public citiesWithCounties: (ValueInterface & {countyId: number})[];
	public cities: ValueInterface[];

	constructor(
		protected prospectHasService: ProspectHasService,
		protected valuesService: ValuesService,
		protected dialog: MatDialog,
		protected toastrService: ToastrService,
		protected dialogRef: MatDialogRef<HasDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: HasDialogDataInterface,
		protected fb: FormBuilder,
		protected iconService: IconService,
	) {}

	public async ngOnInit(): Promise<void> {
		await this.getCitiesAndCounties();
		this.cities = this.data.values.city;
		this.createFormGroup();
	}

	public createFormGroup(): void {
		this.hasFormGroup = this.fb.group({
			id: this.data.has.id,
			name: [this.data.has.name, [Validators.maxLength(100)]],
			type: this.data.has.type,
			streetName: [this.data.has.streetName, [Validators.maxLength(30)]],
			streetNumber: [this.data.has.streetNumber, [Validators.maxLength(20)]],
			countyId: this.data.has.countyId,
			cityId: this.data.has.cityId,
			roadId: this.data.has.roadId,
			financialClassId: this.data.has.financialClassId,
			categoryId: this.data.has.categoryId,
			price: this.data.has.price,
			size: this.data.has.size,
			parcelId: [this.data.has.parcelId, [Validators.maxLength(100)]],
			zoning: [this.data.has.zoning, [Validators.maxLength(50)]],
			purchaseDate: this.data.has.purchaseDate,
			comments: this.data.has.comments,
		});
	}

	public resetCategory(): void {
		const has = new ProspectHasModel();
		has.getFinancialClassName(this.data.values);
		if (!this.hasFormGroup.get('financialClassId').value) {
			this.data.has.categoryId = null;
		}
	}

	public getFinancialClassId(): number {
		if (this.hasFormGroup.get('financialClassId').value) {
			return this.hasFormGroup.get('financialClassId').value;
		}
	}

	public setCitiesBasedOnCounty(event: MatSelectChange): void {
		if (event.value !== undefined) {
			const cities: (ValueInterface & {countyId: number})[] = this.citiesWithCounties.filter((city) => city.countyId === event.value || city.countyId === 0 || city.countyId === null);
			if (cities.length > 0) {
				this.cities = cities;

				if (cities.length === 1) {
					this.hasFormGroup.controls['cityId'].setValue(cities[0].id);
				}
			} else {
				this.hasFormGroup.controls['cityId'].setValue(null);
			}
		} else {
			this.cities = this.data.values.city;
			this.hasFormGroup.controls['cityId'].setValue(null);
		}
	}

	public setCountyBasedOnCity(event: MatSelectChange): void {
		if (event.value !== undefined) {
			const city: (ValueInterface & {countyId: number}) = this.citiesWithCounties.find((city) => city.id === event.value);

			if (city.countyId !== null && city.countyId !== 0) {
				this.hasFormGroup.controls['countyId'].setValue(city.countyId);
			} else {
				this.hasFormGroup.controls['countyId'].setValue(null);
			}

		} else {
			this.hasFormGroup.controls['countyId'].setValue(null);
		}
	}

	public checkDuplicates(): void {
		this.duplicatesDataSource = undefined;
		const search: Partial<ProspectHasModel> = {};

		const streetNumber: number = this.hasFormGroup.get('streetNumber').value;
		const streetName: string = this.hasFormGroup.get('streetName').value;
		const parcelId: string = this.hasFormGroup.get('parcelId').value;

		if (streetNumber && streetName) {
			search.streetNumber = parseInt(streetNumber.toString().trim(), 10);
			search.streetName = streetName.trim();
		}

		if (parcelId && parcelId.length >= 5) {
			search.parcelId = parcelId.trim();
		}

		if (Object.keys(search).length === 0) {
			this.duplicates = this.duplicatesDataSource = undefined;
			return;
		}

		this.prospectHasService.searchHas(search).subscribe(async duplicates => {
			duplicates = duplicates.filter(duplicate => duplicate.id !== this.hasFormGroup.get('id').value);

			if (duplicates.length === 0) {
				return;
			}

			this.duplicates = duplicates;
			this.duplicatesDataSource = await this.prospectHasService.modelsToDataSource(duplicates, this.data.values);

			this.duplicatesDataSource.data.map((duplicate) => {
				(duplicate as any).statusText = this.iconService.getProspectStatusIcon(duplicate.status);
			});
		});
	}

	public save(): void {
		this.data.has = new ProspectHasModel(this.hasFormGroup.value);
		this.dialogRef.close(this.data);
	}

	public move(): void {
		this.data.action = 'Move';
		this.data.has = new ProspectHasModel(this.hasFormGroup.value);
		this.dialogRef.close(this.data);
	}

	public saveAndMove(): void {
		this.data.action = 'Save & Move';
		this.data.has = new ProspectHasModel(this.hasFormGroup.value);
		this.dialogRef.close(this.data);
	}

	public delete(): void {
		this.data.action = 'Delete';
		this.dialogRef.close(this.data);
	}

	public cancel(): void {
		if (this.possibleDuplicatesTab) {
			this.possibleDuplicatesTab = !this.possibleDuplicatesTab;
			this.selectedTab = 0;
		} else {
			this.data.action = 'Cancel';
			this.dialogRef.close(this.data);
		}
	}

	public selectionChanged(event): void {
		this.selectedTab = event.index;
		this.possibleDuplicatesTab = event.index === 1;
	}

	public viewDuplicate($event: {$event: MouseEvent, i: number, row: ProspectHasModel}) {
		const duplicate: ProspectHasModel = this.getDuplicate($event.row.prospectId);
		const has = new ProspectHasModel($event.row, this.data.values);
		has.purchaseDate = has.purchaseDate ? new Date(has.purchaseDate) : null;

		this.dialog
			.open(HasDialogComponent, {
				width: '925px',
				data: {
					has: has,
					types: ProspectHasService.TYPES,
					values: this.data.values,
					userCanEdit: false,
					userCanDelete: false,
					editMode: false,
					itemCanBeMoved: duplicate.prospectId !== this.data.prospectId,
				},
			})
			.afterClosed()
			.subscribe((result) => {
				if (result.action !== 'Move') {
					return;
				}
				this.prospectHasService.moveHas(duplicate.prospectId, duplicate.id, this.data.prospectId)
					.subscribe(
						(data: ProspectHasModel) => {
							result.has = data;
							this.toastrService.success('Has entry successfully moved.');
							this.dialogRef.close(result);
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						},
					);
			});
	}

	protected async getCitiesAndCounties(): Promise<void> {
		await new Promise<void>(resolve => {
			this.valuesService.getCitiesAndCounties()
				.subscribe(values => {
					this.citiesWithCounties = values;
					resolve();
				});
		});
	}

	protected getDuplicate(prospectId: number): ProspectHasModel {
		return this.duplicates.find((duplicate) => duplicate.prospectId === prospectId);
	}

}

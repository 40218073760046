import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {CanDeactivate} from '@angular/router';

export interface CanComponentDeactivate {
    onBeforeUnload: ($event: BeforeUnloadEvent) => void;
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

    public canDeactivate(component: CanComponentDeactivate) {
        return !component.canDeactivate || component.canDeactivate();
    }
}

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ValuesService } from '../../../../../service/values.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PropertySearchDto, SavedSearchModel, ValuesModel } from '@dvp/is2-shared';
import { MatDialog } from '@angular/material';
import { YesNoDialogComponent } from '../../../../../component/yes-no-dialog/yes-no-dialog.component';

@Component({
	selector: 'search-filters',
	templateUrl: './search-filters.component.pug',
	styleUrls: ['./search-filters.component.scss'],
})

export class SearchFiltersComponent implements OnInit, OnChanges {

	public codeMask = [
		/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/,
		'-',
		/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/,
		'-',
		/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/,
	];

	public searchForm: FormGroup;
	public saveChanges: boolean = false;
	public values: ValuesModel;

	@Input()
	public preset: SavedSearchModel<PropertySearchDto>;

	@Input()
	public filters: PropertySearchDto;

	@Output()
	public cancel: EventEmitter<any> = new EventEmitter<any>();

	@Output()
	public apply: EventEmitter<{search: SavedSearchModel<PropertySearchDto>, saveChanges: boolean}> = new EventEmitter<{search: SavedSearchModel<PropertySearchDto>, saveChanges: boolean}>();

	@ViewChild('nameInput', {static: true}) public nameInput: ElementRef;

	constructor(
		protected valuesService: ValuesService,
		protected fb: FormBuilder,
		protected yesNoDialog: MatDialog,
	) {}

	public async applyFilters(): Promise<void> {
		const search = new SavedSearchModel<PropertySearchDto>();
		search.filters = new PropertySearchDto(this.searchForm.value);

		if (this.preset && this.preset.name) {
			await this.openYesNoDialog();
			search.isPublic = this.preset.isPublic;
			search.name = this.preset.name;
			search.savedSearchID = this.preset.savedSearchID;
			search.userID = this.preset.userID;
			search.type = this.preset.type;
		}

		Object.keys(search.filters).forEach(key => {
			if (!search.filters[key]) {
				delete search.filters[key];
			}
		});

		this.apply.emit({search, saveChanges: this.saveChanges});
	}

	public openYesNoDialog(): Promise<string> {
		return new Promise<string>((resolve: any): void => {
			this.yesNoDialog.open(YesNoDialogComponent, {
				data: {
					title: 'Search Presets',
					question: `Would you like to save changes to ${this.preset.name}?`,
					allowCancel: true,
				},
			}).afterClosed().subscribe((result: string) => {
				if (result === 'Yes') {
					this.saveChanges = true;
				}

				if (result === 'Cancel') {
					return;
				}

				resolve(result);
			});
		});
	}

	public ngOnInit(): void {
		this.valuesService.getValues().subscribe((values: ValuesModel) => {
			this.values = values;
		});

		this.nameInput.nativeElement.focus();

		this.searchForm = this.fb.group({
			name: [],
			keywords: [],
			code: [],
			propertyDescription: [],
			ownerName: [],
			statusId: [],
			financialClass1Id: [],
			financialClass2Id: [],
			category1Id: [],
			category2Id: [],
			countyId: [],
			cityId: [],
			roadId: [],
			agent1Id: [],
			agent2Id: [],
			minPrice: [],
			maxPrice: [],
			minSize: [],
			maxSize: [],

			closingPriceMin: [],
			closingPriceMax: [],
			closingLeaseRateMin: [],
			closingLeaseRateMax: [],
			closingLeaseRateUnitId: [],
			closingLeaseTypeId: [],

			listFromRelation: [],
			listFromValue: [],
			listToRelation: [],
			listToValue: [],

			expirationFromRelation: [],
			expirationFromValue: [],
			expirationToRelation: [],
			expirationToValue: [],

			closedFromRelation: [],
			closedFromValue: [],
			closedToRelation: [],
			closedToValue: [],

			salesPresentationFromRelation: [],
			salesPresentationFromValue: [],
			salesPresentationToRelation: [],
			salesPresentationToValue: [],
		});

		if (this.preset) {
			this.searchForm.patchValue(Object.assign({}, this.preset.filters));
		}

		if (this.filters && !this.preset) {
			this.searchForm.patchValue(Object.assign({}, this.filters));
		}
	}

	public ngOnChanges(): void {
		if (this.searchForm) {
			if (this.filters && !this.preset) {
				this.searchForm.setValue(Object.assign({}, this.filters));
			}

			if (this.preset) {
				this.searchForm.setValue(Object.assign({}, this.preset.filters));
			}
		}
	}

	public codeChanged() {
		const code = this.searchForm.get('code').value.toUpperCase();
		this.searchForm.patchValue(Object.assign({}, {code}));
	}
}

import { Injectable } from '@angular/core';
import { PropertyService } from '../module/property/service/property.service';

@Injectable()
export class IconService {

	public getOutboundCallIcon() {
		return `
		  <span class="fa-layers outbound-call-icon icon">
				<i class="fas fa-arrow-alt-up" data-fa-transform="shrink-4 rotate-45 up-7 right-9"></i>
		    <i class="fas fa-phone fa-flip-horizontal" data-fa-transform="grow-4 right-2"></i>
		  </span>
		`;
	}

	public getInboundCallIcon() {
		return `
		  <span class="fa-layers inbound-call-icon icon">
				<i class="fas fa-arrow-alt-down" data-fa-transform="shrink-4 rotate-45 up-7 right-9"></i>
		    <i class="fas fa-phone fa-flip-horizontal" data-fa-transform="grow-4 right-2"></i>
		  </span>
		`;
	}

	public getCheckmarkIcon() {
		return `<i class="far fa-check check-icon icon"></i>`;
	}

	public getEmailIcon() {
		return `
		  <span class="fa-layers send-email-icon icon">
		    <i class="fas fa-envelope" data-fa-transform="grow-4 right-2"></i>
		    <i class="fas fa-circle" data-fa-transform="shrink-4 left-4 down-6"></i>
		    <i class="fas fa-check-circle" data-fa-transform="shrink-4 left-4 down-6"></i>
		  </span>
		`;
	}

	public getDoNotEmailIcon() {
		return `
		  <span class="fa-layers do-not-send-email-icon icon" title="Opted out of receiving emails">
		    <i class="fas fa-envelope" data-fa-transform="grow-4 right-2"></i>
		    <i class="fas fa-circle" data-fa-transform="shrink-4 left-4 down-6"></i>
		    <i class="fas fa-times-circle" data-fa-transform="shrink-4 left-4 down-6"></i>
		  </span>
		`;
	}

	public getNoEmailAddressIcon() {
		return `
		  <span class="fa-layers do-not-send-email-icon icon" title="No email address on file">
		    <i class="fas fa-envelope" data-fa-transform="grow-4 right-2"></i>
		    <i class="fas fa-circle" data-fa-transform="shrink-4 left-4 down-6"></i>
		    <i class="fas fa-exclamation-circle" data-fa-transform="shrink-4 left-4 down-6"></i>
		  </span>
		`;
	}

	public getProspectStatusIcon(status: string) {
		let colorClass: string = '';
		let iconClass: string = 'fa-exclamation-circle';

		if (status.trim().toUpperCase() !== 'DELETED') {
			colorClass = 'active';
			iconClass = 'fa-check-circle';
		}

		return `
			<span class="${colorClass}">
				${status.toUpperCase()}&nbsp;
				<i class="far ${iconClass}"></i>
			</span>
		`;
	}

	public getPropertyStatusIcon(status: string) {
		let colorClass: string = '';
		let iconClass: string = 'fa-exclamation-circle';

		if (PropertyService.ACTIVE_STATUS_VALUES.includes(status.trim().toUpperCase())) {
			colorClass = 'active';
			iconClass = 'fa-check-circle';
		}

		return `
			<span class="${colorClass}">
				${status.toUpperCase()}&nbsp;
				<i class="far ${iconClass}"></i>
			</span>
		`;
	}
}

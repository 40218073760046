import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavModel } from '../../../../../model/nav.model';

@Component({
	selector: 'navbar',
	templateUrl: './navbar.component.pug',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

	@Input() public currentSection = 'details';
	@Input() public createMode: boolean;

	@Input('canHaveLeaseSpaces')
	set canHaveLeaseSpaces(canHaveLeaseSpaces: boolean) {
		this._canHaveLeaseSpaces = canHaveLeaseSpaces;
		this.ngOnInit();
	}

	get canHaveLeaseSpaces(): boolean {
		return this._canHaveLeaseSpaces;
	}
	protected _canHaveLeaseSpaces: boolean;

	@Output() protected linkClick: EventEmitter<string> = new EventEmitter();

	public links: NavModel[];

	public ngOnInit(): void {
		this.links = this.getLinks();

		if (this.createMode) {
			this.links = this.links.filter(link => ['mailings', 'checklist'].indexOf(link.section) === -1);
			this.links.splice(7, 0, {label: 'LEASE SPACES', section: 'lease-spaces', iconClass: 'fa-home'});
		} else {
			if (this.canHaveLeaseSpaces) {
				this.links.splice(8, 0, {label: 'LEASE SPACES', section: 'lease-spaces', iconClass: 'fa-home'});
			}
		}
	}

	public getLinks(): NavModel[] {
		return [
			{label: 'DETAILS', section: 'details', iconClass: 'fa-info-circle'},
			{label: 'LOCATION', section: 'location', iconClass: 'fa-map-marker-alt'},
			{label: 'CLOSING', section: 'closing', iconClass: 'fa-university'},
			{label: 'PHOTOS', section: 'photos', iconClass: 'fa-image'},
			{label: 'SELLER', section: 'seller', iconClass: 'fa-user'},
			{label: 'HIGHLIGHTS', section: 'highlights', iconClass: 'fa-chart-line'},
			{label: 'SHOWN', section: 'shown', iconClass: 'fa-external-link-square-alt'},
			{label: 'MAILINGS', section: 'mailings', iconClass: 'fa-envelope'},
			{label: 'TASKS', section: 'tasks', iconClass: 'fa-tasks'},
			{label: 'ATTRIBUTES', section: 'attributes', iconClass: 'fa-pencil'},
			{label: 'PROSPECT MATCHES', section: 'prospect-matches', iconClass: 'fa-heart', color: 'warn'},
		];
	}

	public goTo(fragment): void {
		this.linkClick.emit(fragment);
	}

}

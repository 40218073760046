import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
	templateUrl: './team-member-dialog.component.pug',
	styleUrls: ['./team-member-dialog.component.scss'],
})

export class TeamMemberDialogComponent {

	public teamMember: FormGroup = this.fb.group({
		members: '',
	});

	constructor(
		public dialogRef: MatDialogRef<TeamMemberDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		protected fb: FormBuilder) {}

	public addTeamMembers(): void {
		this.dialogRef.close(this.teamMember.getRawValue());
	}

}

import {PaginationDto} from '@dvp/is2-shared';
import {Observable} from 'rxjs/Rx';
import {MatPaginator, MatSort} from '@angular/material';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {DataSource} from '@angular/cdk/collections';

export class PaginationDatasource<T> extends DataSource<T> {

	subject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
	pager: PaginationDto<T> = {currentPage: 0, pageSize: 10, totalItems: 0, data: []};

	constructor(private getDataFunction: () => Observable<PaginationDto<T>>,
	            private paginator: MatPaginator,
	            private sort?: MatSort,
	            private observables?: Observable<any>[]) {
		super();
	}

	private getData(): void {
		this.getDataFunction().subscribe((page: PaginationDto<T>) => {
			this.subject.next(page.data);
			this.pager = page;
		});
	}

	connect(): Observable<T[]> {
		let displayedChanges: Observable<any>[] = this.paginator ? [this.paginator.page] : [];
		if (this.sort) {
			displayedChanges.push(this.sort.sortChange);
		}
		if (this.observables) {
			displayedChanges = displayedChanges.concat(this.observables);
		}

		Observable.merge(...displayedChanges).subscribe((d) => {
			if (d && d['active'] && d['direction']) {
				this.paginator.pageIndex = 0;
			}

			this.getData();
		});

		if (!this.subject.isStopped) {
			this.getData();
		}

		return Observable.merge(this.subject);
	}

	disconnect(): void {
		this.subject.complete();
		this.subject.observers = [];
	}
}

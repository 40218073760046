import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'duration'
})

export class DurationFormatPipe implements PipeTransform {
	transform(duration: number): string {
		return moment.duration(duration, 'seconds').humanize();
	}
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ANIMATION_TYPES, LoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { UserGuard } from './guard/user.guard';
import { NavigationService } from './service/navigation.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from './service/user.service';
import {
	MatButtonModule,
	MatCardModule,
	MatDatepickerModule,
	MatDialogModule,
	MatExpansionModule,
	MatFormFieldModule,
	MatGridListModule,
	MatIconModule,
	MatInputModule,
	MatMenuModule,
	MatNativeDateModule,
	MatProgressBarModule,
	MatSidenavModule,
	MatTableModule,
	MatTabsModule,
	MatToolbarModule,
	MatSlideToggleModule,
	MatListModule,
	MatTooltipModule,
	MatRippleModule,
	MatSelectModule,
	MatPaginatorModule,
	MatSortModule,
	MatChipsModule,
	MatCheckboxModule,
	MatRadioModule,
	MatBadgeModule,
	MatStepperModule,
} from '@angular/material';
import { StartupService } from './service/startup.service';
import { ShutdownService } from './service/shutdown.service';
import { ContainerComponent } from './component/container/container.component';
import { ChartModule } from 'angular2-highcharts';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgProgressModule } from 'ngx-progressbar';
import { RouterService } from './service/router.service';
import { DpDatePickerModule } from 'ng2-date-picker';
import { MonthRangePickerComponent } from './component/month-range-picker/month-range-picker.component';
import { DateRangePickerComponent } from './component/date-range-picker/date-range-picker.component';
import { TextMaskModule } from 'angular2-text-mask';
import { AgmCoreModule } from '@agm/core';
import { FileUploadModule } from 'ng2-file-upload';
import { ScrollSpyIndexModule, ScrollSpyModule } from 'ngx-scrollspy';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AdminGuard } from './guard/admin.guard';
import { ValuesService } from './service/values.service';
import { DvpTableComponent } from './component/dvp-table/dvp-table.component';
import { DurationFormatPipe } from './pipe/duration-format.pipe';
import { PhoneNumberFormatPipe } from './pipe/phone-number-format.pipe';
import { DeleteDialogComponent } from './component/deleteDialog/delete-dialog.component';
import { CategoryOptionsPipe } from './pipe/category-options.pipe';
import { DialService } from './service/dial.service';
import { ExpansionContainerComponent } from './component/expansion-container/expansion-container.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { EnableControlDirective } from './directives/enable-control.directive';
import { NumberInputDirective } from './directives/number-mask.directive';
import { PhoneInputDirective } from './directives/phone-mask.directive';
import { ActionButtonComponent } from './component/action-button/action-button.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { SortablejsModule } from 'angular-sortablejs';
import { UnsavedChangesDialogComponent } from './component/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { CanDeactivateGuard } from './guard/can-deactivate.guard';
import { YesNoDialogComponent } from './component/yes-no-dialog/yes-no-dialog.component';
import { PromptDialogComponent } from './component/prompt-dialog/prompt-dialog.component';
import { MailingsComponent } from './component/mailings/mailings.component';
import { MailingDialogComponent } from './component/mailing-dialog/mailing-dialog.component';
import { ShownComponent } from './component/shown/shown.component';
import { ShownDialogComponent } from './component/shown-dialog/shown-dialog.component';
import { AddImagesComponent } from './component/add-images/add-images.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropImageDialogComponent } from './component/crop-image-dialog/crop-image-dialog.component';
import { UploaderService } from './service/uploader.service';
import { PhoneButtonComponent } from './component/phone-button/phone-button.component';
import { TableComponent } from './component/table/table.component';
import { SlackService } from './service/slack.service';
import { RichTextEditorComponent } from './component/rich-text-editor/rich-text-editor.component';
import { FormFieldComponent } from './component/form-field/form-field.component';
import { RelativeTimeComponent } from './component/relative-time/relative-time.component';
import { SavedSearchService } from './service/saved-search.service';
import { NguCarouselModule } from '@ngu/carousel';
import { ImageSelectorComponent } from './component/image-selector/image-selector.component';
import { ImageCarouselComponent } from './component/image-carousel/image-carousel.component';
import { EmailService } from './service/email.service';
import { IconService } from './service/icon.service';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { PropertySearchComponent } from './component/property-search/property-search.component';
import { PropertyAdvancedSearchComponent } from './component/property-advanced-search/property-advanced-search.component';
import { PropertySearchDialogComponent } from './component/property-search-dialog/property-search-dialog.component';
import { ProspectSearchComponent } from './component/prospect-search/prospect-search.component';
import { ProspectAdvancedSearchComponent } from './component/prospect-advanced-search/prospect-advanced-search.component';
import { ProspectSavedSearchComponent } from './component/prospect-saved-search/prospect-saved-search.component';
import { ProspectListComponent } from './component/prospect-list/prospect-list.component';
import { ProspectPaginationListComponent } from './component/prospect-pagination-list/prospect-pagination-list.component';
import { ProspectSearchDialogComponent } from './component/prospect-search-dialog/prospect-search-dialog.component';
import { MatchMakerComponent } from './component/matchmaker/matchmaker.component';
import { MatchmakerDialogComponent } from './component/matchmaker-dialog/matchmaker-dialog.component';
import { TableRowAnchorComponent } from './component/table-row-anchor/table-row-anchor.component';
import { ShownService } from './service/shown.service';
import { MatchmakerService } from './service/matchmaker.service';
import { MapDialogComponent } from './component/map-dialog/map-dialog.component';
import { PropertyListComponent } from './component/property-list/property-list.component';
import { PropertyPaginationListComponent } from './component/property-pagination-list/property-pagination-list.component';
import { PropertyMapComponent } from './module/property/component/search-map/property-map/property-map.component';
import { TaskService } from './service/task.service';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { TaskListComponent } from './component/task-list/task-list.component';
import { TaskPaginationListComponent } from './component/task-pagination-list/task-pagination-list.component';
import { TaskDialogComponent } from './component/task-dialog/task-dialog.component';
import { TasksComponent } from './module/property/component/property-edit/tasks/tasks.component';
import { TaskGroupListComponent } from './component/task-group-list/task-group-list.component';
import { TaskGroupDialogComponent } from './component/task-group-dialog/task-group-dialog.component';
import { CoreMapContentComponent } from './module/property/component/search-map/core-map-content/core-map-content.component';
import { BulkEditProspectsDialogComponent } from './component/bulk-edit-prospects-dialog/bulk-edit-prospects-dialog.component';
import { CallPopComponent } from './component/call-pop/call-pop.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { EditOptionDialogComponent } from './component/edit-option-dialog/edit-option-dialog.component';
import { PrintHeaderComponent } from './component/print-header/print-header.component';
import { VirtualScrollTableComponent } from './component/virtual-scroll-table/virtual-scroll-table.component';
import { PrintTableComponent } from './component/print-table/print-table.component';
import { MobileTableComponent } from './component/mobile-table/mobile-table.component';
import { PrintColumnSelectionDialogComponent } from './component/print-column-selection-dialog/print-column-selection-dialog.component';
import { SearchPresetDialogComponent } from './component/search-preset-dialog/search-preset-dialog.component';

library.add(far, fas);
dom.watch();

declare const require: any;

const Highcharts = require('highcharts');
Highcharts.setOptions({lang: {thousandsSep: ','}});

export function highchartsFactory() {
	return require('highcharts');
}

export enum COLORS {
	PRIMARY = '#026E84',
	ACCENT = '#A5C6CE',
}

@NgModule({
	imports: [
		CommonModule,
		LoadingModule.forRoot({
			animationType: ANIMATION_TYPES.threeBounce,
			primaryColour: '#efefef',
			secondaryColour: COLORS.ACCENT,
			tertiaryColour: COLORS.PRIMARY,
		}),
		ToastrModule.forRoot({
			timeOut: 8000,
		}),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyBT94Kubr2751ycCfiS7G8zzWSAYAUBRvo',
			libraries: ['places'],
		}),
		ScrollSpyModule.forRoot(),
		ScrollSpyIndexModule,
		ChartModule,
		FormsModule,
		ReactiveFormsModule,
		MatCardModule,
		MatBadgeModule,
		MatButtonModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatNativeDateModule,
		MatSidenavModule,
		MatToolbarModule,
		MatGridListModule,
		MatDialogModule,
		MatTabsModule,
		MatTableModule,
		MatProgressBarModule,
		MatListModule,
		FlexLayoutModule,
		DpDatePickerModule,
		FileUploadModule,
		AgmJsMarkerClustererModule,
		MatSlideToggleModule,
		MatTooltipModule,
		MatRippleModule,
		MatSelectModule,
		MatPaginatorModule,
		MatSortModule,
		MatChipsModule,
		MatCheckboxModule,
		MatRadioModule,
		CKEditorModule,
		SortablejsModule.forRoot({}),
		ImageCropperModule,
		MatStepperModule,
		NguCarouselModule,
		TextMaskModule,
		RouterModule,
		ScrollingModule,
	],
	declarations: [
		ContainerComponent,
		ExpansionContainerComponent,
		MonthRangePickerComponent,
		DateRangePickerComponent,
		DvpTableComponent,
		DurationFormatPipe,
		PhoneNumberFormatPipe,
		DeleteDialogComponent,
		UnsavedChangesDialogComponent,
		YesNoDialogComponent,
		PromptDialogComponent,
		MailingsComponent,
		MailingDialogComponent,
		ShownComponent,
		ShownDialogComponent,
		CategoryOptionsPipe,
		DisableControlDirective,
		EnableControlDirective,
		NumberInputDirective,
		PhoneInputDirective,
		ActionButtonComponent,
		AddImagesComponent,
		CropImageDialogComponent,
		PhoneButtonComponent,
		TableComponent,
		RichTextEditorComponent,
		FormFieldComponent,
		RelativeTimeComponent,
		ImageSelectorComponent,
		ImageCarouselComponent,
		SafeHtmlPipe,
		TableRowAnchorComponent,
		PropertySearchComponent,
		PropertyAdvancedSearchComponent,
		PropertySearchDialogComponent,
		ProspectSearchComponent,
		ProspectAdvancedSearchComponent,
		ProspectSavedSearchComponent,
		ProspectListComponent,
		ProspectPaginationListComponent,
		ProspectSearchDialogComponent,
		MatchMakerComponent,
		MapDialogComponent,
		MatchmakerDialogComponent,
		PropertyListComponent,
		PropertyPaginationListComponent,
		TaskListComponent,
		TaskPaginationListComponent,
		TaskDialogComponent,
		TasksComponent,
		TaskGroupListComponent,
		TaskGroupDialogComponent,
		PropertyMapComponent,
		CoreMapContentComponent,
		BulkEditProspectsDialogComponent,
		CallPopComponent,
		EditOptionDialogComponent,
		PrintHeaderComponent,
		VirtualScrollTableComponent,
		PrintTableComponent,
		MobileTableComponent,
		PrintColumnSelectionDialogComponent,
		SearchPresetDialogComponent,
	],
	entryComponents: [
		DeleteDialogComponent,
		UnsavedChangesDialogComponent,
		YesNoDialogComponent,
		PromptDialogComponent,
		MailingDialogComponent,
		ShownDialogComponent,
		CropImageDialogComponent,
		PropertySearchDialogComponent,
		ProspectSearchDialogComponent,
		MapDialogComponent,
		MatchmakerDialogComponent,
		TaskDialogComponent,
		TaskGroupDialogComponent,
		BulkEditProspectsDialogComponent,
		EditOptionDialogComponent,
		PrintColumnSelectionDialogComponent,
		SearchPresetDialogComponent,
	],
	exports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		LoadingModule,
		ToastrModule,
		FormsModule,
		ReactiveFormsModule,
		DpDatePickerModule,
		ChartModule,
		MatCardModule,
		MatBadgeModule,
		MatButtonModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatNativeDateModule,
		MatSidenavModule,
		MatToolbarModule,
		MatTabsModule,
		MatTableModule,
		MatGridListModule,
		MatDialogModule,
		MatProgressBarModule,
		MatListModule,
		FlexLayoutModule,
		ContainerComponent,
		ExpansionContainerComponent,
		MonthRangePickerComponent,
		DateRangePickerComponent,
		DvpTableComponent,
		NgProgressModule,
		TextMaskModule,
		AgmCoreModule,
		FileUploadModule,
		ScrollSpyModule,
		ScrollSpyIndexModule,
		AgmJsMarkerClustererModule,
		MatSlideToggleModule,
		MatTooltipModule,
		MatRippleModule,
		MatSelectModule,
		MatPaginatorModule,
		MatSortModule,
		MatChipsModule,
		DeleteDialogComponent,
		UnsavedChangesDialogComponent,
		YesNoDialogComponent,
		PromptDialogComponent,
		MailingsComponent,
		MailingDialogComponent,
		ShownComponent,
		ShownDialogComponent,
		MatCheckboxModule,
		MatRadioModule,
		CategoryOptionsPipe,
		DisableControlDirective,
		EnableControlDirective,
		NumberInputDirective,
		PhoneInputDirective,
		ActionButtonComponent,
		CKEditorModule,
		SortablejsModule,
		PhoneNumberFormatPipe,
		AddImagesComponent,
		ImageCropperModule,
		CropImageDialogComponent,
		PhoneButtonComponent,
		TableComponent,
		RichTextEditorComponent,
		FormFieldComponent,
		RelativeTimeComponent,
		MatStepperModule,
		NguCarouselModule,
		ImageSelectorComponent,
		ImageCarouselComponent,
		SafeHtmlPipe,
		TableRowAnchorComponent,
		PropertySearchComponent,
		PropertyAdvancedSearchComponent,
		PropertySearchDialogComponent,
		ProspectSearchComponent,
		ProspectAdvancedSearchComponent,
		ProspectSavedSearchComponent,
		ProspectListComponent,
		ProspectPaginationListComponent,
		ProspectSearchDialogComponent,
		MatchMakerComponent,
		MapDialogComponent,
		MatchmakerDialogComponent,
		PropertyListComponent,
		PropertyPaginationListComponent,
		TaskListComponent,
		TaskPaginationListComponent,
		TaskDialogComponent,
		TasksComponent,
		TaskGroupListComponent,
		TaskGroupDialogComponent,
		PropertyMapComponent,
		CoreMapContentComponent,
		BulkEditProspectsDialogComponent,
		CallPopComponent,
		ScrollingModule,
		EditOptionDialogComponent,
		PrintHeaderComponent,
		VirtualScrollTableComponent,
		PrintTableComponent,
		MobileTableComponent,
		PrintColumnSelectionDialogComponent,
		SearchPresetDialogComponent,
	],
})
export class SharedModule {

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharedModule,
			providers: [
				RouterService,
				UserService,
				UserGuard,
				ValuesService,
				AdminGuard,
				CanDeactivateGuard,
				NavigationService,
				StartupService,
				ShutdownService,
				DialService,
				UploaderService,
				SlackService,
				SavedSearchService,
				EmailService,
				IconService,
				ShownService,
				MatchmakerService,
				TaskService,
				{
					provide: HighchartsStatic,
					useFactory: highchartsFactory,
				},
			],
		};
	}

}

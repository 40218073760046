import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelectChange } from '@angular/material';
import { DeleteExplanationDialogDataInterface } from '../../interface/delete-explanation-dialog-data.interface';
import { PaginationDto, ProspectRemarkModel } from '@dvp/is2-shared';
import { ProspectRemarkService } from '../../service/prospect-remark.service';
import { StoredSearchInterface } from '../../../../interface/stored-search.interface';
import { ProspectListModel, ProspectSearchDto } from '@dvp/is2-shared';
import { ProspectSelectionEventInterface } from '../../../../interface/prospect-selection-event.interface';
import { UserService } from '../../../../service/user.service';
import { ProspectSearchService } from '../../service/prospect-search.service';
import { Observable } from 'rxjs';

@Component({
	templateUrl: './delete-explanation-dialog.component.pug',
	styleUrls: ['./delete-explanation-dialog.component.scss'],
})
export class DeleteExplanationDialogComponent implements OnInit {

	public selectedOption: string;
	public remark: string;
	public loading: boolean = false;
	public bulkEdit: boolean = false;
	public selectedDuplicates: ProspectListModel[] = [];
	public searchDto: Partial<ProspectSearchDto>;
	public initialSearch: StoredSearchInterface<Partial<ProspectSearchDto>, ProspectListModel>;

	@Input() protected remarkService: ProspectRemarkService;

	@ViewChild('remarkInput', {static: false}) public remarkInput: ElementRef;

	constructor(
		public dialogRef: MatDialogRef<DeleteExplanationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DeleteExplanationDialogDataInterface,
		protected userService: UserService,
		protected prospectSearchService: ProspectSearchService,
	) {}

	public ngOnInit(): void {
		if (!this.data.prospect) {
			this.bulkEdit = true;
			this.selectedOption = 'Other';
			setTimeout(() => this.remarkInput.nativeElement.focus(), 500);
		}
	}

	public reasonChanged(event: MatSelectChange): void {
		if (event.value === 'Duplicate') {
			this.loading = true;
			this.duplicateSearch();
		} else {
			this.remark = null;
		}
	}

	public duplicateSearch = (): void | Observable<PaginationDto<ProspectListModel>> => {
		this.searchDto = {
			details: {},
		};

		if (this.data.prospect.details.firstName) {
			this.searchDto.details.firstName = this.data.prospect.details.firstName.trim();
		}

		if (this.data.prospect.details.lastName) {
			this.searchDto.details.lastName = this.data.prospect.details.lastName.trim();
		}

		if (this.data.prospect.details.company) {
			this.searchDto.details.company = this.data.prospect.details.company.trim();
		}

		if (Object.keys(this.searchDto.details).length === 0) {
			this.initialSearch = {
				dto: this.searchDto,
				results: {
					currentPage: 0,
					pageSize: 0,
					totalItems: 0,
					data: []},
			};
		}

		this.prospectSearchService.checkDuplicates(this.searchDto, this.data.prospect.id, 0, 0).subscribe((res) => {
			this.initialSearch = {
				dto: this.searchDto,
				results: res,
			};
			this.loading = false;
		});
	};

	public selected(selection: ProspectSelectionEventInterface): void {
		if (selection.selected) {
			this.selectedDuplicates.push(selection.prospect);
		} else {
			const index: number = this.selectedDuplicates.findIndex(prospect => prospect.id === selection.prospect.id);
			this.selectedDuplicates.splice(index, 1);
		}

		if (this.selectedDuplicates.length > 0) {
			this.remark = 'duplicate';
		} else {
			this.remark = null;
		}
	}

	public confirm(): void {
		this.data.remarks = [];
		let comment: string;

		if (this.bulkEdit) {
			comment = `Bulk Edit Delete - ${this.remark}`;
			this.data.prospects.forEach((prospect) => {
				this.createRemarks(comment, prospect.id);
			});
		}

		if (this.selectedOption === 'Other' && !this.bulkEdit) {
			comment = `Deleted - ${this.remark}`;
			this.createRemarks(comment);
		}

		if (this.selectedOption === 'Duplicate') {
			const codes: string[] = this.selectedDuplicates.map(prospect => prospect.code);
			const newCodes = codes.join(', ');

			comment = `This was a duplicate and is deleted in favor of ${newCodes}.`;
			this.createRemarks(comment);

			this.selectedDuplicates.forEach(duplicate => {
				comment = `${this.data.prospect.details.code} was a duplicate of this prospect and was deleted.`;
				this.createRemarks(comment, duplicate.id);
			});

		}

		this.dialogRef.close(this.data);
	}

	public createRemarks(comment: string, prospectId?: number): void {
		this.data.remarks.push(new ProspectRemarkModel({
			date: new Date(),
			userId: this.userService.getUser().userId,
			user: this.data.user,
			prospectId: prospectId || this.data.prospect.id,
			comments: comment,
		}));
	}

}


import {Pipe, PipeTransform} from '@angular/core';
import {UserListModel} from '@dvp/is2-shared';
import {LocationCodeModel} from '@dvp/is2-shared/dist/model/location-code.model';
import {ProspectTypeCodeModel} from '@dvp/is2-shared/dist/model/prospect-type-code.model';

@Pipe({
	name: 'prospectFilter'
})

export class ProspectFilterPipe implements PipeTransform {
	transform(prospects: ProspectTypeCodeModel[], searchText: string): ProspectTypeCodeModel[] {
		if (!prospects) {
			return [];
		}

		if (!searchText) {
			return prospects;
		}

		searchText = searchText.toLowerCase();

		return prospects.filter((type: ProspectTypeCodeModel) => {
			const {code, description} = type;

			return (this.hasMatch(code, searchText) || this.hasMatch(description, searchText));
		});
	}

	private hasMatch(value: string, searchText) {
		return value ? value.toLowerCase().includes(searchText) : false;
	}

}

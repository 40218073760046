import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	templateUrl: './edit-option-dialog.component.pug',
	styleUrls: ['./edit-option-dialog.component.scss'],
})

export class EditOptionDialogComponent {

	public edit: boolean = false;

	constructor(public dialogRef: MatDialogRef<EditOptionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
		if (this.data === 'Edit') {
			this.edit = true;
		}
	}

}

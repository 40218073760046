import {Component, EventEmitter, Input, Output} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {ModalButtonInterface} from './modal-button.interface';
import {ModalTriggeredInterface} from './modal-triggered.interface';


@Component({
	selector: 'app-code-modal',
	templateUrl: './code-modal.component.pug',
	styleUrls: ['./code-modal.component.scss'],
	animations: [
		trigger(
			'modalAnimation', [
				transition(':enter', [
					style({transform: 'translate(-50%, -50%)', top: '-50%', opacity: 0}),
					animate('300ms ease-in')
				]),
				transition(':leave', [
					animate('300ms ease-in', style({transform: 'translate(-50%, -50%)', top: '-50%', opacity: 0}))
				])
			]
		),

		trigger(
			'backdropAnimation', [
				transition(':enter', [
					style({opacity: 0}),
					animate('300ms ease-in')
				]),
				transition(':leave', [
					style({opacity: .7}),
					animate('300ms ease-in', style({ opacity: 0 }))
				])
			]
		)
	]
})
export class CodeModalComponent {

	public containerTitle = '';
	public visible = false;

	@Input() actionButtons: ModalButtonInterface[] = [];
	@Output() triggered: EventEmitter<ModalTriggeredInterface> = new EventEmitter();
	@Output() closed: EventEmitter<void> = new EventEmitter();


	@Input()
	set title(title) {
		this.containerTitle = title;
	}

	public show(): void {
		this.visible = true;
	}

	public close() {
		this.visible = false;
		this.closed.emit();
	}

	protected actionButtonTrigger(actionButton: ModalButtonInterface) {
		if (actionButton.close) {
			this.close();
		} else {
			this.triggered.emit({
				id: actionButton.id,
				modal: this // ? do we need this?
			});
		}
	}

}

import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[enableControl]',
})
export class EnableControlDirective {

	@Input() set enableControl(condition: boolean) {
		const action = condition ? 'enable' : 'disable';
		this.ngControl.control[action]();
	}

	constructor(private ngControl: NgControl) {}

}

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'unsaved-changes-dialog',
    templateUrl: './unsaved-changes-dialog.component.pug',
})
export class UnsavedChangesDialogComponent {

    constructor(public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    }
}

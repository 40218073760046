import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { NavModel } from '../model/nav.model';

@Pipe({
	name: 'navigationAccessible',
})
@Injectable()
export class NavigationAccessiblePipe implements PipeTransform {

	public transform(items: NavModel[]): NavModel[] {
		if (!items) {
			return [];
		}
		return items.filter((nav: NavModel) => {
			if (!nav.accessible) {
				return true;
			}
			return nav.accessible();
		});
	}

}

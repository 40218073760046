import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	templateUrl: './costar-email-dialog.component.pug',
	styleUrls: ['./costar-email-dialog.component.scss'],
})
export class CostarEmailDialogComponent {

	public data: CostarEmailDialogDataInterface;

	constructor(public dialogRef: MatDialogRef<CostarEmailDialogComponent>, @Inject(MAT_DIALOG_DATA) data: CostarEmailDialogDataInterface) {
		this.data = data;
	}

	public sendEmailToCostar() {
		this.dialogRef.close(this.data);
	}

}

export interface CostarEmailDialogDataInterface {
	propertyName: string;
	url: string;
	userEmail: string;
	userText: string;
}

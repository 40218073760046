import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDatePickerConfig } from 'ng2-date-picker';

@Component({
	selector: 'dvp-date-range-picker',
	templateUrl: './date-range-picker.component.pug',
	styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent {

	@Input()
	public dateRangeFormGroup: FormGroup;

	public startDateConfig: IDatePickerConfig = {
		format: 'MM/DD/YYYY',
		openOnFocus: false,
	};

	public endDateConfig: IDatePickerConfig = {
		format: 'MM/DD/YYYY',
		openOnFocus: false,
	};

}

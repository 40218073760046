
import {EventEmitter, Injectable} from '@angular/core';
import { NavigationEnd, NavigationExtras, Router, UrlTree } from '@angular/router';

/**
 * We use a route service to perform routing so we can listen for events that angular does not give us
 *
 * Specifically navigate events do not fire while a route is chaning between ResolveStart and ResolveEnd,
 * this provides a listener so a resolver knows to terminate.
 */
@Injectable()
export class RouterService {

	protected previousUrl: string;
	protected currentUrl: string;
	protected navigateListener = new EventEmitter<any>();

	constructor(protected router: Router) {
		this.currentUrl = this.router.url;
		router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;
			}
		});
	}

	public getPreviousUrl(): string {
		return this.previousUrl;
	}

	public subscribe(generatorOrNext?: any, error?: any, complete?: any) {
		return this.navigateListener.subscribe(generatorOrNext, error, complete);
	}

	public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
		this.navigateListener.next();
		return this.router.navigate(commands, extras);
	}

	public navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
		this.navigateListener.next();
		return this.router.navigateByUrl(url, extras);
	}

	/**
	 * Used to let navigation route listeners know something happened
	 */
	public next(): void {
		this.navigateListener.next();
	}

}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ValuesService} from '../../../../service/values.service';
import {CodeModalComponent} from '../code-modal/code-modal.component';
import {CodesService} from '../../service/codes.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ModalButtonInterface} from '../code-modal/modal-button.interface';
import {DeleteDialogComponent} from '../../../../component/deleteDialog/delete-dialog.component';
import {MatDialog} from '@angular/material';
import {HttpErrorResponse} from '@angular/common/http';
import {CallSourceCodeModel} from '@dvp/is2-shared/dist/model/call-source-code.model';
import {Subscription} from 'rxjs/Subscription';

@Component({
	selector: 'call-source-codes',
	templateUrl: './call-source-codes.component.pug',
	styleUrls: ['./call-source-codes.component.scss'],
})
export class CallSourceCodesComponent implements OnInit, OnDestroy {

	public searchText: string = null;
	public loading = false;

	public modalSource: FormGroup;
	public modalTitle: string;

	private pdfSubscription: Subscription;

	@ViewChild('modal', {static: true}) modal: CodeModalComponent;

	public callSources: CallSourceCodeModel[];
	public actionButtons: ModalButtonInterface[];

	constructor(private fb: FormBuilder,
							private codesService: CodesService,
							private toastrService: ToastrService,
							private valuesService: ValuesService,
							private deleteDialog: MatDialog) {}

	ngOnInit() {
		this.loading = true;
		this.codesService.getCallSources()
			.subscribe((values) => {
				this.callSources = values;
				this.loading = false;
			}, (error) => {
				this.toastrService.error(error);
				this.loading = false;
			});
	}

	ngOnDestroy() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}
	}

	public openModal() {
		this.modal.show();
	}

	public editSource(type: any) {
		this.modalTitle = 'Edit Call Source';
		this.modalSource = this.fb.group({
			id: type.id,
			code: [type.code, [Validators.required, Validators.maxLength(3)]],
			description: type.description
		});

		this.actionButtons = [
			{id: 'cancel', title: 'Cancel', close: true},
			{id: 'delete', title: 'Delete', color: 'warn'},
			{id: 'save', title: 'Save', color: 'primary'}
		];
		this.openModal();
	}

	public modalAction(event) {
		if (this.modalSource.valid) {
			if (event.id === 'save') {
				this.loading = true;
				const source = this.modalSource.value;
				if (source.id) {
					this.codesService.saveCallSource(source)
						.subscribe((result) => {
							this.toastrService.success(`${source.code} saved.`);
							this.modal.close();
							this.ngOnInit();
							this.loading = false;
						}, (error) => {
							this.toastrService.error(error);
							this.loading = false;
						});
				} else {
					this.codesService.createCallSource(source)
						.subscribe((result) => {
							this.toastrService.success(`${source.code} created.`);
							this.modal.close();
							this.ngOnInit();
							this.loading = false;
						}, (error) => {
							this.toastrService.error(error);
							this.loading = false;
						});
				}
			} else if (event.id === 'delete') {
				this.deleteSource(this.modalSource.value);
			}
		}
	}

	public deleteSource(source: CallSourceCodeModel) {
		const deleteDialog = this.deleteDialog.open(DeleteDialogComponent, {
			width: '400px',
			data: {
				text: source.description,
				type: 'Call Source'
			}
		});
		deleteDialog.afterClosed().subscribe((result) => {
			if (result === 'Delete') {
				this.codesService.deleteCallSource(source.id)
					.subscribe(() => {
							this.toastrService.success(`${source.code} has been deleted.`);
							this.modal.close();
							this.ngOnInit();
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						}
					);
			} else {
				this.modal.close();
			}
		});
	}

	public addSource() {
		this.modalTitle = 'Add Call Source';
		this.actionButtons = [
			{id: 'cancel', title: 'Cancel', close: true},
			{id: 'save', title: 'Save', color: 'primary'}
		];
		const type = new CallSourceCodeModel();
		this.modalSource = this.fb.group({
			code: [type.code, [Validators.required, Validators.maxLength(3)]],
			description: type.description
		});
		this.openModal();
	}

	public print() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}

		this.loading = true;
		const tab: Window = window.open('/loading');
		this.pdfSubscription = this.codesService.getCallSourcesPdf()
			.finally(() => {
				this.loading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}

}

import { MatCheckboxChange, MatSort } from '@angular/material';
import { EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IdInterface } from '@dvp/is2-shared';
import { RowClassInterface } from '../../interface/row-class.interface';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../service/user.service';
import { DialService } from '../../service/dial.service';

export abstract class TableFunctionalityComponent implements OnInit, OnChanges {
	@Input() public bulkEdit: boolean;
	@Input() public selectAllExclusions: boolean;
	@Input() public selectAllMode: boolean;
	@Input() public dataSource: any;
	@Input() public columns: TableColumnInterface[];
	@Input() public clickable = false;
	@Input() public layout: string;
	@Input() public paginated = false;
	@Input() public phoneLinks: {phone: string, label: string}[];

	@Input() protected totalItems?: number;
	@Input() protected printMode: boolean;

	@Input()
	protected set rowClass(rowClass: RowClassInterface) {
		this._rowClass = this.addDisabledClickableClass(rowClass);
	}

	protected get rowClass(): RowClassInterface {
		return this._rowClass;
	}

	protected _rowClass: RowClassInterface;

	@Input()
	protected set viewportHeight(viewportHeight: number) {
		this.viewport.getElementRef().nativeElement.style.height = `${viewportHeight}px`;
	}

	protected get viewportHeight(): number {
		return this._viewportHeight;
	}

	protected _viewportHeight: number;

	@ViewChild(MatSort, {static: true})
	public sort: MatSort;

	@ViewChild('viewport', {static: true})
	protected viewport: CdkVirtualScrollViewport;

	@Output() public rowEvent: EventEmitter<any> = new EventEmitter();
	@Output() public sortData: EventEmitter<MatSort> = new EventEmitter();
	@Output() public masterToggleEvent: EventEmitter<void> = new EventEmitter();
	@Output() public toggleRowEvent: EventEmitter<{event: MatCheckboxChange, item: IdInterface}> = new EventEmitter();

	public mobile: boolean = false;
	public selection: SelectionModel<IdInterface> = new SelectionModel(true, []);
	public gridHeight: number = 1000;

	protected constructor (
		protected mediaObserver: MediaObserver,
		protected toastrService?: ToastrService,
		protected userService?: UserService,
		protected dialService?: DialService,
	) {
		this.mediaObserver.media$.subscribe((change: MediaChange) => this.mobile = ['xs', 'sm'].includes(change.mqAlias));
	}

	public ngOnChanges(): void {
		this.sortData.emit(this.sort);
	}

	public ngOnInit() {
		this.mobile = (window.innerWidth < 960); // sm
		this._rowClass = this.addDisabledClickableClass();
	}

	public emitRowEvent(row, i, $event) {
		if (row.disabled) {
			return;
		}

		if (this.clickable) {
			this.rowEvent.emit({row, i, $event});
		}
	}

	public masterToggle(): void {
		this.masterToggleEvent.emit();
	}

	public toggleRow(event: MatCheckboxChange, item: IdInterface): void {
		this.toggleRowEvent.emit({event: event, item: item});
	}

	public getColumnDefs(columns) {
		if (this.bulkEdit) {
			return ['edit', ...columns.map(col => col.columnDef)];
		}

		return columns.map(col => col.columnDef);
	}

	public triggerCallback(button: {callback: (row: any, event: MouseEvent) => {}}, row: any, event: MouseEvent): boolean {
		event.preventDefault();
		event.cancelBubble = true;
		button.callback(row, event);
		return false;
	}

	public getRowClasses(row: any): string[] {
		if (!this.rowClass) {
			return;
		}

		return Object.keys(this.rowClass).filter(key => {
			return this.rowClass[key](row);
		});
	}

	public assignArchiveClass(row: any) {
		return row && row.occurrence && row.occurrence.isArchived;
	}

	public checkDial(event: MouseEvent, phoneNumber: string): boolean {
		if (!this.userService.isAtDesk()) {
			return true;
		}

		event.preventDefault();
		this.dialService.dial(phoneNumber).subscribe(
			() => {
				this.dialed(null);
			},
			(error: HttpErrorResponse) => {
				this.dialed(error.error.message);
			},
		);
	}

	public getRowText(input, data): string {
		if (input.columnDef === 'relativeStartDate') {
			return `Complete within ${data.relativeStartDate} days after start date`;
		}
	}

	protected dialed(error: string): void {
		if (!error) {
			this.toastrService.success('Dial completed.');
		} else {
			this.toastrService.error(error);
		}
	}

	protected addDisabledClickableClass(rowClass?: RowClassInterface): RowClassInterface {
		if (!this.clickable) {
			return;
		}

		if (!rowClass) {
			rowClass = {};
		}

		if (!rowClass.hasOwnProperty('clickable')) {
			rowClass.clickable = (row: any): boolean => {
				return row && !row.disabled;
			};
		}
		return rowClass;
	}

	protected getTruncatedParameters(parameters: string): string {
		if (!parameters) {
			return;
		}

		const splitParams: string[] = parameters.split('\n');

		if (splitParams.length > 2) {
			return splitParams.slice(0, 1).join('\n') + '\n...';
		}

		return parameters;
	}

}

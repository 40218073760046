import { NgModule } from '@angular/core';

import { LoginModule } from './module/login/login.module';
import { DashboardModule } from './module/dashboard/dashboard.module';
import { SharedModule } from './shared.module';
import { PropertyModule } from './module/property/property.module';
import { ProspectModule } from './module/prospect/prospect.module';
import { ReportingModule } from './module/reporting/reporting.module';
import { AdminModule } from './module/admin/admin.module';
import { UserModule } from './module/user/user.module';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { NavigationAccessiblePipe } from './pipe/navigation-accessible.pipe';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material';
import { LoadingPage } from './page/loading/loading.page';
import { UserGuard } from './guard/user.guard';
import { NavigationService } from './service/navigation.service';
import { ServerErrorInterceptor } from './interceptor/server-error.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

const appRoutes: Routes = [
	{
		path: '',
		redirectTo: '/dashboard/charts',
		pathMatch: 'full',
	},
	{
		path: 'loading',
		component: LoadingPage,
		canActivate: [UserGuard],
	},
	{
		path: '**',
		redirectTo: '/dashboard/charts',
		pathMatch: 'full',
	},
];

@NgModule({
	declarations: [
		AppComponent,
		NavigationAccessiblePipe,
		LoadingPage,
	],
	imports: [
		RouterModule.forRoot(
			appRoutes,
			{
				enableTracing: environment.routing.tracing,
				onSameUrlNavigation: 'reload',
			},
		),
		SharedModule.forRoot(),
		LoginModule,
		DashboardModule,
		PropertyModule,
		ReportingModule,
		ProspectModule,
		AdminModule,
		UserModule,
		MatDatepickerModule,
		MatMomentDateModule,
	],
	providers: [
		{provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
		UserGuard,
		ServerErrorInterceptor,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ServerErrorInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {

	constructor(navigationService: NavigationService) {
		navigationService.addUserNavigation({
			label: 'Report Issue',
			action: () => window.location.href = `mailto:${environment.supportEmail.to.join(';')}?cc=${environment.supportEmail.cc.join(',')}`,
			ordinal: 1,
			iconClass: 'fa-question-circle',
		});
	}

}

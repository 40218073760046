import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { SlackService } from '../service/slack.service';
import { UserService } from '../service/user.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

	constructor(protected toastrService: ToastrService, protected slackService: SlackService, protected userService: UserService) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): any {
		if (req.url.indexOf(environment.apiUrl) === -1) {
			return next.handle(req);
		}

		return next.handle(req).catch(error => {
			if (error instanceof HttpErrorResponse && error.status >= 500 && error.status < 600) {
				this.handleServerError(req, error);
			}
			return throwError(error);
		});
	}

	protected handleServerError(request: HttpRequest<any>, response: HttpErrorResponse): void {
		this.slackService.httpError(this.userService.getUser(), request, response).subscribe(
			() => {
				this.toastrService.error('There was a problem on the server. The support team has been notified.');
			},
			(error2) => {
				this.toastrService.error(
					'There was a problem on the server and the support team notification also failed. Please take a screenshot of this issue and send it to the support team.',
					null,
					{disableTimeOut: true},
				);
				this.toastrService.error(response.error.message, null, {disableTimeOut: true});
				this.toastrService.error(error2.message, null, {disableTimeOut: true});
			},
		);
	}

}

import {Injectable} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {ValidatorService} from './validator.service';

@Injectable()
export class FormBuilderService {

	constructor(private fb: FormBuilder) {
	}

	public getFormBuilder(): FormBuilder {
		return this.fb;
	}

	public buildFormFromClassInstance(classInstance: any, classConstructor: new() => Object): FormGroup {
		const formControls = {};
		const formFieldList: any = [];

		Object.keys(classInstance).forEach(key => {
			if (classInstance[key] instanceof Array) {
				formControls[key] = new FormArray(classInstance[key].map(field => new FormControl(field, ValidatorService.validateControl(classConstructor))));
			} else {
				formControls[key] = new FormControl(classInstance[key], ValidatorService.validateControl(classConstructor));
			}

			formFieldList.push(key);
		});

		const formInstance = this.fb.group(formControls);
		formInstance['formFields'] = formFieldList;

		return formInstance;
	}

	public touchAll(formGroup: FormGroup | FormArray, func = 'markAsTouched', opts = {onlySelf: false}): this {
		Object.keys(formGroup.controls).forEach(key => {
			if (formGroup.controls[key] instanceof FormGroup || formGroup.controls[key] instanceof FormArray) {
				this.touchAll(formGroup.controls[key], func, opts);
			} else {
				formGroup.controls[key][func](opts);
			}
		});
		return this;
	}

}

import {FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {PropertyUtilsService} from './property-utils.service';
import {validateSync, ValidationError} from 'class-validator';

/**
 * Custom angular validator for pleerock class-validator decorators.
 */
export class ValidatorService {
	/**
	 * Validate the value of the specified AbstractControl
	 *
	 * @param classConstructor class with the validation decorators
	 */
	public static validateControl(classConstructor: new() => Object): ValidatorFn {
		return (control: FormControl) => {
			let valid = null;
			const controlName = ValidatorService.getControlName(control);
			if (controlName) {
				const obj: Object = new classConstructor();

				let transformedValue = control.value;
				try {
					transformedValue = PropertyUtilsService.transformPropertyValue(classConstructor, controlName, control.value);
				} catch (e) {
					// hacky solution to stupid problem that I don't want to spend a million years trying to fix. rawr.
					if (e.message !== `Cannot read property '_isAMomentObject' of undefined`) {
						throw e;
					}
				}

				PropertyUtilsService.setProperty(obj, controlName, transformedValue);

				const valResult: ValidationError[] = validateSync(obj, {skipMissingProperties: true});

				if (valResult.length > 0) {
					const msgs: Array<String> = ValidatorService.convertValidationErrorToMsgsArray(valResult, controlName);
					if (msgs && msgs.length > 0) {
						valid = {'dynaControlError': {noValid: true, messages: msgs}};
					}
				}
			}
			return valid;
		};
	}


	private static convertValidationErrorToMsgsArray(result: ValidationError[],
	                                                 propertyName: string,
	                                                 msgs: Array<String> = []): Array<String> {
		for (const valid of result) {  // iterate list
			if (valid.property === propertyName && valid.constraints) {
				for (const constr in valid.constraints) {  // iterate object properties
					if (valid.constraints.hasOwnProperty(constr)) {
						msgs.push(valid.constraints[constr]);
					}
				}
			}
			if (valid.children && valid.children.length > 0) {
				this.convertValidationErrorToMsgsArray(
					valid.children, propertyName, msgs);  // find constraints in children
			}
		}

		return msgs;
	}


	private static getControlName(control: FormControl): string {
		let controlName: string = null;
		const parent = control['parent'];

		if (parent instanceof FormGroup) {  // iterate only if parent is a FormGroup
			Object.keys(parent.controls).forEach((name) => {
				// compare passed control with child control
				if (control === parent.controls[name]) {  // if same references
					controlName = name;
				}
			});
		}
		return controlName;  // return the name or null if not found
	}
}

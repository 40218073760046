import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavModel } from '../../../../../model/nav.model';

@Component({
	selector: 'prospect-navbar',
	templateUrl: './prospect-navbar.component.pug',
	styleUrls: ['./prospect-navbar.component.scss'],
})
export class ProspectNavbarComponent {

	@Input() public currentSection = 'details';

	@Output() protected linkClick: EventEmitter<string> = new EventEmitter();

	public links: NavModel[] = [
		{label: 'DETAILS', section: 'details', iconClass: 'fa-info-circle'},
		{label: 'WANTS', section: 'wants', iconClass: 'fa-exclamation-circle'},
		{label: 'SHOWN', section: 'shown', iconClass: 'fa-external-link-square-alt'},
		{label: 'HAS', section: 'has', iconClass: 'fa-link'},
		{label: 'REMARKS', section: 'remarks', iconClass: 'fa-comments'},
		{label: 'MAILINGS', section: 'mailings', iconClass: 'fa-envelope'},
		{label: 'MISC', section: 'misc', iconClass: 'fa-asterisk'},
		{label: 'CALLS', section: 'calls', iconClass: 'fa-phone'},
		{label: 'TASKS', section: 'tasks', iconClass: 'fa-tasks'}
	];

	public goTo(fragment): void {
		this.linkClick.emit(fragment);
	}

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ProspectListModel, ProspectSearchDto, PaginationDto, MailingModel } from '@dvp/is2-shared';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ProspectSearchService {

	constructor(protected http: HttpClient) {
	}

	public static prepareSearchDto(searchDto: Partial<ProspectSearchDto>) {
		if (!searchDto.details) {
			searchDto.details = {statuses: ['ACTIVE']};
		} else if (!searchDto.details.statuses || searchDto.details.statuses.length === 0) {
			searchDto.details.statuses = ['ACTIVE'];
		}

		Object.keys(searchDto).forEach(section => {
			if (!searchDto.hasOwnProperty(section)) {
				return;
			}
			Object.keys(searchDto[section]).forEach(key => {
				if ((!searchDto[section][key] && searchDto[section][key] !== false) || (Array.isArray(searchDto[section][key]) && searchDto[section][key].length === 0)) {
					delete searchDto[section][key];
				}
			});
			if (Object.keys(searchDto[section]).length === 0) {
				delete searchDto[section];
			}
		});

		return searchDto;
	}

	public search(
		searchDto: Partial<ProspectSearchDto>,
		currentPage: number = 0,
		pageSize: number = 10,
		sortColumn?: string,
		sortDirection?: string,
	): Observable<PaginationDto<ProspectListModel>> {
		let params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		if (sortColumn && sortDirection) {
			params = params.append('sortColumn', sortColumn).append('sortDirection', sortDirection);
		}

		return this.http.post<PaginationDto<ProspectListModel>>(`${environment.apiUrl}/prospect`, ProspectSearchService.prepareSearchDto(searchDto), {params});
	}

	public getSearchResultsHasPdf(searchDtos: ProspectSearchDto[]): Observable<ArrayBuffer> {
		return this.http.post(`${environment.apiUrl}/prospect/haspdf`, {
				searchDtos: searchDtos.map(i => ProspectSearchService.prepareSearchDto(i)),
			},
			{
				headers: {'Accept': 'application/pdf'},
				responseType: 'arraybuffer',
			});
	}

	public getSearchResultsPdf(searchDtos: ProspectSearchDto[]): Observable<ArrayBuffer> {
		return this.http.post(
			`${environment.apiUrl}/prospect/pdf`,
			{searchDtos: searchDtos.map(i => ProspectSearchService.prepareSearchDto(i))},
			{
				headers: {'Accept': 'application/pdf'},
				responseType: 'arraybuffer',
			},
		);
	}

	public getSearchResultsCsv(searchDtos: ProspectSearchDto[], excludedProspectIds: number[], mailing: MailingModel): Observable<ArrayBuffer> {
		return this.http.post(
			`${environment.apiUrl}/prospect/csv`,
			{
				searchDtos: searchDtos.map(i => ProspectSearchService.prepareSearchDto(i)),
				excludedProspectIds,
				mailing,
			},
			{
				headers: {'Accept': 'text/csv'},
				responseType: 'arraybuffer',
			},
		);
	}

	public getBulkSearchResults(searchDtos: ProspectSearchDto[]) {
		return this.http.post(
			`${environment.apiUrl}/prospect/bulksearch`,
			{searchDtos: searchDtos.map(i => ProspectSearchService.prepareSearchDto(i))},
		);
	}

	public getBulkSearchPdf(searchDtos: ProspectSearchDto[]) {
		return this.http.post(
			`${environment.apiUrl}/prospect/bulksearch-pdf`,
			{searchDtos: searchDtos.map(i => ProspectSearchService.prepareSearchDto(i))},
			{
				headers: {'Accept': 'application/pdf'},
				responseType: 'arraybuffer',
			},
		);
	}

	public checkDuplicates(
		searchDto: Partial<ProspectSearchDto>,
		prospectId?: number,
		currentPage: number = 0,
		pageSize: number = 10,
	): Observable<PaginationDto<ProspectListModel>> {
		const params = new HttpParams()
			.append('prospectId', `${prospectId}`)
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		return this.http.post<PaginationDto<ProspectListModel>>(
			`${environment.apiUrl}/prospect/check-duplicates`, ProspectSearchService.prepareSearchDto(searchDto),
			{params},
		);
	}

}

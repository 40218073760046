import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { PropertyListModel, PropertySearchDto, ValueInterface } from '@dvp/is2-shared';
import { SearchService } from '../../../property/service/search.service';
import { UserModel, PaginationDto } from '@dvp/is2-shared';
import { Observable } from 'rxjs';
import { UserService } from '../../../../service/user.service';
import { PropertyPaginationListComponent } from '../../../../component/property-pagination-list/property-pagination-list.component';
import { ValuesService } from '../../../../service/values.service';
import { PropertySelectionEventInterface } from '../../../../interface/property-selection-event.interface';
import { RouterService } from '../../../../service/router.service';

@Component({
	selector: 'is2-property-listing-summary',
	templateUrl: './property-listing-summary.component.pug',
	styleUrls: ['./property-listing-summary.component.scss'],
})

export class PropertyListingSummaryComponent implements AfterViewInit {

	@ViewChild('propertyPaginationList', {static: false}) protected propertyPaginationListComponent: PropertyPaginationListComponent;

	protected _user: UserModel;
	@Input() set user(user: UserModel) {
		this._user = user;
	}

	get user(): UserModel {
		return this._user;
	}

	public searchDto: PropertySearchDto = new PropertySearchDto();
	public cities: ValueInterface[];
	public agents: ValueInterface[];

	constructor(
		protected searchService: SearchService,
		protected userService: UserService,
		protected valueService: ValuesService,
		protected router: RouterService,
	) {
		valueService.getValues().subscribe((values) => {
			this.cities = values.city;
			this.agents = values.activeAgent;
		});
	}

	public ngAfterViewInit(): void {
		this.propertyPaginationListComponent.doSearch();
		this.propertyPaginationListComponent.getColumns();
	}

	public propertySelected(event: PropertySelectionEventInterface): void {
		this.router.navigateByUrl(`/property/detail/${event.property.id}`);
	}

	public getProperties = (pageIndex: number, pageSize: number): Observable<PaginationDto<PropertyListModel>> => {
		this.searchDto.agent1Id = [this.user.userId];
		return this.searchService.search(this.searchDto, pageIndex, pageSize).finally(() => {});
	};

}

import { Component, Inject } from '@angular/core';
import { SearchInterface, SavedSearchModel } from '@dvp/is2-shared';
import { MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../../service/user.service';

@Component({
	templateUrl: './search-preset-dialog.component.pug',
	styleUrls: ['./search-preset-dialog.component.scss'],
})
export class SearchPresetDialogComponent {

	public data: {
		searchPreset: SavedSearchModel<SearchInterface>,
		action: string,
	};
	public isAdmin: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) data, userService: UserService) {
		this.data = data;
		this.isAdmin = userService.hasRole('Admin') || userService.hasRole('Secretary');
	}

}

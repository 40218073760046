import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'is2-table-row-anchor',
	templateUrl: './table-row-anchor.component.pug',
	styleUrls: ['./table-row-anchor.component.scss'],
})
export class TableRowAnchorComponent implements OnInit{

	@Input() public row: any;

	public hasLink: boolean = false;

	public ngOnInit(): void {
		if (this.row.hasOwnProperty('rowHref') && this.row.rowHref) {
			this.hasLink = true;
		}
	}

}

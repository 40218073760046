import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	selector: 'yes-no-dialog',
	templateUrl: './yes-no-dialog.component.pug',
})
export class YesNoDialogComponent {

	public data: {
		callback?: (includePhoneNumbers: boolean) => void,
		title: string,
		question: string,
		allowCancel: boolean,
	};

	protected disableClick: boolean;

	constructor(public dialogRef: MatDialogRef<YesNoDialogComponent, string>, @Inject(MAT_DIALOG_DATA) data) {
		this.data = data;
	}

	public clickedYes() {
		if (this.disableClick) {
			return;
		}

		if (this.data.callback) {
			this.data.callback(true);
		}

		this.disableClick = true;
		this.dialogRef.close('Yes');
	}

	public clickedNo() {
		if (this.disableClick) {
			return;
		}

		if (this.data.callback) {
			this.data.callback(false);
		}

		this.disableClick = true;
		this.dialogRef.close('No');
	}

}

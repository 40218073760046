import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../../guard/user.guard';
import { DashboardPage } from './page/dashboard/dashboard.page';
import { SharedModule } from '../../shared.module';
import { NavigationService } from '../../service/navigation.service';
import { ReportService } from './service/report.service';
import { ProspectsByAgentComponent } from './component/prospects-by-agent/prospects-by-agent.component';
import { RouterService } from '../../service/router.service';
import { ChartContainerComponent } from './component/chart-container/chart-container.component';
import { CallCountByUserByDateComponent } from './component/call-count-by-user-by-date/call-count-by-user-by-date.component';
import { ContactsComponent } from './component/contacts/contacts.component';
import { DashboardComponent } from './dashboard.component';
import { CallSummaryPage } from './page/call-summary/call-summary.page';
import { TeamCallSummaryPage } from './page/team-call-summary/team-call-summary.page';
import { UserCallsPage } from './page/user-calls/user-calls.page';
import { ProspectSummaryPage } from './page/prospect-summary/prospect-summary.page';
import { SummaryService } from './service/summary.service';
import { ChartModalComponent } from './component/chart-modal/chart-modal.component';
import { PropertySummaryPage } from './page/property-summary/property-summary.page';
import { ListingSummaryPage } from './page/listing-summary/listing-summary.page';
import { UserFilterPipe } from './pipe/userFilter.pipe';
import { ProspectProgressPage } from './page/prospect-progress/prospect-progress.page';
import { FullScreenModalComponent } from '../../component/full-screen-modal/full-screen-modal.component';
import { BoxComponent } from './component/box/box.component';
import { BoxContentTransclusionDirective } from './directive/box-content-transclusion.directive';
import { TasksComponent } from './component/tasks/tasks.component';
import { AdminDashboardComponent } from './component/admin-dashboard/admin-dashboard.component';
import { SalesDashboardComponent } from './component/sales-dashboard/sales-dashboard.component';
import { PropertyStatusSummaryComponent } from './component/property-status-summary/property-status-summary.component';
import { PropertySummaryMapComponent } from './component/property-summary-map/property-summary-map.component';
import { PropertyListingSummaryComponent } from './component/property-listing-summary/property-listing-summary.component';

const appRoutes: Routes = [
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [UserGuard],
		children: [
			{
				path: '',
				redirectTo: '/dashboard/charts',
				pathMatch: 'full',
			},
			{
				path: 'charts',
				component: DashboardPage,
			},
			{
				path: 'calls',
				children: [
					{
						path: '',
						component: CallSummaryPage,
						pathMatch: 'full',
					},
					{
						path: 'team/:id',
						component: TeamCallSummaryPage,
					},
					{
						path: 'user/:id',
						component: UserCallsPage,
					},
				],
			},
			{
				path: 'prospect-summary',
				component: ProspectSummaryPage,
			},
			{
				path: 'prospect-progress',
				component: ProspectProgressPage,
			},
			{
				path: 'property-summary',
				component: PropertySummaryPage,
			},
			{
				path: 'listing-summary',
				component: ListingSummaryPage,
			},
		],
	},
];

@NgModule({
	declarations: [
		DashboardComponent,
		DashboardPage,
		BoxComponent,
		ChartContainerComponent,
		ProspectsByAgentComponent,
		CallCountByUserByDateComponent,
		ContactsComponent,
		CallSummaryPage,
		TeamCallSummaryPage,
		UserCallsPage,
		ProspectSummaryPage,
		PropertySummaryPage,
		ChartModalComponent,
		FullScreenModalComponent,
		ListingSummaryPage,
		UserFilterPipe,
		ProspectProgressPage,
		BoxContentTransclusionDirective,
		TasksComponent,
		AdminDashboardComponent,
		SalesDashboardComponent,
		PropertyStatusSummaryComponent,
		PropertySummaryMapComponent,
		PropertyListingSummaryComponent,
	],
	imports: [
		RouterModule.forChild(appRoutes),
		SharedModule.forRoot(),
	],
	exports: [
		BoxContentTransclusionDirective,
	],
	providers: [
		UserGuard,
		ReportService,
		SummaryService,
	],
})
export class DashboardModule {

	constructor(
		protected navigationService: NavigationService,
		protected router: RouterService,
	) {

		this.navigationService.addMainNavigation({
			label: 'Dashboard',
			path: ['dashboard', 'charts'],
			pathToMatch: ['dashboard'],
			ordinal: 0,
		});

		this.navigationService.addSubNavigation('/dashboard', {
			label: 'Charts',
			path: ['dashboard', 'charts'],
		});

		this.navigationService.addSubNavigation('/dashboard', {
			label: 'Calls',
			path: ['dashboard', 'calls'],
		});

		this.navigationService.addSubNavigation('/dashboard', {
			label: 'Prospect Summary',
			path: ['dashboard', 'prospect-summary'],
		});

		this.navigationService.addSubNavigation('/dashboard', {
			label: 'Prospect Progress',
			path: ['dashboard', 'prospect-progress'],
		});

		this.navigationService.addSubNavigation('/dashboard', {
			label: 'Property Summary',
			path: ['dashboard', 'property-summary'],
		});

		this.navigationService.addSubNavigation('/dashboard', {
			label: 'Listing Summary',
			path: ['dashboard', 'listing-summary'],
		});
	}

}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatCheckboxChange } from '@angular/material';
import { ProspectRemarkModel, ValueInterface, PaginationDto, IdInterface } from '@dvp/is2-shared';
import { Observable } from 'rxjs/Observable';
import { PaginationDatasource } from '../../../../../datasource/pagination.datasource';
import { RemarkDialogComponent } from '../remark-dialog/remark-dialog.component';
import { ProspectRemarkService } from '../../../service/prospect-remark.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from '../../../../../component/deleteDialog/delete-dialog.component';
import * as moment from 'moment';
import { UserService } from '../../../../../service/user.service';
import { TableColumnInterface } from '../../../../../component/table/interface/table-column.interface';
import { Moment } from 'moment';
import { TableComponent } from '../../../../../component/table/table.component';
import { Subject } from 'rxjs';
import { SelectAllEditComponent } from '../../../../../component/select-all-edit/select-all-edit.component';

@Component({
	selector: 'prospect-remarks',
	templateUrl: './prospect-remarks.component.pug',
	styleUrls: ['./prospect-remarks.component.scss'],
})
export class ProspectRemarksComponent extends SelectAllEditComponent implements OnInit {

	@ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;
	@ViewChild(TableComponent, {static: false}) protected table: TableComponent;

	protected _prospectId: number;

	@Input('prospectId')
	set prospectId(prospectId: number) {
		this._prospectId = prospectId;
		this.ngOnInit();
	}

	get prospectId() {
		return this._prospectId;
	}

	@Input() public hasAdminRights = false;
	@Input() protected hasRoutedToRights = false;
	@Input() public agents: ValueInterface[];
	@Input() public isDeleted = false;

	@Output() protected lastCalledDateUpdate: EventEmitter<Moment> = new EventEmitter();

	protected subject = new Subject<any>();

	public dataSource: PaginationDatasource<ProspectRemarkModel>;
	public tableLoading = false;
	public displayedColumns: TableColumnInterface[] = [{
		columnDef: 'date',
		title: 'Date',
	}, {
		columnDef: 'user',
		title: 'User',
	}, {
		columnDef: 'comments',
		title: 'Comments',
	}];

	constructor(
		protected remarkService: ProspectRemarkService,
		protected dialog: MatDialog,
		protected deleteDialog: MatDialog,
		protected toastrService: ToastrService,
		protected userService: UserService,
	) {
		super();
	}

	public ngOnInit(): void {
		this.dataSource = new PaginationDatasource(this.getData.bind(this), this.paginator, null, [this.subject.asObservable()]);
		this.paginator.page.subscribe(() => this.tableLoading = true);
		setTimeout(() => this.bulkEditSelection = this.table.selection);
	}

	public addRemark(event) {
		event.preventDefault();
		event.stopPropagation();

		const remark = new ProspectRemarkModel({
			date: new Date(),
			userId: this.userService.getUser().userId,
			prospectId: this.prospectId,
		});

		const dialog = this.dialog.open(RemarkDialogComponent, {
			width: '400px',
			autoFocus: false,
			data: {
				remark: remark,
				agents: this.agents,
				action: 'Add',
				editMode: false,
				canEdit: true,
				isRoutedTo: this.hasRoutedToRights,
				isAdmin: this.hasAdminRights,
			},
		});

		dialog.afterClosed().subscribe((data) => {
			if (!data || data === 'Cancel') {
				return;
			} else {
				if (!data.remark.id) {
					this.remarkService.createRemark(data.remark)
						.finally(() => {
							this.tableLoading = false;
						})
						.subscribe(id => {
								if (data.shouldUpdateLastCalledDate) {
									this.lastCalledDateUpdate.emit(moment(data.remark.date));
								}
								this.toastrService.success('Remark successfully added.');
								this.subject.next();
							},
							(error: HttpErrorResponse) => {
								this.toastrService.error(error.error.message);
							},
						);
				}
			}
		});
	}

	public editRemarkList($event) {
		const row: ProspectRemarkModel = $event.row;
		const canEditOrDelete = this.hasAdminRights || row.userId === this.userService.getUser().userId;
		const dialog = this.dialog.open(RemarkDialogComponent, {
			width: '400px',
			autoFocus: false,
			data: {
				remark: {
					id: row.id,
					date: row.date ? new Date(row.date) : null,
					comments: row.comments,
					type: row.type,
					userId: row.userId,
					user: row.user,
				},
				agents: this.agents,
				action: 'Edit',
				editMode: true,
				canEdit: canEditOrDelete,
				canDelete: canEditOrDelete,
				isRoutedTo: this.hasRoutedToRights,
				isAdmin: this.hasAdminRights,
			},
		});

		dialog.afterClosed().subscribe((data) => {
			if (!data || data === 'Cancel') {
				return;
			} else if (data === 'Delete') {
				const deleteDialog = this.deleteDialog.open(DeleteDialogComponent, {
					width: '400px',
					data: {
						text: `this Remark from ${moment.utc(row.date).format('MM-DD-YYYY')}`,
						type: 'Remark Entry',
					},
				});
				deleteDialog.afterClosed().subscribe((action) => {
					if (action === 'Delete') {
						this.remarkService.deleteRemark(this.prospectId, row.id)
							.subscribe(() => {
									this.toastrService.success(`Remark for #${this.prospectId} has been deleted.`);
									this.subject.next();
								},
								(error: HttpErrorResponse) => {
									this.toastrService.error(error.error.message);
								},
							);
					}
				});
			} else {
				this.remarkService.updateRemark(data.remark, this.prospectId)
					.subscribe(() => {
							if (data.shouldUpdateLastCalledDate) {
								this.lastCalledDateUpdate.emit(moment(data.remark.date));
							}
							this.toastrService.success(`Remark for #${this.prospectId} has been updated.`);
							this.subject.next();
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						},
					);
			}
		});
	}

	public getMasterToggleEvents(): void {
		super.masterToggle();
	}

	public toggleRow(event: {event: MatCheckboxChange, item: IdInterface}): void {
		this.toggleBulkEditRow(event.event, event.item);
	}

	public deleteSelectedRows(event) {
		event.stopPropagation();
		event.preventDefault();

		let count: number;
		let method: string;
		let data: ProspectRemarkModel[];

		if (this.selectAllMode && this.bulkEditExceptionList.length > 0) {
			count = this.dataSource.pager.totalItems - this.bulkEditExceptionList.length;
			data = this.bulkEditExceptionList as ProspectRemarkModel[];
			method = 'selectAllDeleteRemarks';
		}

		if (this.selectAllMode && this.bulkEditExceptionList.length === 0) {
			count = this.bulkEditSelection.selected.length;
			data = this.bulkEditSelection.selected as ProspectRemarkModel[];
			method = 'bulkDeleteRemarks';
		}

		if (!this.selectAllMode) {
			count = this.bulkEditExceptionList.length;
			data = this.bulkEditSelection.selected as ProspectRemarkModel[];
			method = 'bulkDeleteRemarks';
		}

		if (!count) {
			this.toggleBulkEditMode();
			return;
		}

		const deleteDialog = this.deleteDialog.open(DeleteDialogComponent, {
			width: '400px',
			data: {
				text: `${count} remark entries`,
				type: 'Remark Entries',
			},
		});


		deleteDialog.afterClosed().subscribe(result => {
			if (result === 'Delete') {
				this.tableLoading = true;
				this.remarkService[method](this.prospectId, data.map(row => row.id))
					.finally(() => this.tableLoading = false)
					.subscribe(() => {
						this.toastrService.success(`${count} remark entries have been deleted`);
						this.toggleBulkEditMode();
						this.subject.next();
					}, (error: HttpErrorResponse) => {
						this.toastrService.error(error.error.message);
					});
			}
		});
	}

	public getData(): Observable<PaginationDto<ProspectRemarkModel>> {
		return this.remarkService.getProspectRemarks(this.prospectId, this.paginator.pageIndex, this.paginator.pageSize)
			.finally(() => this.tableLoading = false)
			.map(dto => {
				const remarks: ProspectRemarkModel[] = dto.data;

				if (this.selectAllMode) {
					const filterRemarks: ProspectRemarkModel[] = remarks.filter(this.compareArrays(this.bulkEditExceptionList));
					const filterExcluded: ProspectRemarkModel[] = this.bulkEditExceptionList.filter(this.compareArrays(remarks));
					const results: ProspectRemarkModel[] = filterRemarks.concat(filterExcluded);
					results.forEach(result => this.bulkEditSelection.select(result));
				}

				if (this.bulkEditMode && !this.selectAllMode) {
					const exceptions: ProspectRemarkModel[] = remarks.filter((remark) => this.bulkEditExceptionList.map(exception => exception.id).includes(remark.id));
					exceptions.forEach((exception) => this.bulkEditSelection.select(exception));
				}

				return dto;
			});

	}
}

import { Component, ViewChild, OnInit, AfterViewInit, Input } from '@angular/core';
import { TaskListComponent } from '../task-list/task-list.component';
import { UserService } from '../../service/user.service';
import {
	UserModel,
	UserPreferencesModel,
	PaginationDto,
	TaskModel,
	UserListModel,
	ValueInterface,
	TaskOccurrenceDto,
} from '@dvp/is2-shared';
import { MatPaginator, PageEvent } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { TaskService } from '../../service/task.service';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { PaginationDatasource } from '../../datasource/pagination.datasource';
import { Router } from '@angular/router';
import { UsersService } from '../../module/admin/service/users.service';

@Component({
	selector: 'task-pagination-list',
	templateUrl: './task-pagination-list.component.pug',
	styleUrls: ['./task-pagination-list.component.scss'],
})

export class TaskPaginationListComponent implements OnInit, AfterViewInit {

	@ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;
	@ViewChild('taskList', {static: true}) protected taskListComponent: TaskListComponent;

	@Input() public users: UserListModel[];
	@Input() public hasAdminRights: boolean;
	@Input() public dashboardPage: boolean = false;
	@Input() public labelNames: ValueInterface[];
	@Input() public selectedLabels: number[];
	@Input() public pageSize: number = 5;

	protected subject = new Subject<any>();

	public tasks: TaskOccurrenceDto[];
	public user: UserModel;
	public loading: boolean = false;
	public totalItems: number = 0;
	public pageIndex: number = 0;
	public dataSource: PaginationDatasource<TaskModel>;

	constructor(
		protected userService: UserService,
		protected taskService: TaskService,
		protected usersService: UsersService,
		protected router: Router,
	) {
		this.user = this.userService.getUser();

		if (!this.user.preferences) {
			this.user.preferences = new UserPreferencesModel();
		}

		this.pageSize = this.user.preferences.prospectsPerPage;
	}

	public ngOnInit(): void {
		this.usersService.getAllUsers().subscribe((users: UserListModel[]) => {
			this.users = users.filter(user => user.active);
		});
		this.getTasks();
		this.getColumns();
	}

	public ngAfterViewInit(): void {
		this.paginator.page.subscribe((event: PageEvent) => {
			this.pageIndex = event.pageIndex;
			this.pageSize = event.pageSize;
			this.getTasks();
		});
	}

	public getColumns(): TableColumnInterface[] {
		return this.taskListComponent.getColumns().splice(4, 1,
			{
				title: 'Entity',
				columnDef: 'name',
			},
			{
				title: '',
				pipe: 'actions',
				buttons: [{
					fontSet: 'fas',
					fontIcon: 'fa-building',
					color: 'primary',
					tooltip: 'Click to view Property',
					callback: (row: TaskOccurrenceDto, event: MouseEvent) => {
						this.routeTo(row, event);
					},
					condition: (row: TaskOccurrenceDto) => {
						return !!row.propertyId;
					},
				}, {
					fontSet: 'fas',
					fontIcon: 'fa-user',
					color: 'primary',
					tooltip: 'Click to view Prospect',
					callback: (row: TaskOccurrenceDto, event: MouseEvent) => {
						this.routeTo(row, event);
					},
					condition: (row: TaskOccurrenceDto) => {
						return !!row.prospectId;

					},
				}],
			},
		);
	}

	public getTasks(): void {
		this.loading = true;
		const observable: void | Observable<PaginationDto<TaskOccurrenceDto>> = this.taskService.getTasksByUserId(this.user.userId, this.pageIndex, this.pageSize, this.selectedLabels);

		if (observable) {
			observable.subscribe(this.setSearchResults.bind(this));
		}
	}

	protected routeTo(row: TaskOccurrenceDto, event: MouseEvent): void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}

		if (row.propertyId) {
			// noinspection JSIgnoredPromiseFromCall
			this.router.navigateByUrl(`/property/detail/${row.propertyId}`);
		}

		if (row.prospectId) {
			// noinspection JSIgnoredPromiseFromCall
			this.router.navigateByUrl(`/prospect/detail/${row.prospectId}`);
		}
	}

	protected setSearchResults(results: PaginationDto<TaskOccurrenceDto>): void {
		this.tasks = results.data;

		this.tasks.map((task) => {
			if (task.occurrence.labels.length > 0 && this.labelNames) {
				(task as any).labelString = task.occurrence.labels.map(label => {
					if (label) {
						return (' ' + this.labelNames.find(x => x.id === label).name);
					}
				});
			}
		});
		this.totalItems = results.totalItems;
		this.pageIndex = results.currentPage;
		this.loading = false;
	}

}

import {NgModule} from '@angular/core';

import {RouterModule, Routes} from '@angular/router';
import {UserGuard} from '../../guard/user.guard';
import {ReportingPage} from './page/reporting/reporting.page';
import {SharedModule} from '../../shared.module';
import {NavigationService} from '../../service/navigation.service';
import {RouterService} from '../../service/router.service';
import {CallReportService} from './page/service/call-report.service';

const appRoutes: Routes = [
	{
		path: 'reporting',
		component: ReportingPage,
		canActivate: [UserGuard],
	},
];

@NgModule({
	declarations: [
		ReportingPage,
	],
	imports: [
		RouterModule.forChild(appRoutes),
		SharedModule.forRoot(),
	],
	providers: [
		UserGuard,
		CallReportService
	],
})
export class ReportingModule {

	constructor(protected navigationService: NavigationService,
	            protected router: RouterService) {
		// TODO:: enable reporting link
		/*
		this.navigationService.addMainNavigation({
			label: 'Reporting',
			action: () => router.navigate(['/reporting']),
		});
		*/
	}

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IdInterface } from '@dvp/is2-shared';

@Component({
	selector: 'prospect-create',
	templateUrl: './prospect-create.component.pug',
	styleUrls: ['./prospect-create.component.scss'],
})

export class ProspectCreateComponent implements OnInit {

	public defaults: ProspectDefaultsInterface;

	constructor(
		protected activatedRoute: ActivatedRoute,
		protected router: Router,
	) {}

	public ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params) {
				this.defaults = {
					workPhone: params.from,
				};
			}
		});
	}

	public goToProspect(prospect: IdInterface): void {
		// noinspection JSIgnoredPromiseFromCall
		this.router.navigateByUrl('/prospect/detail/' + prospect.id);
	}
}

export interface ProspectDefaultsInterface {
	workPhone: string;
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { UserModel, ValueInterface, ValuesModel } from '@dvp/is2-shared';
import { PropertyService } from '../../../service/property.service';
import { MatDatepickerInputEvent } from '@angular/material';
import * as moment from 'moment';
import { UsersService } from '../../../../admin/service/users.service';

@Component({
	selector: 'property-details',
	templateUrl: './details.component.pug',
	styleUrls: ['./details.component.scss'],
})

export class DetailsComponent implements OnInit, OnChanges {

	@Input() public values: ValuesModel;
	@Input() public detailsFormGroup: FormGroup;
	@Input() public hasAdminRights = false;
	@Input() public createMode = false;

	public currentDate = new Date();
	public ACTIVE_STATUSES: number[] = PropertyService.ACTIVE_STATUSES;

	constructor(public usersService: UsersService) {}

	public ngOnInit(): void {
		const statusControl = this.detailsFormGroup.get('statusId');
		const confidentialControl = this.detailsFormGroup.get('isConfidential');
		const priceControl = this.detailsFormGroup.get('price');
		const sizeControl = this.detailsFormGroup.get('size');

		const initialStatus = statusControl.value;
		statusControl.valueChanges.subscribe((status) => {
			if ([2, 4, 5].includes(initialStatus) && status === 1) {
				confidentialControl.setValue(true);
			}
		});

		priceControl.valueChanges.subscribe((price) => {
			const size = sizeControl.value;
			if (priceControl.pristine) {
				return;
			} else if (Number.isNaN(price) || Number.isNaN(size) || price < 0 || size <= 0) {
				return;
			}

			const pricePerAmount = price / size;
			this.detailsFormGroup.patchValue({pricePerAmount: `${pricePerAmount.toFixed(2)}`});
		});

		sizeControl.valueChanges.subscribe((size) => {
			const price = priceControl.value;
			if (sizeControl.pristine) {
				return;
			} else if (Number.isNaN(price) || Number.isNaN(size) || price < 0 || size <= 0) {
				return;
			}

			const pricePerAmount = price / size;
			this.detailsFormGroup.patchValue({pricePerAmount: `${pricePerAmount.toFixed(2)}`});
		});

		const summerTaxesControl = this.detailsFormGroup.get('summerTaxes');
		const winterTaxesControl = this.detailsFormGroup.get('winterTaxes');

		summerTaxesControl.valueChanges.subscribe((summerTaxes) => {
			const winterTaxes = winterTaxesControl.value || 0;
			if (summerTaxesControl.pristine) {
				return;
			}

			const totalTaxes = summerTaxes + winterTaxes;
			this.detailsFormGroup.patchValue({totalTaxes});
		});

		winterTaxesControl.valueChanges.subscribe((winterTaxes) => {
			const summerTaxes = summerTaxesControl.value || 0;
			if (winterTaxesControl.pristine) {
				return;
			}

			const totalTaxes = winterTaxes + summerTaxes;
			this.detailsFormGroup.patchValue({totalTaxes});
		});
	}

	public ngOnChanges(): void {
		const agent1: string = this.detailsFormGroup.controls['agentIds'].value[0];
		const agent2: string = this.detailsFormGroup.controls['agentIds'].value[1];

		if (agent1 && this.values && !this.agentInValues(agent1)) {
			this.usersService.getUser(agent1).subscribe((user: UserModel) => {
				this.values.activeAgent.push({id: agent1, name: `${user.firstName} ${user.lastName} (INACTIVE)`});
			});
		}

		if (agent2 && this.values && !this.agentInValues(agent2)) {
			this.usersService.getUser(agent2).subscribe((user: UserModel) => {
				this.values.activeAgent.push({id: agent2, name: `${user.firstName} ${user.lastName} (INACTIVE)`});
			});
		}

		if (this.values) {
			this.setBuildingClassRequired();
		}
	}

	public expirationDateChanged(event: MatDatepickerInputEvent<Date>): void {
		if (moment(event.value) > moment() && this.detailsFormGroup.get('statusId').value === 3) {
			this.detailsFormGroup.patchValue({statusId: 1});
		}
	}

	public getAgentIds(): AbstractControl[] {
		const agentIds: FormArray = this.detailsFormGroup.get('agentIds') as FormArray;
		return agentIds.controls;
	}

	public getFinancialClassIds() {
		const financialClassIds: FormArray = this.detailsFormGroup.get('financialClassIds') as FormArray;
		return financialClassIds.controls;
	}

	public getCategoryIds() {
		const categoryIds: FormArray = this.detailsFormGroup.get('categoryIds') as FormArray;
		return categoryIds.controls;
	}

	public resetCategory(index: number) {
		const categoryControl = this.getCategoryIds()[index];
		categoryControl.enable();
		categoryControl.setValue(null);
	}

	public getFinancialClassId(index: number): number {
		return this.getFinancialClassIds()[index].value;
	}

	public getStatus(): string {
		if (this.values) {
			const status = this.values.propertyStatus.find(value => value.id === this.detailsFormGroup.get('statusId').value);
			if (status) {
				return status.name;
			}
		}

		return;
	}

	public categoryChanged(index: number): void {
		if (index !== 0) {
			return;
		}

		this.setSizeField();
		this.setBuildingClassRequired();
	}

	public getCategoryClass(index: number): string | undefined {
		const catId: string = this.getCategoryIds()[index].value;
		const category: ValueInterface | undefined = this.values.propertyCategory.find(x => x.id === +catId);
		let categoryClass: string | undefined;

		if (category !== undefined) {
			categoryClass = category.name.substr(0, 5);
		}

		return categoryClass;
	}

	public setBuildingClassRequired(): void {
		const categoryClass: string | undefined = this.getCategoryClass(0);
		const control: AbstractControl = this.detailsFormGroup.controls['buildingClass'];
		if (['04.01', '04.02', '04.03'].includes(categoryClass)) {
			control.setValidators(Validators.required);
		} else {
			control.clearValidators();
		}
		control.updateValueAndValidity();
	}

	public setSizeField() {
		const categoryClass: string | undefined = this.getCategoryClass(0);
		let categorySubclass: string | undefined;
		if (categoryClass !== undefined) {
			categorySubclass = categoryClass.substr(0, 2);
		}
		let sizeId = null;
		switch (categorySubclass) {
			case '01':
			case '04':
			case '05':
			case '06':
			case '08':
			case '10':
				sizeId = 1; // Sq. ft
				break;
			case '07':
				sizeId = 2; // Acres
				break;
			case '03':
				sizeId = 3; // Rooms
				break;
			case '02':
			case '09':
				sizeId = 4; // Units
				break;
			case undefined:
			default:
				break;
		}
		this.detailsFormGroup.get('sizeUnitId').setValue(sizeId);
	}

	protected agentInValues(id: string): boolean {
		return !!this.values.activeAgent.find((agent: ValueInterface) => agent.id === id);
	}
}

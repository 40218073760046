import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ProspectListModel, ValueInterface, ValuesModel } from '@dvp/is2-shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProspectService } from '../../module/prospect/service/prospect.service';
import { DeleteExplanationDialogComponent } from '../../module/prospect/component/delete-explanation-dialog/delete-explanation-dialog.component';
import { DeleteExplanationDialogDataInterface } from '../../module/prospect/interface/delete-explanation-dialog-data.interface';
import { ProspectRemarkService } from '../../module/prospect/service/prospect-remark.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'bulk-edit-prospects-dialog',
	templateUrl: './bulk-edit-prospects-dialog.component.pug',
})

export class BulkEditProspectsDialogComponent implements OnInit {

	public bulkEditFormGroup: FormGroup;
	public currentDate = new Date();
	public states = require('../../../assets/states.json');
	public data: {
		prospects: ProspectListModel[],
		values: ValuesModel,
		count: number,
	};
	public statuses: ValueInterface[] = ProspectService.STATUSES;

	constructor(
		public dialogRef: MatDialogRef<BulkEditProspectsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		protected fb: FormBuilder,
		protected toastrService: ToastrService,
		protected deleteExplainDialog: MatDialog,
		protected remarkService: ProspectRemarkService,
	) {
		this.data = data;
	}

	public ngOnInit(): void {
		this.createForm();
	}

	public createForm(): void {
		this.bulkEditFormGroup = this.fb.group({
			details: this.fb.group({
				address1: ['', Validators.maxLength(50)],
				address2: ['', Validators.maxLength(50)],
				city: ['', Validators.maxLength(50)],
				state: [''],
				zipcode: ['', Validators.maxLength(5)],
				website: ['', Validators.maxLength(50)],
				canSendLetter: false,
				letterCheckbox: [''],
				canSendEmail: [''],
				emailCheckbox: false,
				workPhone: [''],
				nextContactDate: [''],
				routeToAgentId: [''],
				callSources: [''],
				prosType1: [''],
				prosType2: [''],
				prosType3: [''],
				prosType4: [''],
				prosType5: [''],
			}),
			misc: this.fb.group({
				status: [''],
				building: ['', Validators.maxLength(30)],
				personalMail: ['', Validators.maxLength(20)],
				newsletter: ['', Validators.maxLength(30)],
				sicCode: ['', Validators.maxLength(20)],
			}),
		});

		this.bulkEditFormGroup.disable();
		this.bulkEditFormGroup.get('details.emailCheckbox').enable();
		this.bulkEditFormGroup.get('details.letterCheckbox').enable();
	}

	public toggleEnableFunctionality(formControlName: string): void {
		const controlStatus = this.bulkEditFormGroup.get(formControlName).status;
		const control = this.bulkEditFormGroup.get(formControlName).value;

		if (controlStatus === 'VALID' && control !== '') {
			this.bulkEditFormGroup.get(formControlName).reset();
		}

		controlStatus === 'DISABLED' ? this.bulkEditFormGroup.get(formControlName).enable() : this.bulkEditFormGroup.get(formControlName).disable();
	}

	public async save(): Promise<void> {
		if (this.bulkEditFormGroup.invalid) {
			this.bulkEditFormGroup.markAsTouched();
			this.toastrService.error('must fix errors prior to saving');
			return;
		}

		const updatedProspect = this.bulkEditFormGroup.value;

		if (updatedProspect.misc && updatedProspect.misc.status === 'DELETED') {
			await this.deleteExplanation();
		}

		delete updatedProspect.details.emailCheckbox;
		delete updatedProspect.details.letterCheckbox;

		this.dialogRef.close(updatedProspect);
	}

	protected async deleteExplanation(): Promise<void> {
		return new Promise<void>((resolve: any, reject: any): void => {
			this.deleteExplainDialog.open(DeleteExplanationDialogComponent, {
				minWidth: '1000px',
				data: <DeleteExplanationDialogDataInterface>{
					text: `${this.data.prospects.length} Prospects`,
					prospects: this.data.prospects,
				},
			}).afterClosed().subscribe((result) => {
				if (result.remarks) {
					this.remarkService.createBulkRemarks(result.remarks).subscribe(() => {
							resolve();
						},
						(error: HttpErrorResponse) => {
							reject(error);
						});
				}
			});
		});
	}
}

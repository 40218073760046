import {Component, OnInit} from '@angular/core';
import {CallReportService} from '../service/call-report.service';
import {CallSummaryModel} from '@dvp/is2-shared';

@Component({
	templateUrl: './reporting.page.pug',
	styleUrls: ['./reporting.page.scss'],
})
export class ReportingPage implements OnInit {

	constructor(protected callReportService: CallReportService) {}

	public ngOnInit(): void {
		this.loadCallSummary();
	}

	protected loadCallSummary() {
		this.callReportService.callSummary()
			.subscribe((callSummary: CallSummaryModel) => {

			});
	}

}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	templateUrl: './label-dialog.component.pug',
	styleUrls: ['./label-dialog.component.scss']
})

export class LabelDialogComponent {

	constructor(public dialogRef: MatDialogRef<LabelDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data) {}

	public save(): void {
		this.dialogRef.close(this.data.label);
	}

	public deleteLabel(): void {
		this.dialogRef.close('Delete');
	}

}

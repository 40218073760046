/* tslint:disable:max-line-length */
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { PropertyListModel } from '@dvp/is2-shared';
import { RichTextEditorComponent } from '../../../../component/rich-text-editor/rich-text-editor.component';
import { PropertyService } from '../../../property/service/property.service';
import { ControlValueAccessor } from '@angular/forms';
import { ControlValueAccessorProviderFactory } from '../../../../util/control-value-accessor-provider.factory';
import { COLORS } from '../../../../shared.module';

@Component({
	selector: 'property-email-editor',
	templateUrl: './property-email-editor.component.pug',
	styleUrls: ['./property-email-editor.component.scss'],
	providers: [ControlValueAccessorProviderFactory(PropertyEmailEditorComponent)]
})
export class PropertyEmailEditorComponent implements ControlValueAccessor {

	protected readonly BROCHURE_URL: string = 'http://www.thomasduke.com/brochure';

	@ViewChild('ckEditor', {static: true}) ckEditor: RichTextEditorComponent;

	@Output() public onImageSelect: EventEmitter<any> = new EventEmitter<any>();

	public loading: boolean = false;
	public richTextEditorConfig;
	public carouselTileItems: Array<any> = [];

	public _value: any;
	get value(): any { return this._value; }
	set value(value: any) {
		if (value !== this._value) {
			this.writeValue(value);
		}
	}

	protected onChange = (_: any) => {};
	protected onTouched = () => {};

	constructor(protected propertyService: PropertyService) {
		this.richTextEditorConfig = RichTextEditorComponent.DEFAULT_CONFIG;
		this.richTextEditorConfig.startupShowBorders = false;
		this.richTextEditorConfig.extraAllowedContent = ['table', 'th', 'tr', 'td'];
		this.richTextEditorConfig.extraPlugins = 'divarea';
	}

	public writeValue(value: any): void {
		if (value && !this._value) {
			return this.buildEmail(value);
		}

		this._value = value;
		this.onChange(value);
	}

	public registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	public buildEmail({ property, customMessage }) {
		this._value = {
			property,
			customMessage,
		};

		this.propertyService.getImages(property.id).subscribe((images: any[]) => {
			this.carouselTileItems = images;
			this._value.customMessage.customImage = this.carouselTileItems.find(i => i.featured).original;
		});

		this._value.customMessage.text = this.buildPropertyHTML(property);
	}

	public imageSelected(image) {
		this.value.customMessage.customImage = image ? image.original : null;
	}

	protected formatAsCurrency(number: number) {
		if (!number) {
			return 'Call for price.';
		}

		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
		});

		return formatter.format(number);
	}

	protected getSpaceAvailable(minAvail: number, maxContiguous: number) {
		const formatNumber = (number: number) => number.toLocaleString();

		if (minAvail && !maxContiguous) {
			return `${formatNumber(minAvail)}+ SF`;
		} else if (!minAvail && maxContiguous) {
			return `Up to ${formatNumber(maxContiguous)} SF`;
		} else if (minAvail && maxContiguous) {
			return `${formatNumber(minAvail)} - ${formatNumber(maxContiguous)} SF`;
		} else {
			return `Call for more information.`;
		}
	}

	protected buildPropertyHTML(property: PropertyListModel) {
		const {name, mapAddress, mapCity, mapZip, minAvail, maxContiguous, price, buildoutId} = property;
		const propertyHTML = `
			<table>
				<tr>
					<td>
						<span style="color: ${COLORS.PRIMARY}; font-size: 20px;">
							<strong>${name}</strong>
						</span>
					</td>
				</tr>
				<tr>
					<td>${mapAddress} | ${mapCity}, MI ${mapZip}</td>
				<tr>
					<td>
						<hr align="left" style="width: 70%;">
					</td>
				</tr>
				<tr>
					<td>
						<span>Price: &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
						<strong>${this.formatAsCurrency(price)}</strong>
					</td>
				</tr>
				<tr>
					<td>
						<span>Space Available:&emsp;&emsp;</span>
						<strong>${this.getSpaceAvailable(minAvail, maxContiguous)}</strong>
					</td>
				</tr>
				<tr>
					<td>
						<a href="${this.BROCHURE_URL + '/' + buildoutId}" style="color: #${COLORS.PRIMARY}; font-style: italic; font-size: 12px; display: ${buildoutId ? 'block;' : 'none;'}">
							<strong>See Brochure</strong>
						</a>
					</td>
				</tr>
			</table>
		`;

		const parser = new DOMParser();
		const doc = parser.parseFromString(propertyHTML, 'text/html');

		return doc.body.innerHTML;
	}

}

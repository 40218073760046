
import { base } from './base';
import deepmerge from 'deepmerge';

export const environment = deepmerge(base, {

  apiUrl: 'https://staging.api.reis.thomasduke.com',

  slack: {
    webhookUrl: 'https://hooks.slack.com/services/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',
  },

});

import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
	PaginationDto,
	PropertyMatchModel,
	ProspectModel,
	ProspectListModel,
	UserModel,
	UserPreferencesModel,
	ValuesModel,
	IdInterface,
	ProspectSearchDto,
	UserListModel,
} from '@dvp/is2-shared';
import { Observable, Subject } from 'rxjs';
import { MatPaginator, PageEvent, MatSort, MatDialog } from '@angular/material';
import { UserService } from '../../service/user.service';
import { ProspectSelectionEventInterface } from '../../interface/prospect-selection-event.interface';
import { ProspectListComponent } from '../prospect-list/prospect-list.component';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { SelectAllEditComponent } from '../select-all-edit/select-all-edit.component';
import { BulkEditProspectsDialogComponent } from '../bulk-edit-prospects-dialog/bulk-edit-prospects-dialog.component';
import { ProspectService } from '../../module/prospect/service/prospect.service';
import { PaginationDatasource } from '../../datasource/pagination.datasource';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'prospect-pagination-list',
	templateUrl: './prospect-pagination-list.component.pug',
	styleUrls: ['./prospect-pagination-list.component.scss'],
})
export class ProspectPaginationListComponent extends SelectAllEditComponent implements AfterViewInit {

	@Input() protected searchFunction: (
		pageIndex: number,
		pageSize: number,
		sortColumn: string,
		sortDirection: string,
	) => void | Observable<PaginationDto<ProspectListModel>>;

	@Input() public agents: UserListModel[];
	@Input() public multiple: boolean;
	@Input() public dataTransformation: (prospect: ProspectListModel) => void;
	@Input() public previouslySelectedId: number;
	@Input() public match: boolean;
	@Input() public phoneLinks: {phone: string, label: string}[];
	@Input() public values: ValuesModel;
	@Input() public rowHeight: number;
	@Input() public maxHeight: number;

	@Output() protected prospectSelect: EventEmitter<ProspectSelectionEventInterface> = new EventEmitter();
	@Output() protected search: EventEmitter<PaginationDto<ProspectListModel>> = new EventEmitter();
	@Output() protected load: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;
	@ViewChild('prospectList', {static: true}) protected prospectListComponent: ProspectListComponent;

	public prospects: ProspectListModel[];
	public matches: PropertyMatchModel[];
	public searchDto: ProspectSearchDto;
	public totalItems: number = 0;
	public pageSize: number;
	public pageIndex: number = 0;
	public sortActive: string;
	public sortDirection: string;

	protected subject = new Subject<any>();

	constructor(userService: UserService,
		protected bulkEditProspectsDialog: MatDialog,
		protected prospectService: ProspectService,
		protected toastrService: ToastrService,
		) {
		super();
		const user: UserModel = userService.getUser();

		if (!user.preferences) {
			user.preferences = new UserPreferencesModel();
		}

		this.pageSize = user.preferences.prospectsPerPage || 25;
	}

	public ngAfterViewInit(): void {
		this.paginator.page.subscribe((event: PageEvent) => {
			this.pageIndex = event.pageIndex;
			this.pageSize = event.pageSize;
			this.getProspects();
		});
	}

	public doSearch(): void {
		if (this.bulkEditMode) {
			this.toggleBulkEditMode();
		}
		this.paginator.pageIndex = this.pageIndex = 0;
		this.getProspects();
	}

	public prospectSelected(event: ProspectSelectionEventInterface): void {
		this.prospectSelect.emit(event);
	}

	public editSelectedRows(event: MouseEvent): void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}

		let count: number;
		let method: string;
		const prospects: ProspectListModel[] = this.bulkEditExceptionList as ProspectListModel[];

		if (this.selectAllMode) {
			count = this.dataSource.pager.totalItems - this.bulkEditExceptionList.length;
			method = 'selectAllUpdateProspects';
		} else {
			count = this.bulkEditExceptionList.length;
			method = 'bulkUpdateProspects';
		}

		this.bulkEditProspectsDialog.open(BulkEditProspectsDialogComponent, {
			width: '1000px',
			data: {
				prospects,
				values: this.values,
				count,
			}
		}).afterClosed().subscribe((result: string | Partial<ProspectModel>) => {
			if (result === 'Cancel') {
				this.toggleBulkEditMode();
				return;
			}
			const prospectIds: number[] = prospects.map(prospect => prospect.id);

			this.prospectService[method](result as Partial<ProspectModel>, prospectIds, this.searchDto).subscribe((data) => {
				if (data) {
					this.toastrService.success(`${count} Prospects have been updated`);
					this.doSearch();
				}
			}, (error: HttpErrorResponse) => {
				this.toastrService.error(error.error.message);
			});
		});
	}

	public getSortChange(sort: MatSort): void {
		if (sort && (sort.active !== this.sortActive || sort.direction !== this.sortDirection)) {
			this.sortActive = sort.active;
			this.sortDirection = sort.direction;
			this.getProspects();
		}
	}

	public setCurrentSearch(currentSearch: ProspectSearchDto): void {
		this.searchDto = currentSearch;
	}

	public getColumns(): TableColumnInterface[] {
		return this.prospectListComponent.getColumns();
	}

	public setSearchResults(results: PaginationDto<ProspectListModel>): void {
		this.dataSource = new PaginationDatasource<IdInterface>(null, this.paginator, null, [this.subject.asObservable()]);

		if (this.selectAllMode) {
			const filterProspects: ProspectListModel[] = results.data.filter(this.compareArrays(this.bulkEditExceptionList));
			const filterExcluded: ProspectListModel[] = this.bulkEditExceptionList.filter(this.compareArrays(results.data));
			const data: ProspectListModel[] = filterProspects.concat(filterExcluded);
			data.forEach(prospect => this.bulkEditSelection.select(prospect));
		}

		if (this.bulkEditMode && !this.selectAllMode) {
			const exceptions: ProspectListModel[] = results.data.filter((prospect) => this.bulkEditExceptionList.map(exception => exception.id).includes(prospect.id));
			exceptions.forEach((exception) => this.bulkEditSelection.select(exception));
		}

		this.prospects = results.data;
		this.totalItems = results.totalItems;
		this.pageIndex = results.currentPage;
		this.dataSource.pager = results;

		if (!this.match) {
			this.search.emit(results);
		}

		if (this.prospectListComponent.table) {
			this.bulkEditSelection = this.prospectListComponent.table.selection;
		}

		this.load.emit(false);
	}

	public clear(): void {
		this.prospects = undefined;
		this.totalItems = 0;
	}

	public getProspects() {
		const searchObservable: void | Observable<PaginationDto<ProspectListModel>> = this.searchFunction(this.pageIndex, this.pageSize, this.sortActive, this.sortDirection);

		if (searchObservable) {
			searchObservable.subscribe(this.setSearchResults.bind(this));
		}
	}

}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { DialService } from '../../../../../service/dial.service';
import { ToastrService } from 'ngx-toastr';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ProspectDetailsModel, UserModel, ValueInterface, ValuesModel } from '@dvp/is2-shared';
import { UsersService } from '../../../../admin/service/users.service';
import { UserService } from '../../../../../service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProspectService } from '../../../service/prospect.service';

@Component({
	selector: 'prospect-details',
	templateUrl: './prospect-details.component.pug',
	styleUrls: ['./prospect-details.component.scss'],
})
export class ProspectDetailsComponent implements OnChanges, OnInit {

	@Input() public createMode: boolean;
	@Input() public detailsFormGroup: FormGroup;
	@Input() public values: ValuesModel;
	@Input() public hasSalesRights = false;
	@Input() public hasRoutedToRights = false;
	@Input() public hasAdminRights = false;
	@Input() public isDeleted = false;
	@Input() public needToCheckDuplicates: boolean = false;

	@Output() protected nameOrCompanyChange: EventEmitter<void> = new EventEmitter<void>();

	public states = require('../../../../../../assets/states.json');

	public isExtraSmall: boolean;
	public statuses: ValueInterface[] = ProspectService.STATUSES;

	public phoneLinks: {phone: string, label: string}[] = [];
	public prospectDetails: Partial<ProspectDetailsModel>;
	public currentDate = new Date();

	constructor(
		protected dialService: DialService,
		protected toastrService: ToastrService,
		protected usersService: UsersService,
		protected userService: UserService,
		protected mediaObserver: MediaObserver,
	) {
		this.mediaObserver.media$.subscribe((change: MediaChange) => {
			this.isExtraSmall = change.mqAlias === 'xs';
		});
	}

	public ngOnInit(): void {
		this.detailsFormGroup.valueChanges.subscribe(() => this.ngOnChanges());
		this.prospectDetails = this.detailsFormGroup.value;
	}

	public getProspectTypeIds(): AbstractControl[] {
		const ProspectIds: FormArray = this.detailsFormGroup.get('prospectTypes') as FormArray;
		return ProspectIds.controls;
	}

	public getPhoneNumber(field: string): string {
		return this.detailsFormGroup.get(field).value;
	}

	public dialed(error: string) {
		if (!error) {
			this.toastrService.success('Dial completed.');
		} else {
			this.toastrService.error(error);
		}
	}

	public clickToEmail(field: string): void {
		const email = this.detailsFormGroup.get(field).value;
		location.href = `mailto:${email}`;
	}

	public ngOnChanges() {
		if (!this.values) {
			return;
		}

		const inputtedByAgentId = this.detailsFormGroup.controls['inputtedByAgentId'].value;
		if (inputtedByAgentId && !this.agentInValues(inputtedByAgentId)) {
			this.usersService.getUser(inputtedByAgentId).subscribe((user: UserModel) => {
				this.values.activeAgent.push({id: inputtedByAgentId, name: `${user.firstName} ${user.lastName} (INACTIVE)`});
			});
		}

		const routeToAgentId = this.detailsFormGroup.controls['routeToAgentId'].value;
		if (routeToAgentId && !this.agentInValues(routeToAgentId)) {
			this.usersService.getUser(routeToAgentId).subscribe((user: UserModel) => {
				this.values.activeAgent.push({id: routeToAgentId, name: `${user.firstName} ${user.lastName} (INACTIVE)`});
			});
		}

		const operator = this.detailsFormGroup.controls['operator'].value;
		if (operator && !this.agentInValues(operator)) {
			this.usersService.getUser(operator).subscribe((user: UserModel) => {
				this.values.activeAgent.push({id: operator, name: `${user.firstName} ${user.lastName}`});
			});
		}

		this.phoneLinks = [
			{phone: this.getPhoneNumber('workPhone'), label: 'Work Phone'},
			{phone: this.getPhoneNumber('homePhone'), label: 'Home Phone'},
			{phone: this.getPhoneNumber('cellPhone'), label: 'Cell Phone'},
			{phone: this.getPhoneNumber('pager'), label: 'Direct Phone'},
		].filter(x => x.phone != null && x.phone !== '');
	}

	private agentInValues(id: number): boolean {
		return !!this.values.activeAgent.find((agent: ValueInterface) => agent.id === id);
	}

	public trim(field) {
		const control: FormControl = this.detailsFormGroup.get(field) as FormControl;
		control.setValue(control.value.trim());
	}

	public checkDial(event: MouseEvent, phoneNumber: string): boolean {
		if (!this.userService.isAtDesk()) {
			return true;
		}

		event.preventDefault();
		this.dialService.dial(phoneNumber).subscribe(
			() => {
				this.dialed(null);
			},
			(error: HttpErrorResponse) => {
				this.dialed(error.error.message);
			},
		);
	}

	public nameOrCompanyChanged(): void {
		this.nameOrCompanyChange.emit();
	}

}

import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationDto, PropertyMatchModel, PropertyListModel, UserModel, UserPreferencesModel, ValueInterface } from '@dvp/is2-shared';
import { MatPaginator, PageEvent } from '@angular/material';
import { UserService } from '../../service/user.service';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { PropertyListComponent } from '../property-list/property-list.component';
import { PropertySelectionEventInterface } from '../../interface/property-selection-event.interface';

@Component({
	selector: 'property-pagination-list',
	templateUrl: './property-pagination-list.component.pug',
	styleUrls: ['./property-pagination-list.component.scss'],
})

export class PropertyPaginationListComponent implements AfterViewInit {

	@Input() protected searchFunction: (pageIndex: number, pageSize: number) => void | Observable<PaginationDto<PropertyListModel>>;
	@Input() public dataTransformation: (property: PropertyListModel) => void;
	@Input() public match: boolean;
	@Input() public cities: ValueInterface[];
	@Input() public rowHeight: number;
	@Input() public agents: ValueInterface[];

	@Output() protected propertySelect: EventEmitter<PropertySelectionEventInterface> = new EventEmitter();
	@Output() protected search: EventEmitter<PaginationDto<PropertyListModel>> = new EventEmitter();

	@ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;
	@ViewChild('propertyList', {static: true}) protected propertyListComponent: PropertyListComponent;

	public properties: PropertyListModel[];
	public matches: PropertyMatchModel[];
	public totalItems: number = 0;
	public pageSize: number;
	public pageIndex: number = 0;

	constructor(userService: UserService) {
		const user: UserModel = userService.getUser();

		if (!user.preferences) {
			user.preferences = new UserPreferencesModel();
		}

		this.pageSize = user.preferences.prospectsPerPage || 25;
	}

	public ngAfterViewInit(): void {
		this.paginator.page.subscribe((event: PageEvent) => {
			this.pageIndex = event.pageIndex;
			this.pageSize = event.pageSize;
			this.getProperties();
		});
	}

	public propertySelected(event: PropertySelectionEventInterface): void {
		this.propertySelect.emit(event);
	}

	public doSearch(): void {
		this.paginator.pageIndex = this.pageIndex = 0;
		this.getProperties();
	}

	public getColumns(): TableColumnInterface[] {
		return this.propertyListComponent.getColumns();
	}

	public setSearchResults(results: PaginationDto<PropertyListModel>): void {
		this.properties = results.data;
		this.totalItems = results.totalItems;
		this.pageIndex = results.currentPage;
	}

	protected getProperties(): void {
		const searchObservable: void | Observable<PaginationDto<PropertyListModel>> = this.searchFunction(this.pageIndex, this.pageSize);
		if (searchObservable) {
			searchObservable.subscribe(this.setSearchResults.bind(this));
		}
	}

}

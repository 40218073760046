import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SavedSearchService } from '../../../../../service/saved-search.service';
import { UserService } from '../../../../../service/user.service';
import { PropertySearchDto, SavedSearchModel } from '@dvp/is2-shared';

@Component({
	selector: 'search-preset-list',
	templateUrl: './search-preset-list.component.pug',
	styleUrls: ['./search-preset-list.component.scss'],
})
export class SearchPresetListComponent implements OnInit {

	@Output()
	public setSearch: EventEmitter<SavedSearchModel<PropertySearchDto>> = new EventEmitter();

	@Output()
	public editEmitter: EventEmitter<SavedSearchModel<PropertySearchDto>> = new EventEmitter();

	@Output()
	public saveSearchClick: EventEmitter<void> = new EventEmitter();

	public searchPresets: SavedSearchModel<PropertySearchDto>[];
	public presetsToDelete: number[] = [];
	public editingPreset: boolean = false;
	private userID: number;

	constructor(
		private searchPresetService: SavedSearchService<PropertySearchDto>,
		private userService: UserService,
	) {}

	ngOnInit() {
		this.userID = this.userService.getUser().userId;
		this.getPresets();
	}

	public toggleDelete(search: SavedSearchModel<PropertySearchDto>, saved?: boolean) {
		this.editingPreset = true;

		if (saved) {
			search.selected = !search.selected;
		}

		if (search.selected) {
			this.presetsToDelete.push(search.savedSearchID);
		} else {
			const index = this.presetsToDelete.findIndex(x => x === search.savedSearchID);
			this.presetsToDelete.splice(index, 1);

			if (this.presetsToDelete.length === 0) {
				this.editingPreset = false;
			}
		}
	}

	public deleteSearches() {
		this.searchPresetService.deleteSavedSearch(this.userService.getUser().userId, this.presetsToDelete)
			.subscribe(() => {
				this.presetsToDelete = [];
				this.getPresets();
				this.editingPreset = false;
			});
	}

	public getPresets() {
		this.searchPresetService.getSavedSearches(this.userID, 'Property')
			.subscribe((results) => {
				this.searchPresets = results;
			});
	}

	public editPreset() {
		const preset = this.searchPresets.find(x => x.savedSearchID === this.presetsToDelete[0]);
		this.editEmitter.emit(preset);
	}

	public saveSearch(): void {
		this.saveSearchClick.emit();
	}

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../service/user.service';
import { UserGuard } from '../../../guard/user.guard';

@Injectable()
export class ReAuthGuard extends UserGuard {

	constructor(userService: UserService,
							router: Router) {
		super(userService, router);
	}

	/**
	 * Checks if the user is authenticated.  If they are authenticated, force them to the property.  Otherwise,
	 *  they can access this route in question.
	 * @param {string} url - the url the user is trying to access
	 * @returns {boolean}
	 */
	public authCheck(url: string): boolean {
		if (!this.userService.hasUser()) {
			return true;
		}

		// Navigate to the login page with extras
		this.router.navigate(['/dashboard']);
		return false;
	}
}

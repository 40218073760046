import { Component, Input } from '@angular/core';

@Component({
	selector: 'is2-form-field',
	templateUrl: './form-field.component.pug',
	styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {

	@Input() public label: string;
	@Input() public flex: boolean;
	@Input() public isInvalid: boolean;

}

import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {RouterService} from '../../../service/router.service';
import {ToastrService} from 'ngx-toastr';
import {ProspectService} from '../service/prospect.service';

@Injectable()
export class ProspectResolve implements Resolve<any> {

	constructor(private prospectService: ProspectService,
	            private router: RouterService,
	            private toastrService: ToastrService) {
	}

	public resolve(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
		const id = router.params.id;

		const doRejection = (reject: any) => {
			reject({
				ngNavigationCancelingError: true
			});
		};

		return new Promise((resolve: any, reject: any) => {
			const subscription = this.prospectService.getProspect(id).subscribe(resolve, (error) => {
				// Show the reason why the route request was rejected
				if (!(error.hasOwnProperty(status)) || error.status !== 401) {
					this.toastrService.error(error.error.message);
				}
				doRejection(reject);
			});

			this.router.subscribe(change => {
				subscription.unsubscribe();
				doRejection(reject);
			});
		});
	}

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PaginationDto, PropertyMatchModel, MatchInterestInterface, ProspectWantsModel, ProspectHasModel, PropertyModel, ProspectMatchModel } from '@dvp/is2-shared';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class MatchmakerService {

	constructor(protected  http: HttpClient) {}

	public saveInterestLevel(interestLevel: MatchInterestInterface): Observable<number> {
		return this.http.post<any>(`${environment.apiUrl}/matchmaker/save`, interestLevel);
	}

	public findWantPropertyMatches(want: ProspectWantsModel, currentPage: number, pageSize: number): Observable<PaginationDto<PropertyMatchModel>> {
		const params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		return this.http.post<PaginationDto<PropertyMatchModel>>(`${environment.apiUrl}/matchmaker/find-properties`, want, {params});
	}

	public findHasProspectMatches(has: ProspectHasModel, currentPage: number, pageSize: number, userId?: number): Observable<PaginationDto<ProspectMatchModel>> {
		let params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		if (userId) {
			params = params.append('userId', `${userId}`);
		}

		return this.http.post<PaginationDto<ProspectMatchModel>>(`${environment.apiUrl}/matchmaker/has/find-prospects`, has, {params});
	}

	public findProspectMatchesForProperty(property: PropertyModel, currentPage: number, pageSize: number, userId?: number): Observable<PaginationDto<ProspectMatchModel>> {
		let params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		if (userId) {
			params = params.append('userId', `${userId}`);
		}

		return this.http.post<PaginationDto<ProspectMatchModel>>(`${environment.apiUrl}/matchmaker/property/find-prospects`, property, {params});
	}

}

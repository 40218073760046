export const base = {
	production: false,

	apiUrl: 'http://localhost:3000',

	routing: {
		tracing: false,
		defaultUrl: '/dashboard',
		loginUrl: '/login',
	},

	token: {
		refresh: 120000,
	},

	toastr: {
		timeOut: 8000,
	},

	officeIps: [
		'209.31.116.162',
		'97.78.117.82',
		'204.232.82.114',
	],

	slack: {
		webhookUrl: '',
	},

	supportEmail: {
		to: [
			'support@workd.com',
		],
		cc: [
			'tgrayshaw@thomasduke.com',
			'ahaney@thomasduke.com',
		],
	},
};

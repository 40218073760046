import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
	templateUrl: './import-dialog.component.pug',
	styleUrls: ['./import-dialog.component.scss']
})

export class ImportDialogComponent {

	public buildoutId: number = null;

	constructor(
		public dialogRef: MatDialogRef<ImportDialogComponent>,
	) {}

	public importProperty(): void {
		this.dialogRef.close(this.buildoutId);
	}
}

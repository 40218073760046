import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	CanLoad,
	NavigationExtras,
	Route,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { UserService } from '../service/user.service';
import { environment } from '../../environments/environment';

@Injectable()
export class PropertyGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(protected userService: UserService,
	            protected router: Router) {
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.authCheck(state.url);
	}

	public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}

	public canLoad(route: Route): boolean {
		return this.authCheck(`/${route.path}`);
	}

	/**
	 * Checks if the user is authenticated.  If they are authenticated, allow them to continue.  Otherwise,
	 *  send them to the login page.
	 * @param {string} url - the url the user is trying to access
	 * @returns {boolean}
	 */
	public authCheck(url: string): boolean {

		if (this.userService.hasUser() && (this.userService.hasRole('Admin') || this.userService.hasRole('Secretary'))) {
			return true;
		}

		const navigationExtras: NavigationExtras = {
			queryParams: {
				redirect: url
			}
		};

		// Navigate to the login page with extras
		this.router.navigate([environment.routing.loginUrl], navigationExtras);

		return false;
	}
}

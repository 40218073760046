import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { PropertyModel } from '@dvp/is2-shared';

@Injectable()
export class PropertyService {

	public static readonly ACTIVE_STATUSES: number[] = [1, 3, 6];
	public static readonly ACTIVE_STATUS_VALUES: string[] = ['ACTIVE', 'EXPIRED', 'UNDER CONTRACT'];

	constructor(protected http: HttpClient) {
	}

	public getDetail(id: number): Observable<PropertyModel> {
		return this.http.get<PropertyModel>(`${environment.apiUrl}/property/${id}`)
			.map(response => new PropertyModel(response));
	}

	public getStatusUpdatesPdf(id: number, includePhoneNumbers: boolean): Observable<ArrayBuffer> {
		let params = new HttpParams();
		if (includePhoneNumbers) {
			params = params.append('includePhoneNumbers', '1');
		}
		return this.http.get(`${environment.apiUrl}/property/${id}/updatespdf`, {
			params,
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer',
		});
	}

	public getStatusUpdateCsv(id: number, includePhoneNumbers: boolean): Observable<ArrayBuffer> {
		let params = new HttpParams();
		if (includePhoneNumbers) {
			params = params.append('includePhoneNumbers', '1');
		}
		return this.http.get(`${environment.apiUrl}/property/${id}/updatecsv`, {
			params,
			headers: {'Accept': 'text/csv'},
			responseType: 'arraybuffer',
		});
	}

	public getSummaryPdf(id: number): Observable<ArrayBuffer> {
		return this.http.get(`${environment.apiUrl}/property/${id}/summarypdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer',
		});
	}

	public getListingSummaryPdf(agentId: number, showInactive: boolean): Observable<ArrayBuffer> {
		let params = new HttpParams().append('showInactive', `${showInactive}`);
		if (agentId) {
			params = params.append('agentId', `${agentId}`);
		}

		return this.http.get(`${environment.apiUrl}/property/listingsummarypdf`, {
			params,
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer',
		});
	}

	public getPdf(id: number): Observable<ArrayBuffer> {
		return this.http.get(`${environment.apiUrl}/property/${id}/pdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer',
		});
	}

	public closingProcess(id: number): Observable<ArrayBuffer> {
		return this.http.get(`${environment.apiUrl}/property/${id}/closing`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer',
		});
	}

	public getCreatePdf(): Observable<ArrayBuffer> {
		return this.http.get(`${environment.apiUrl}/property/create/pdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer',
		});
	}

	public save(property: PropertyModel): Observable<any> {
		return this.http.put(`${environment.apiUrl}/property/${property.id}`, property);
	}

	public create(property: PropertyModel, createFromBuildoutId?: boolean): Observable<any> {
		let params = new HttpParams();
		if (createFromBuildoutId) {
			params = params.append('createFromBuildoutId', `${createFromBuildoutId}`);
		}
		return this.http.post(`${environment.apiUrl}/property/create`, property, {params});
	}

	public getDescription(id: number): Observable<string> {
		return this.http.get<string>(`${environment.apiUrl}/property/${id}/description`);
	}

	public getName(id: number): Observable<string> {
		return this.http.get<string>(`${environment.apiUrl}/property/${id}/name`);
	}

	public getImages(id: number): Observable<any> {
		return this.http.get<string>(`${environment.apiUrl}/property/${id}/images`);
	}

	public importFromBuildout(id: number): Observable<PropertyModel> {
		return this.http.get<PropertyModel>(`${environment.apiUrl}/property/create-from-buildout/${id}`);
	}

	public archiveData(id: number, data: string[]): Observable<void> {
		return this.http.put<any>(`${environment.apiUrl}/property/archive-data/${id}`, data);
	}

	public indexAllProperties(): Promise<void> {
		return this.http.put<void>(`${environment.apiUrl}/property/index-all`, undefined).toPromise();
	}

}

import {Pipe, PipeTransform} from '@angular/core';
import {UserListModel} from '@dvp/is2-shared';
import {LocationCodeModel} from '@dvp/is2-shared/dist/model/location-code.model';

@Pipe({
	name: 'locationFilter'
})

export class LocationFilterPipe implements PipeTransform {
	transform(locations: LocationCodeModel[], searchText: string): LocationCodeModel[] {
		if (!locations) {
			return [];
		}

		if (!searchText) {
			return locations;
		}

		searchText = searchText.toLowerCase();

		return locations.filter((loc: LocationCodeModel) => {
			const {code, name} = loc;

			return (this.hasMatch(code, searchText) || this.hasMatch(name, searchText));
		});
	}

	private hasMatch(value: string, searchText) {
		return value ? value.toLowerCase().includes(searchText) : false;
	}

}

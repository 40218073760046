import { Component, Input } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/finally';
import { UserService } from '../../../../service/user.service';

@Component({
	selector: 'dvp-login-form',
	templateUrl: './login-form.component.pug',
	styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

	public current_username = '';
	public loading = false;
	protected _success: () => any = () => {
	};

	constructor(protected userService: UserService,
	            protected authService: AuthService,
	            protected toastrService: ToastrService) {
		this.current_username = this.userService.getUsername();
	}

	@Input()
	set onSuccess(success: () => any) {
		this._success = success;
	}

	public login(form: any) {
		this.loading = true;
		this.toastrService.clear();

		if (this.userService.hasUser()) {
			form.username = this.userService.getUsername();
		}

		this.authService.login(form.username, form.password)
			.finally(() => {
				this.loading = false;
			})
			.subscribe(
				data => {
					// If login is successful, go to where the user was originally trying to go
					this._success();
				},
				(error: HttpErrorResponse) => {
					if (error.status >= 500 && error.status < 600) {
						// the error message will be handled by the server error interceptor
						return;
					}

					// Otherwise, determine the error message, and display to the user
					let message = error.message;

					if (error.status === 404) {
						message = 'Unable to connect to the server.';
					} else if (error.status === 401) {
						message = 'Invalid Username/Password!';
					}

					this.toastrService.error(message);
				}
			);
	}

}

import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '../../guard/admin.guard';
import { SharedModule } from '../../shared.module';
import { NavigationService } from '../../service/navigation.service';
import { RouterService } from '../../service/router.service';
import { AdminComponent } from './admin.component';
import { UsersPage } from './page/users/users.page';
import { UserDetailPage } from './page/user-detail/user-detail.page';
import { UserResolve } from './resolve/user.resolve';
import { UsersService } from './service/users.service';
import { UserService } from '../../service/user.service';
import { UserFilterPipe } from './pipe/userFilter.pipe';
import { TeamsService } from './service/teams.service';
import { CodesPage } from './page/codes/codes.page';
import { LocationCodesComponent } from './component/location-codes/location-codes.component';
import { CodeModalComponent } from './component/code-modal/code-modal.component';
import { CodesService } from './service/codes.service';
import { ProspectTypeCodesComponent } from './component/prospect-codes/prospect-type-codes.component';
import { LocationFilterPipe } from './pipe/locationFilter.pipe';
import { ProspectFilterPipe } from './pipe/prospectFilter.pipe';
import { CallSourceCodesComponent } from './component/call-source-codes/call-source-codes.component';
import { CategoriesPage } from './page/categories/categories.page';
import { CategoryResolve } from './resolve/category.resolve';
import { CategoryDetailPage } from './page/category-detail/category-detail.page';
import { SubCategoryCodesComponent } from './component/sub-category/sub-category-codes.component';
import { SystemSettingsPage } from './page/system-settings/system-settings.page';
import { SettingsService } from './service/settings.service';
import { TaskTemplatePage } from './page/task-template/task-template.page';
import { TaskTemplateDetailPage } from './page/task-template-detail/task-template-detail.page';
import { TemplateResolve } from './resolve/template.resolve';
import { TaskTemplateListComponent } from '../../component/task-template-list/task-template-list.component';
import { TaskTemplateDialogComponent } from '../../component/task-template-dialog/task-template-dialog.component';
import { TeamsPage } from './page/teams/teams.page';
import { TeamDetailPage } from './page/team-detail/team-detail.page';
import { TeamResolve } from './resolve/team.resolve';
import { TeamMemberDialogComponent } from './component/team-member-dialog/team-member-dialog.component';
import { LabelDialogComponent } from './component/label-dialog/label-dialog.component';

const appRoutes: Routes = [
	{
		path: 'admin',
		component: AdminComponent,
		canActivate: [AdminGuard],
		children: [
			{
				path: '',
				redirectTo: '/admin/users',
				pathMatch: 'full',
			},
			{
				path: 'users',
				component: UsersPage,
			},
			{
				path: 'users/detail/:id',
				component: UserDetailPage,
				resolve: {
					user: UserResolve,
				},
			},
			{
				path: 'teams',
				component: TeamsPage,
			},
			{
				path: 'teams/detail/:id',
				component: TeamDetailPage,
				resolve: {
					user: TeamResolve,
				},
			},
			{
				path: 'teams/create',
				component: TeamDetailPage,
			},
			{
				path: 'users/create',
				component: UserDetailPage,
			},
			{
				path: 'codes',
				component: CodesPage,
			},
			{
				path: 'category',
				component: CategoriesPage,
			},
			{
				path: 'category/detail/:id',
				component: CategoryDetailPage,
				resolve: {
					category: CategoryResolve,
				},
			},
			{
				path: 'category/create',
				component: CategoryDetailPage,
			},
			{
				path: 'settings/system',
				component: SystemSettingsPage,
			},
			{
				path: 'task/templates',
				component: TaskTemplatePage,
			},
			{
				path: 'create/task/template',
				component: TaskTemplateDetailPage,
			},
			{
				path: 'task/template/:id',
				component: TaskTemplateDetailPage,
				resolve: {
					template: TemplateResolve,
				}
			}
		],
	},
];

@NgModule({
	declarations: [
		AdminComponent,
		UsersPage,
		UserDetailPage,
		UserFilterPipe,
		CodesPage,
		CodeModalComponent,
		LocationCodesComponent,
		ProspectTypeCodesComponent,
		LocationFilterPipe,
		ProspectFilterPipe,
		CallSourceCodesComponent,
		CategoriesPage,
		CategoryDetailPage,
		SystemSettingsPage,
		SubCategoryCodesComponent,
		TaskTemplatePage,
		TaskTemplateDetailPage,
		TaskTemplateListComponent,
		TaskTemplateDialogComponent,
		TeamsPage,
		TeamDetailPage,
		TeamMemberDialogComponent,
		LabelDialogComponent,
	],
	imports: [
		RouterModule.forChild(appRoutes),
		SharedModule.forRoot(),
	],
	providers: [
		AdminGuard,
		UsersService,
		TeamsService,
		UserResolve,
		CodesService,
		CategoryResolve,
		TemplateResolve,
		SettingsService,
		TeamResolve,
	],
	entryComponents: [
		TaskTemplateDialogComponent,
		TeamMemberDialogComponent,
		LabelDialogComponent,
	]
})
export class AdminModule {

	constructor(
		protected navigationService: NavigationService,
		protected router: RouterService,
		private userService: UserService,
	) {
		this.navigationService.addMainNavigation({
			label: 'Administration',
			path: ['admin', 'users'],
			pathToMatch: ['admin'],
			ordinal: 3,
			accessible: () => this.userService.hasRole('Admin'),
		});

		this.navigationService.addSubNavigation('/admin', {
			label: 'Manage Users',
			path: ['admin', 'users'],
			accessible: () => this.userService.hasRole('Admin'),
		});

		this.navigationService.addSubNavigation('/admin', {
			label: 'Manage Teams',
			path: ['admin', 'teams'],
			accessible: () => this.userService.hasRole('Admin'),
		});

		this.navigationService.addSubNavigation('/admin', {
			label: 'Create User',
			path: ['admin', 'users', 'create'],
			accessible: () => this.userService.hasRole('Admin'),
		});

		this.navigationService.addSubNavigation('/admin', {
			label: 'Manage Codes',
			path: ['admin', 'codes'],
			accessible: () => this.userService.hasRole('Admin'),
		});

		this.navigationService.addSubNavigation('/admin', {
			label: 'Manage Categories',
			path: ['admin', 'category'],
			accessible: () => this.userService.hasRole('Admin'),
		});

		this.navigationService.addSubNavigation('/admin', {
			label: 'Manage Tasks',
			path: ['admin', 'task', 'templates'],
			accessible: () => this.userService.hasRole('Admin'),
		});

		this.navigationService.addSubNavigation('/admin', {
			label: 'System Settings',
			path: ['admin', 'settings', 'system'],
			accessible: () => this.userService.hasRole('Admin'),
		});
	}

}

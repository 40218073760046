import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { UserModel } from '@dvp/is2-shared';

@Injectable()
export class SlackService {

	constructor(protected http: HttpClient) {}

	public httpError(user: UserModel, request: HttpRequest<any>, response: HttpErrorResponse): Observable<any> {
		if (
			request
			&& request.url
			&& (
				request.url.match(new RegExp('/token/refresh/null$'))
				|| (
					request.url.includes('/task/user/occurrences')
					&& response.error.code === 'ETIMEOUT'
				)
			)
		) {
			return Observable.of(undefined);
		}

		const text: string = `${user.firstName} ${user.lastName} got an error.`;

		const color: string = 'danger';
		const attachments = [
			{
				color,
				title: 'Request',
				text: '```' + JSON.stringify(request, null, '\t') + '```',
				mrkdwn_in: ['text'],
			},
			{
				color,
				title: 'Response',
				text: '```' + JSON.stringify(response, null, '\t') + '```',
				mrkdwn_in: ['text'],
			},
		];

		return this.send(text, attachments);
	}

	protected send(text: string, attachments?: any[]): Observable<any> {
		const webhookUrl: string = environment.slack.webhookUrl;
		if (!webhookUrl) {
			return new Observable<any>(observer => observer.next());
		}

		const message: Message = {text};

		if (attachments) {
			message.attachments = attachments;
		}

		return this.http.post(webhookUrl, JSON.stringify(message), {responseType: 'text'});
	}

}

interface Message {

	text?: string;

	attachments?: Attachment[];
	response_type?: string;

	username?: string;
	icon_emoji?: string;
	channel?: string;

	replace_original?: boolean;
	delete_original?: boolean;

}

interface Attachment {

	title?: string;
	fallback?: string;

	color?: string;
	pretext?: string;
	author_name?: string;
	author_link?: string;
	author_icon?: string;
	title_link?: string;
	text: string;
	fields?: AttachmentField[];
	image_url?: string;
	thumb_url?: string;
	footer?: string;
	footer_icon?: string;
	ts?: number;
	mrkdwn_in?: string[];

	attachment_type?: string;
	actions?: AttachmentAction[];
	callback_id?: string;

}

interface AttachmentField {

	title: string;
	value: string;
	short: boolean;

}

interface AttachmentAction {

	name: string;
	text: string;
	style?: string;
	type: string;
	value?: string;
	confirm?: Confirmation;

}

interface Confirmation {

	title?: string;
	text: string;
	ok_text?: string;
	dismiss_text?: string;

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { SavedSearchModel } from '@dvp/is2-shared';

@Injectable()
export class SavedSearchService<T> {

	constructor(protected http: HttpClient) {
	}

	saveSearch(search: SavedSearchModel<T>): Observable<number|void> {
		return this.http.post<number|void>(`${environment.apiUrl}/presets`, search);
	}

	getSavedSearches(userID: number, type: string): Observable<SavedSearchModel<T>[]> {
		return this.http.get<SavedSearchModel<T>[]>(`${environment.apiUrl}/presets/${userID}/${type}`);
	}

	deleteSavedSearch(userID: number, ids: number[]): Observable<any> {
		return this.http.delete(`${environment.apiUrl}/presets/${userID}`, {
			params: {
				ids: ids.join(','),
			},
		});
	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class DialService {
	constructor(protected http: HttpClient) {}

	dial(phoneNumber: string): Observable<any> {
		return this.http.post(`${environment.apiUrl}/dial/${phoneNumber}`, null);
	}
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { ValuesService } from '../../service/values.service';

@Component({
	templateUrl: './map-dialog.component.pug',
	styleUrls: ['./map-dialog.component.scss'],
})

export class MapDialogComponent implements OnInit {

	public icon: object = {
		url: 'assets/TD-icon.png',
		scaledSize: {
			height: 40,
			width: 28,
		},
	};
	public longitude: number;
	public latitude: number;
	public loading: boolean;
	public mapAddress: string;

	protected data: {
		category: string,
		financialClas: string,
		location: string,
		name: string,
		city: string,
		county: string,
		parcelId: string,
		street: string,
	};

	constructor(
		protected dialogRef: MatDialogRef<MapDialogComponent>,
		protected mapsAPILoader: MapsAPILoader,
		protected valuesService: ValuesService,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.data = data;
	}

	public ngOnInit(): void {
		this.loading = true;
		this.getGoogleLatLong(this.data.street, this.data.county, this.data.city);
		this.mapAddress = `${this.data.street}, ${this.data.city}`;
	}

	protected getGoogleLatLong(mapAddress: string, county: string, city: string): void {
		this.mapsAPILoader.load().then(() => {
			const geocoder = new window['google'].maps.Geocoder();
			geocoder.geocode({'address': `${mapAddress}, ${city}, ${county}, MI, USA`}, (results, status) => {
				if (status === 'OK') {
					this.latitude = +results[0].geometry.location.lat().toFixed(6);
					this.longitude = +results[0].geometry.location.lng().toFixed(6);
				}
				if (results[0]) {
					this.loading = false;
				}
			});
		});
	}

}

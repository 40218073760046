import { Directive, ElementRef, Renderer2, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { createTextMaskInputElement } from 'text-mask-core/dist/textMaskCore';

import { ControlValueAccessorProviderFactory } from '../util/control-value-accessor-provider.factory';

@Directive({
	selector: '[phoneInput]',
	// tslint:disable-next-line:use-host-property-decorator
	host: {
		'(change)': 'onChange($event.target.value)',
		'(input)': 'onChange($event.target.value)',
		'(blur)': 'onTouched()',
	},
	providers: [ControlValueAccessorProviderFactory(PhoneInputDirective)],
})
export class PhoneInputDirective implements ControlValueAccessor, OnInit, OnChanges {

	protected textMaskInputElement: {
		state: {
			previousConformedValue: string,
			previousPlaceholder: string,
		},
		update: (string) => void,
	};
	protected inputElement: HTMLInputElement;
	protected lastValue: string;

	public onChange = (_: any) => {};
	public onTouched = () => {};

	constructor(
		private renderer: Renderer2,
		private elementRef: ElementRef,
	) {}

	public ngOnInit() {
		this.setupMask(true);
	}

	public ngOnChanges(changes: SimpleChanges) {
		this.setupMask(true);
		if (this.textMaskInputElement !== undefined) {
			this.textMaskInputElement.update(this.inputElement.value);
		}
	}

	public writeValue(value: number): void {
		this.setupMask();

		const normalizedValue = value == null ? '' : this.mask(value);

		this.renderer.setProperty(this.elementRef.nativeElement, 'value', normalizedValue);

		if (this.textMaskInputElement !== undefined) {
			this.textMaskInputElement.update(normalizedValue);
			this.lastValue = normalizedValue;
		}
	}

	public registerOnChange(fn: (_: string | null) => void): void {
		this.onChange = value => {
			this.setupMask();

			if (this.textMaskInputElement === undefined || this.lastValue === value) {
				return;
			}

			this.textMaskInputElement.update(value);

			// get the updated value
			value = this.inputElement.value;

			this.lastValue = value;
			const parsedValue = value === '' ? null : this.unmask(value);
			fn(parsedValue);
		};
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = () => {
			const parsedValue = this.lastValue === '' ? null : this.unmask(this.lastValue);
			if (isNaN(parseInt(parsedValue, 10))) {
				this.onChange('');
			} else {
				this.onChange(this.mask(parseInt(parsedValue, 10)));
			}

			fn();
		};
	}

	public setDisabledState(isDisabled: boolean): void {
		this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
	}

	private setupMask(create = false) {
		const textMaskConfig = {
			mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
			guide: false,
			placeholderChar: '_',
			pipe: undefined,
			keepCharPositions: false,
		};

		if (!this.inputElement) {
			if (this.elementRef.nativeElement.tagName === 'INPUT') {
				// `textMask` directive is used directly on an input element
				this.inputElement = this.elementRef.nativeElement;
			} else {
				// `textMask` directive is used on an abstracted input element, `md-input-container`, etc
				this.inputElement = this.elementRef.nativeElement.getElementsByTagName('INPUT')[0];
			}
		}

		if (this.inputElement && create) {
			this.textMaskInputElement = createTextMaskInputElement(Object.assign({inputElement: this.inputElement}, textMaskConfig));
		}
	}

	private unmask(value: string): string {
		if (value === null || value === undefined || value === '') {
			return null;
		}

		const newValue = value.replace('_', '').replace(/\D/g, '');
		return newValue.length === 0 ? null : newValue;
	}

	private mask(value: number): string {
		return value === null ? '' : value.toString();
	}
}

import {Component, OnInit} from '@angular/core';
import { AppComponent } from '../../app.component';
import { UserService } from '../../service/user.service';

@Component({
	templateUrl: './dashboard.component.pug',
})
export class DashboardComponent implements OnInit {

	constructor(protected app: AppComponent,
		protected userService: UserService) {}


	public ngOnInit(): void {
		const user = this.userService.getUser();
		// this.app.connectToSocketIo(user);
	}
}

export class StorageService {

	constructor() {
	}

	public static set(key: string, value: any): void {
		localStorage.setItem(key, JSON.stringify(value));
	}

	public static has(key: string): boolean {
		return localStorage.getItem(key) !== null;
	}

	public static get(key: string): any {
		const value = localStorage.getItem(key);

		if (value != null && value !== 'undefined') {
			return JSON.parse(value);
		}

		return null;
	}

	public static remove(keys: string[] | string): void {
		if (!Array.isArray(keys)) {
			keys = [keys];
		}

		for (const key of keys) {
			localStorage.removeItem(key);
		}
	}
}

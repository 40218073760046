import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { RouterService } from '../../../service/router.service';
import { ToastrService } from 'ngx-toastr';
import { TaskService } from '../../../service/task.service';

@Injectable()
export class TemplateResolve implements Resolve<any> {

	constructor(
		protected taskService: TaskService,
		protected router: RouterService,
		protected toastrService: ToastrService,
	) {
	}

	public resolve(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
		const id = router.params.id;

		const doRejection = (reject: any) => {
			reject({
				ngNavigationCancelingError: true,
			});
		};

		return new Promise((resolve: any, reject: any) => {
			const subscription = this.taskService.getTaskGroupTemplateById(id).subscribe(resolve, (error) => {
				if (!(error.hasOwnProperty(status)) || error.status !== 401) {
					this.toastrService.error(error.message);
				}
				doRejection(reject);
			});

			this.router.subscribe(change => {
				subscription.unsubscribe();
				doRejection(reject);
			});
		});
	}

}

import { Injectable } from '@angular/core';
import { Uploader } from '../class/uploader.class';

@Injectable()
export class UploaderService {

	constructor() {}

	public createUploader(token = null) {
		return new Uploader(token);
	}

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ControlValueAccessorProviderFactory } from '../../util/control-value-accessor-provider.factory';

@Component({
	selector: 'is2-rich-text-editor',
	templateUrl: './rich-text-editor.component.pug',
	styleUrls: ['./rich-text-editor.component.scss'],
	providers: [ControlValueAccessorProviderFactory(RichTextEditorComponent)],
})
export class RichTextEditorComponent implements OnInit, ControlValueAccessor {

	public static DEFAULT_CONFIG: any = {
		toolbarGroups: [
			{name: 'clipboard', groups: ['clipboard', 'undo']},
			{name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
			{name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
			{name: 'links', groups: ['links']},
			{name: 'insert', groups: ['insert']},
			'/',
			{name: 'styles', groups: ['styles']},
			{name: 'colors', groups: ['colors']},
		],
		removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,' +
			'Textarea,Button,Select,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,' +
			'Flash,Smiley,SpecialChar,Iframe,PageBreak,Table,Maximize,About,ShowBlocks',
		extraAllowedContent: '*[id]',
		disableNativeSpellChecker: false,
		removePlugins: 'contextmenu,liststyle,tabletools,tableselection',
	};

	private _value: any;
	get value(): any { return this._value; }
	set value(value: any) { if (value !== this._value) { this.writeValue(value); } }

	@Input() public config: any;

	@Output() public blur: EventEmitter<any> = new EventEmitter();
	@Output() public focus: EventEmitter<any> = new EventEmitter();

	public isDisabled = false;

	protected onChange = (_: any) => {};
	protected onTouched = () => {};

	public ngOnInit(): void {
		if (!this.config) {
			this.config = RichTextEditorComponent.DEFAULT_CONFIG;
		}
	}

	public writeValue(value: any): void {
		this._value = value;
		this.onChange(value);
	}

	public registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	public onFocus(): void {
		this.focus.emit();
	}

	public onBlur(): void {
		this.blur.emit();
	}

}

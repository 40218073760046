import { Component, Input } from '@angular/core';
import { ValueInterface } from '@dvp/is2-shared';

@Component({
	selector: 'admin-dashboard',
	templateUrl: './admin-dashboard.component.pug',
	styleUrls: ['./admin-dashboard.component.scss'],
})

export class AdminDashboardComponent {

	@Input() labelNames: ValueInterface[];
}

import { Component, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PropertyMatchModel, ProspectWantsModel, ProspectHasModel, ProspectMatchModel, PropertyModel, ProspectModel, UserModel } from '@dvp/is2-shared';
import { ProspectSelectionEventInterface } from '../../interface/prospect-selection-event.interface';
import { PropertySelectionEventInterface } from '../../interface/property-selection-event.interface';

@Component({
	templateUrl: './matchmaker-dialog.component.pug',
	styleUrls: ['./matchmaker-dialog.component.scss'],
})
export class MatchmakerDialogComponent {

	@Output() data: {
		want: ProspectWantsModel,
		has: ProspectHasModel,
		matches: PropertyMatchModel[]
		prospectId: number,
		user: UserModel,
	};

	constructor(
		protected dialogRef: MatDialogRef<MatchmakerDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.data = data;
	}

	public matchSelected(event: ProspectSelectionEventInterface | PropertySelectionEventInterface): void {
		if (this.data.want) {
			this.dialogRef.close({location: 'property', id: (event as PropertySelectionEventInterface).property.id});
		} else {
			this.dialogRef.close({location: 'prospect', id: (event as ProspectSelectionEventInterface).prospect.id});
		}
	}

	public addMatchMakerShown(event: {match: PropertyMatchModel | ProspectMatchModel, result: PropertyModel | ProspectModel}): void {
		this.dialogRef.close(event);
	}

}

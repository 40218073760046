import { Component, Inject, OnInit } from '@angular/core';
import { TaskTemplateModel, UserListModel, ValueInterface } from '@dvp/is2-shared';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelectChange } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { TaskService } from '../../service/task.service';
import { RRule, RRuleSet } from 'rrule';

@Component({
	templateUrl: './task-template-dialog.component.pug',
	styleUrls: ['./task-template-dialog.component.scss'],
})
export class TaskTemplateDialogComponent implements OnInit {

	public data: {
		taskTemplate: TaskTemplateModel,
		users: UserListModel[],
		createMode: boolean,
	};
	public taskTemplateFormGroup: FormGroup;
	public labelNames: ValueInterface[];
	public endRepeatOn: boolean = false;
	public endRepeatAfter: boolean = false;
	public weekly: boolean = false;
	public neverEnd: boolean = false;
	public rRuleSet: RRuleSet = new RRuleSet();
	public frequencies: ValueInterface[];
	public weekdays: ValueInterface[];
	public endOptions: ValueInterface[];
	public intervals: ValueInterface[];

	constructor(
		public dialogRef: MatDialogRef<TaskTemplateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		protected fb: FormBuilder,
		protected userService: UserService,
		protected taskService: TaskService,
	) {
		this.data = data;
	}

	public ngOnInit(): void {
		this.createTaskTemplateForm();
		this.setValues();

		this.taskService.getLabels().subscribe((result: ValueInterface[]) => {
			this.labelNames = result;
		});
	}

	public setValues(): void {
		this.endOptions = [
			{id: 'never', name: 'Never'},
			{id: 'on', name: 'On'},
			{id: 'after', name: 'After'},
		];

		this.weekdays = [
			{id: 0, name: 'Monday'},
			{id: 1, name: 'Tuesday'},
			{id: 2, name: 'Wednesday'},
			{id: 3, name: 'Thursday'},
			{id: 4, name: 'Friday'},
		];

		this.frequencies = [
			{id: RRule.DAILY.valueOf(), name: 'Days'},
			{id: RRule.WEEKLY.valueOf(), name: 'Weeks'},
			{id: RRule.MONTHLY.valueOf(), name: 'Months'},
		];

		this.intervals = [
			{id: 1, name: '1'},
			{id: 2, name: '2'},
			{id: 3, name: '3'},
			{id: 4, name: '4'},
			{id: 5, name: '5'},
			{id: 6, name: '6'},
			{id: 7, name: '7'},
			{id: 8, name: '8'},
			{id: 9, name: '9'},
			{id: 10, name: '10'},
		];
	}

	public createTaskTemplateForm(): void {
		let rule: RRule;
		if (!this.data.taskTemplate) {
			this.data.taskTemplate = new TaskTemplateModel();
		}

		if (!this.data.taskTemplate.isPrivate) {
			this.data.taskTemplate.isPrivate = false;
		}

		if (this.data.taskTemplate.rRuleSet) {
			rule = RRule.fromString(this.data.taskTemplate.rRuleSet);
		}

		this.taskTemplateFormGroup = this.fb.group({
			id: this.data.taskTemplate.id,
			title: [this.data.taskTemplate.title, [Validators.required]],
			description: this.data.taskTemplate.description,
			relativeStartDate: [this.data.taskTemplate.relativeStartDate, [Validators.pattern('^(0|[1-9][0-9]*)$')]],
			duration: 1,
			defaultAssignee: this.fb.group({
				userId: [this.getDefaultAssignee(), [Validators.required]],
			}),
			isPrivate: this.data.taskTemplate.isPrivate,
			createdBy: this.data.taskTemplate.createdBy,
			createDatetime: this.data.taskTemplate.createDatetime,
			labels: [],
			interval: (rule && rule.origOptions.interval) ? rule.origOptions.interval : '',
			freq: rule ? rule.origOptions.freq : '',
			count: (rule && rule.origOptions.count) ? rule.origOptions.count : '',
			byweekday: [],
			until: (rule && rule.origOptions.until) ? rule.origOptions.until : '',
			endRepeat: '',
		});

		if (this.data.taskTemplate.labels) {
			this.taskTemplateFormGroup.patchValue(Object.assign({}, {labels: this.data.taskTemplate.labels}));
		}

		if (rule && rule.options.byweekday) {
			this.taskTemplateFormGroup.patchValue(Object.assign({}, {byweekday: rule.options.byweekday}));
		}

		if (rule && rule.origOptions.until) {
			this.endRepeatOn = true;
			this.taskTemplateFormGroup.controls['endRepeat'].setValue('on');
		}

		if (rule && rule.origOptions.count) {
			this.endRepeatAfter = true;
			this.taskTemplateFormGroup.controls['endRepeat'].setValue('after');
		}

		if (!this.data.taskTemplate.rRuleSet) {
			this.neverEnd = true;
			this.taskTemplateFormGroup.controls['endRepeat'].setValue('never');
		}

		this.weekly = !!(rule && rule.origOptions.freq === 2);
	}

	public frequencySelected(event: MatSelectChange): void {
		if (event.value === RRule.DAILY.valueOf() || event.value === RRule.MONTHLY.valueOf()) {
			this.weekly = false;
		}

		if (event.value === RRule.WEEKLY.valueOf()) {
			this.weekly = true;
		}
	}

	public getDefaultAssignee(): string {
		if (!this.data.taskTemplate.defaultAssignee && !this.data.taskTemplate.convertAssignee) {
			return '';
		}

		if (!this.data.taskTemplate.defaultAssignee.userId) {
			return this.data.taskTemplate.convertAssignee;
		} else {
			return this.data.taskTemplate.defaultAssignee.userId.toString();
		}
	}

	public endRepeatSelected(event: MatSelectChange): void {
		if (event.value === 'never') {
			this.neverEnd = true;
			this.endRepeatAfter = false;
			this.endRepeatOn = false;
		} else if (event.value === 'on') {
			this.neverEnd = false;
			this.endRepeatAfter = false;
			this.endRepeatOn = true;
		} else {
			this.neverEnd = false;
			this.endRepeatAfter = true;
			this.endRepeatOn = false;
		}
	}


	public async saveTaskTemplate(): Promise<boolean> {
		if (this.taskTemplateFormGroup.invalid) {
			this.taskTemplateFormGroup.controls['title'].markAsTouched();
			this.taskTemplateFormGroup.controls['relativeStartDate'].markAsTouched();
			this.taskTemplateFormGroup.get('defaultAssignee.userId').markAsTouched();
			return false;
		}

		const newTaskTemplate = new TaskTemplateModel(this.taskTemplateFormGroup.value);

		if (this.taskTemplateFormGroup.controls['freq'].value) {
			await this.setRRuleSet();
			newTaskTemplate.rRuleSet = this.rRuleSet.toString() || '';
		}

		if (!Number.isInteger(newTaskTemplate.defaultAssignee.userId)) {
			newTaskTemplate.convertAssignee = this.taskTemplateFormGroup.controls['defaultAssignee'].value.userId;
			newTaskTemplate.defaultAssignee = null;
		}

		this.dialogRef.close({data: newTaskTemplate, action: 'Save'});
	}

	public deleteTaskTemplate(): void {
		this.dialogRef.close({data: this.data.taskTemplate, action: 'Delete'});
	}

	protected async setRRuleSet(): Promise<void> {
		if (this.taskTemplateFormGroup.controls['freq'].value === RRule.DAILY.valueOf()) {
			this.rRuleSet.rrule(new RRule({
				freq: RRule.DAILY,
				count: (this.taskTemplateFormGroup.controls['count'].value ? this.taskTemplateFormGroup.controls['count'].value : undefined),
				until: (this.taskTemplateFormGroup.controls['until'].value ? this.taskTemplateFormGroup.controls['until'].value : undefined),
			}));
		}

		if (this.taskTemplateFormGroup.controls['interval'].value || this.taskTemplateFormGroup.controls['byweekday'].value) {
			this.rRuleSet.rrule(new RRule({
				freq: RRule.WEEKLY,
				interval: this.taskTemplateFormGroup.controls['interval'].value,
				byweekday: this.taskTemplateFormGroup.controls['byweekday'].value,
				count: (this.taskTemplateFormGroup.controls['count'].value ? this.taskTemplateFormGroup.controls['count'].value : undefined),
				until: (this.taskTemplateFormGroup.controls['until'].value ? this.taskTemplateFormGroup.controls['until'].value : undefined),
			}));
		}

		if (this.taskTemplateFormGroup.controls['freq'].value === RRule.MONTHLY.valueOf()) {
			this.rRuleSet.rrule(new RRule({
				freq: RRule.MONTHLY,
				count: (this.taskTemplateFormGroup.controls['count'].value ? this.taskTemplateFormGroup.controls['count'].value : undefined),
				until: (this.taskTemplateFormGroup.controls['until'].value ? this.taskTemplateFormGroup.controls['until'].value : undefined),
			}));
		}
	}

}

import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { MatDatepickerModule, MatExpansionModule, MatIconModule, MatProgressSpinnerModule } from '@angular/material';
import { UserGuard } from '../../guard/user.guard';
import { PropertyPage } from './page/property/property.page';
import { SharedModule } from '../../shared.module';
import { NavigationService } from '../../service/navigation.service';
import { PropertyService } from './service/property.service';
import { DetailsComponent } from './component/property-edit/details/details.component';
import { NavbarComponent } from './component/property-edit/navbar/navbar.component';
import { SellerComponent } from './component/property-edit/seller/seller.component';
import { SearchPage } from './page/search/search.page';
import { SearchService } from './service/search.service';
import { AttributesComponent } from './component/property-edit/attributes/attributes.component';
import { PropertyComponent } from './property.component';
import { SectionComponent } from './component/property-edit/section/section.component';
import { LocationComponent } from './component/property-edit/location/location.component';
import { PropertyResolve } from './resolve/property.resolve';
import { RouterService } from '../../service/router.service';
import { LeaseSpacesComponent } from './component/property-edit/lease-spaces/lease-spaces.component';
import { PhotosComponent } from './component/property-edit/photos/photos.component';
import { FormBuilderService } from './service/form-builder.service';
import { ClosingComponent } from './component/property-edit/closing/closing.component';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { PropertyPreviewComponent } from './component/search-map/property-preview/property-preview.component';
import { SearchFiltersComponent } from './component/search-map/search-filters/search-filters.component';
import { ViewportFilterPipe } from './pipe/viewport-filter.pipe';
import { FilterChipsComponent } from './component/search-map/filter-chips/filter-chips.component';
import { BasePropertySectionComponent } from './component/property-edit/base-property-section/base-property-section.component';
import { CategoryOptionsPipe } from '../../pipe/category-options.pipe';
import { UserService } from '../../service/user.service';
import { ShareListingSearchComponent } from './page/share-listing-search/share-listing-search.component';
import { PropertyGuard } from '../../guard/property.guard';
import { AdvancedShareSearchComponent } from './component/search-map/advanced-share-search/advanced-share-search.component';
import { HighlightsComponent } from './component/property-edit/highlights/highlights.component';
import { SearchPresetListComponent } from './component/search-map/search-preset-list/search-preset-list.component';
import { PropertySearchMapComponent } from './component/search-map/property-search-map/property-search-map.component';
import { CanDeactivateGuard } from '../../guard/can-deactivate.guard';
import { EmailSendConfirmDialogComponent } from '../../component/email-send-confirm-dialog/email-send-confirm-dialog.component';
import { MailingService } from '../../service/mailing.service';
import * as FileSaver from 'file-saver';
import { CostarEmailDialogComponent } from './component/property-edit/costar-email-dialog/costar-email-dialog.component';
import { ProspectModule } from '../prospect/prospect.module';
import { CreateOwnerDialogComponent } from './component/create-owner-dialog/create-owner-dialog.component';
import { ImportDialogComponent } from './component/import-dialog/import-dialog.component';
import { ArchiveDataDialogComponent } from './component/archive-data-dialog/archive-data-dialog.component';

const appRoutes: Routes = [
	{
		path: 'property',
		component: PropertyComponent,
		canActivate: [UserGuard],
		children: [
			{
				path: '',
				redirectTo: '/property/search',
				pathMatch: 'full',
			},
			{
				path: 'search',
				component: SearchPage,
			},
			{
				path: 'detail/:id',
				component: PropertyPage,
				runGuardsAndResolvers: 'paramsOrQueryParamsChange',
				canDeactivate: [CanDeactivateGuard],
				resolve: {
					detail: PropertyResolve,
				},
			},
			{
				path: 'create',
				canActivate: [PropertyGuard],
				children: [
					{
						path: '',
						pathMatch: 'full',
						component: PropertyPage,
						canDeactivate: [CanDeactivateGuard],
					},
					{
						path: ':id',
						component: PropertyPage,
						canDeactivate: [CanDeactivateGuard],
						resolve: {
							detail: PropertyResolve,
						},
					},
				],
			},
			{
				path: 'share/:id',
				component: ShareListingSearchComponent,
			},
		],
	},
];

@NgModule({
	declarations: [
		PropertyComponent,
		PropertyPage,
		SearchPage,
		BasePropertySectionComponent,
		SectionComponent,
		DetailsComponent,
		LocationComponent,
		ClosingComponent,
		SellerComponent,
		AttributesComponent,
		HighlightsComponent,
		NavbarComponent,
		LeaseSpacesComponent,
		PhotosComponent,
		PropertyPreviewComponent,
		SearchFiltersComponent,
		FilterChipsComponent,
		ViewportFilterPipe,
		ShareListingSearchComponent,
		AdvancedShareSearchComponent,
		SearchPresetListComponent,
		PropertySearchMapComponent,
		EmailSendConfirmDialogComponent,
		CostarEmailDialogComponent,
		CreateOwnerDialogComponent,
		ImportDialogComponent,
		ArchiveDataDialogComponent,
	],
	entryComponents: [
		EmailSendConfirmDialogComponent,
		CostarEmailDialogComponent,
		CreateOwnerDialogComponent,
		ImportDialogComponent,
		ArchiveDataDialogComponent,
	],
	imports: [
		RouterModule.forChild(appRoutes),
		SharedModule.forRoot(),
		ProspectModule,
		MatExpansionModule,
		MatIconModule,
		MatDatepickerModule,
		MatProgressSpinnerModule,
	],
	providers: [
		PropertyResolve,
		UserGuard,
		PropertyService,
		SearchService,
		FormBuilderService,
		GoogleMapsAPIWrapper,
		CategoryOptionsPipe,
		PropertyGuard,
		MailingService,
	],
})
export class PropertyModule {

	constructor(navigationService: NavigationService, router: RouterService, userService: UserService, propertyService: PropertyService) {
		navigationService.addMainNavigation({
			label: 'Property',
			path: ['property', 'search'],
			pathToMatch: ['property'],
			ordinal: 1,
		});

		navigationService.addSubNavigation('/property', {
			label: 'Search',
			path: ['property', 'search'],
		});

		navigationService.addSubNavigation('/property', {
			label: 'Create',
			path: ['property', 'create'],
			accessible: () => (userService.hasRole('Admin') || userService.hasRole('Secretary')),
		});

		navigationService.addSubNavigation('/property', {
			label: 'Import',
			accessible: () => (userService.hasRole('Admin') || userService.hasRole('Secretary')),
			action: () => {
				router.navigate([`/property/create`], {queryParams: {import: true}, queryParamsHandling: 'merge'});
			},
		});

		navigationService.addSubNavigation('/property', {
			label: 'New Property Form',
			action: () => {
				propertyService.getCreatePdf().subscribe((data) => {
					const file = new Blob([data], {type: 'application/pdf'});
					FileSaver.saveAs(file, 'new-property-form.pdf');
				});
			},
		});

	}
}


import { Component } from '@angular/core';
import { TableFunctionalityComponent } from '../table-functionality/table-functionality.component';
import { MediaObserver } from '@angular/flex-layout';

@Component({
	selector: 'is2-print-table',
	templateUrl: './print-table.component.pug',
	styleUrls: ['./print-table.component.scss'],
})

export class PrintTableComponent extends TableFunctionalityComponent {

	constructor(
		protected mediaObserver: MediaObserver,
	) {
		super(mediaObserver);
	}

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DialService } from '../../service/dial.service';
import { UserService } from '../../service/user.service';

@Component({
	selector: 'is2-phone-button',
	templateUrl: './phone-button.component.pug',
	styleUrls: ['./phone-button.component.scss'],
})
export class PhoneButtonComponent {

	@Input()
	public phoneNumber: string;
	@Input()
	public disabled = false;
	@Input()
	public tooltip: string;

	@Output()
	public dial: EventEmitter<string> = new EventEmitter();

	constructor(protected dialService: DialService, public userService: UserService) {}

	public clickToDial(): void {
		this.dialService.dial(this.phoneNumber).subscribe(
			() => {
				this.dial.emit();
			},
			(error: HttpErrorResponse) => {
				this.dial.emit(error.error.message);
			},
		);
	}

}

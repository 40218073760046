import { Component, HostListener, ViewChild } from '@angular/core';
import { ProspectListModel, ProspectSearchDto, UserModel } from '@dvp/is2-shared';
import { UserService } from '../../../../service/user.service';
import { CanComponentDeactivate } from '../../../../guard/can-deactivate.guard';
import { ProspectQuickLoaderComponent } from '../../component/prospect-quick-loader/prospect-quick-loader.component';

@Component({
	templateUrl: './next-contacts.page.pug',
	styleUrls: ['./next-contacts.page.scss'],
})
export class NextContactsPage implements CanComponentDeactivate {

	@ViewChild('prospectQuickLoader', {static: true}) protected prospectQuickLoader: ProspectQuickLoaderComponent;

	public searchDto: Partial<ProspectSearchDto>;

	constructor(userService: UserService) {
		const user: UserModel = userService.getUser();
		this.searchDto = {
			details: {
				statuses: ['ACTIVE'],
				routedToIds: [user.userId],
				nextContactFromRelation: 'Beginning of',
				nextContactFromValue: 'This Week',
				nextContactToRelation: 'End of',
				nextContactToValue: 'This Week',
			},
		};
	}

	public sortFunction(a: ProspectListModel, b: ProspectListModel): number {
		if (a.nextContactDate < b.nextContactDate) { return -1; }
		if (a.nextContactDate > b.nextContactDate) { return 1; }
		return 0;
	}

	@HostListener('window:beforeunload', ['$event'])
	public onBeforeUnload($event: BeforeUnloadEvent): void {
		this.prospectQuickLoader.onBeforeUnload($event);
	}

	public canDeactivate(): Promise<boolean> {
		return this.prospectQuickLoader.canDeactivate();
	}

}

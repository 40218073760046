import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';
import {
	BarChartData,
	CallsByUserModel,
	CallSummaryByTeamModel,
	CallSummaryByUserModel,
	PaginationDto,
	ProspectListModel,
} from '@dvp/is2-shared';
import * as moment from 'moment';

@Injectable()
export class ReportService {

	constructor(protected http: HttpClient) {
	}

	public prospectsByAgent(): Observable<BarChartData> {
		return this.http.get<BarChartData>(`${environment.apiUrl}/report/count-prospects-by-agent`);
	}

	public getCallCountByUserByDate(startDate: string, endDate: string, userId: number): Observable<BarChartData> {
		const params = new HttpParams()
			.append('startDate', startDate)
			.append('endDate', endDate);

		return this.http.get<BarChartData>(`${environment.apiUrl}/report/call-count-by-user-by-date/${userId}`, {params});
	}

	public getCallSummaryForAllTeams(
		range: string,
		currentPage: number,
		pageSize: number,
		sortColumn?: string,
		sortDirection?: string,
	): Observable<PaginationDto<CallSummaryByTeamModel>> {
		let params = new HttpParams()
			.append('startDate', this.getStartDate(range).toISOString())
			.append('endDate', moment().utc().endOf('day').toISOString())
			.append('currentPage', currentPage.toString())
			.append('pageSize', pageSize.toString());

		if (sortColumn && sortDirection) {
			params = params.append('sortColumn', sortColumn).append('sortDirection', sortDirection);
		}

		return this.http.get<PaginationDto<CallSummaryByTeamModel>>(`${environment.apiUrl}/report/call-summary/team`, {params});
	}

	public getCallSummaryForTeam(
		range: string,
		teamId: number,
		currentPage: number,
		pageSize: number,
		sortColumn?: string,
		sortDirection?: string,
	): Observable<PaginationDto<CallSummaryByUserModel>> {
		let params = new HttpParams()
			.append('startDate', this.getStartDate(range).toISOString())
			.append('endDate', moment().utc().endOf('day').toISOString())
			.append('currentPage', currentPage.toString())
			.append('pageSize', pageSize.toString());

		if (sortColumn && sortDirection) {
			params = params.append('sortColumn', sortColumn).append('sortDirection', sortDirection);
		}

		return this.http.get<PaginationDto<CallSummaryByUserModel>>(`${environment.apiUrl}/report/call-summary/team/${teamId}`, {params});
	}

	public getCallSummaryForUser(
		range: string,
		userId: number,
		currentPage: number,
		pageSize: number,
		sortColumn?: string,
		sortDirection?: string,
	): Observable<PaginationDto<CallsByUserModel>> {
		let params = new HttpParams()
			.append('startDate', this.getStartDate(range).toISOString())
			.append('endDate', moment().utc().endOf('day').toISOString())
			.append('currentPage', currentPage.toString())
			.append('pageSize', pageSize.toString());

		if (sortColumn && sortDirection) {
			params = params.append('sortColumn', sortColumn).append('sortDirection', sortDirection);
		}

		return this.http.get<PaginationDto<CallsByUserModel>>(`${environment.apiUrl}/report/call-summary/user/${userId}`, {params});
	}

	public getNextContacts(startDate?: Date, endDate?: Date): Observable<ProspectListModel[]> {
		let params = new HttpParams();
		if (startDate) {
			params = params.append('startDate', moment(startDate).toISOString());
		}
		if (endDate) {
			params = params.append('endDate', moment(endDate).toISOString());
		}
		return this.http.get<any>(`${environment.apiUrl}/report/next-contacts`, {params});
	}

	private getStartDate(range: string) {
		return range === 'week'
			? moment().utc().subtract(7, 'days').startOf('day')
			: moment().utc().startOf('day');
	}
}
